import { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { makeAutoObservable, observable } from 'mobx';
import { match } from 'ts-pattern';
import { ItemCount } from '#/shared/enums';
import { User } from '#entities/user';
import { defaultStylesByStep } from '#entities/user/model/tour/common/const/styles';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { ITour } from '#entities/user/model/tour/common/interface/tour.interface';
import { userStore } from '#entities/user/model/user-store.context';
import { State } from '../user-Instruction-store';

export class SaveFilterTour implements ITour {
  data: State;
  @observable hasCompleteStep = false;
  isNotDefaultFilter: null | boolean = null;

  constructor(onboardingStep: IOnboardingStep) {
    const { content, ...otherFields } = onboardingStep;

    this.data = {
      ...otherFields,
      run: false,
      stepIndex: 0,
      steps: [
        {
          content: content.step1,
          placement: 'bottom-start',
          disableBeacon: true,
          spotlightPadding: 6,
          target: '.quickFilter',
          offset: 6,
          showNavigationButtons: true,
          showNextArrow: false,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              left: '16px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -4,
            },
          },
        },
        {
          content: content.step2,
          spotlightPadding: 4,
          offset: -2,
          placement: 'bottom-end',
          target: '#saveFilterButton',
          showNavigationButtons: false,
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -22,
            },
          },
        },
        {
          content: content.step3,
          spotlightPadding: 2,
          offset: 2,
          placement: 'bottom-start',
          target: '#recentlyAdded',
          showNavigationButtons: false,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              left: '16px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -2,
            },
          },
        },
        {
          content: content.step4,
          spotlightPadding: 1,
          offset: 2,
          placement: 'bottom-start',
          target: '.savedFilter_0',
          showNavigationButtons: false,
          spotlightClicks: false,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              left: '16px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: 4,
            },
          },
        },
      ],
    };
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setData = (data: SaveFilterTour['data']) => {
    this.data = data;
  };

  handleCallback = (data: CallBackProps, user: User) => {
    const { action, index, status, type } = data;
    const countStepWithMinusOne = this.data.steps.length - ItemCount.ONE;

    if (index === countStepWithMinusOne && type === EVENTS.TOOLTIP) {
      // Завершение тура
      if (!userStore.checkLocalHasCompleteOnboarding(this.data.id)) {
        userStore.createOnboardingProgress(user.id, this.data.id);
      }

      return;
    }

    if (action === ACTIONS.CLOSE) {
      this.handleClose();
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const futureIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      // Доп логика описана в компонентах
      // flat-filter-buttons и filter-flat-component
      match({ data, futureIndex })
        .with({ futureIndex: 2 }, (data) => {
          return;
        })
        .otherwise(() => {
          this.setStepIndex(futureIndex);
        });
    }
  };

  goNextStep = () => {
    this.setStepIndex(this.data.stepIndex + ItemCount.ONE);
  };

  goPrevStep = () => {
    this.setStepIndex(this.data.stepIndex - ItemCount.ONE);
  };

  setRun = (run: boolean) => {
    this.data.run = run;
  };

  setStepIndex = (index: number) => {
    this.data.stepIndex = index;
  };

  setHasCompleteStep = (hasCompleteStep: SaveFilterTour['hasCompleteStep']) => {
    this.hasCompleteStep = hasCompleteStep;
  };

  setHasChangesFilter = (isNotDefaultFilter: SaveFilterTour['isNotDefaultFilter']) => {
    this.isNotDefaultFilter = isNotDefaultFilter;
  };

  handleChangeFilter = (isNotDefaultFilter: SaveFilterTour['isNotDefaultFilter']) => {
    this.setHasChangesFilter(isNotDefaultFilter);
    this.setHasCompleteStep(Boolean(isNotDefaultFilter));
  };

  handleOpen = () => {
    this.setRun(true);
    this.setStepIndex(0);
  };

  handleClose = () => {
    this.setRun(false);
    this.setStepIndex(0);
  };

  getData = () => {
    return this.data;
  };

  goToLastStep = () => {
    const lastIndex = this.data.steps.length - 1;

    this.setRun(false);
    this.setStepIndex(lastIndex);

    setTimeout(() => {
      this.setRun(true);
    }, 100);
  };
}
