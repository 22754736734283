import { environments } from '../../environment';
import { CrmName } from '../enums/crm-name.enum';

type getRealEstatePathUrlResponseTypeData = {
  getRiesUserLink: (userId: number) => string;
  getRealEstateLink: (internalId: number, crmName: CrmName, ticketId: number | null) => string;
  getSpaceUserLink: () => string;
};

export const getPathUrl = () => {
  return {
    getRiesUserLink: (userId: number) => `${environments.REACT_APP_RIES_URL}/staff/view/${userId}/`,
    getSpaceUserLink: () => `${environments.REACT_APP_SPACE_URL}/profile/`,
    getRealEstateLink: (internalId: number, crmName: CrmName, ticketId: number | null) => {
      if (crmName === CrmName.RIES) {
        return `${environments.REACT_APP_RIES_URL}/?page=object&task=view&id=${internalId}`;
      }

      return `${environments.REACT_APP_SPACE_URL}/object/${internalId}/ticket/${ticketId}`;
    },
  } as getRealEstatePathUrlResponseTypeData;
};
