import { Geometry, GeometryCollection } from 'geojson';
import { LineString, Point, Polygon } from '@turf/turf';
import { PostGisGeometry } from '#shared/enums';

export const isGeometryCollection = (geometry: Geometry): geometry is GeometryCollection => {
  return geometry.type === 'GeometryCollection';
};

export const isPointGeometry = (geometry: Geometry): geometry is Point => {
  return geometry.type === PostGisGeometry.POINT;
};

export const isPolygonGeometry = (geometry: Geometry): geometry is Polygon => {
  return geometry.type === PostGisGeometry.POLYGON;
};

export const isLineStringGeometry = (geometry: Geometry): geometry is LineString => {
  return geometry.type === PostGisGeometry.LINE_STRING;
};
