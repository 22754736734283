import { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { makeAutoObservable } from 'mobx';
import { match } from 'ts-pattern';
import { ItemCount } from '#/shared/enums';
import { User } from '#entities/user';
import { defaultStylesByStep } from '#entities/user/model/tour/common/const/styles';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { ITour } from '#entities/user/model/tour/common/interface/tour.interface';
import { userStore } from '#entities/user/model/user-store.context';
import { State } from '../user-Instruction-store';

export interface SaveFilterState extends State {
  stepIndex: number;
}

export class LkTour implements ITour {
  data: SaveFilterState;

  constructor(onboardingStep: IOnboardingStep) {
    const { content, ...otherFields } = onboardingStep;

    this.data = {
      ...otherFields,
      run: false,
      stepIndex: 0,
      steps: [
        {
          content: content.step1,
          placement: 'right-end',
          disableBeacon: true,
          spotlightPadding: 4,
          target: '#userAvatarHeader',
          showNavigationButtons: false,
          offset: 2,
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: 20,
            },
          },
        },
      ],
    };
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setData = (data: LkTour['data']) => {
    this.data = data;
  };

  goNextStep = () => {
    this.setStepIndex(this.data.stepIndex + ItemCount.ONE);
  };

  goPrevStep = () => {
    this.setStepIndex(this.data.stepIndex - ItemCount.ONE);
  };

  setRun = (run: boolean) => {
    this.data.run = run;
  };

  setStepIndex = (index: number) => {
    this.data.stepIndex = index;
  };

  handleCallback = (data: CallBackProps, user: User) => {
    match(data)
      .with({ action: ACTIONS.CLOSE }, () => {
        this.handleClose();
      })
      .with({ type: EVENTS.TOOLTIP, index: 0 }, () => {
        // Завершение тура
        if (!userStore.checkLocalHasCompleteOnboarding(this.data.id)) {
          userStore.createOnboardingProgress(user.id, this.data.id);
        }
      })
      .otherwise(() => null);
  };

  handleOpen = () => {
    this.setRun(true);
    this.setStepIndex(0);
  };

  handleClose = () => {
    this.setData({ ...this.data, run: false });
  };

  getData = () => {
    return this.data;
  };
}
