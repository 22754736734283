import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { match } from 'ts-pattern';
import AddIcon from '@mui/icons-material/Add';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import ViewAgendaOutlinedIcon from '@mui/icons-material/ViewAgendaOutlined';
import { Box, Stack, useTheme } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import ControlButton from '#features/real-estate-map/ui/control-button';
import { RealEstateFilterType } from '#entities/real-estate/common/type';
import { Flat, FlatFilterType } from '#entities/real-estate/flat';
import { House } from '#entities/real-estate/house';
import { Land } from '#entities/real-estate/land';
import DrawOnMapOutlinedIcon from '#shared/assets/images/draw-on-map/draw-on-map-outlined-icon';
import { TabLink } from '#shared/enums';
import { getItemDeclension } from '#shared/lib/get-item-declension-real-estate';
import { getPath } from '#shared/lib/get-path';
import { Store } from '#shared/lib/store';
import { MapContainer } from '#shared/styles/styles';
import { MapStore } from './map-store';

type LocationMapPointProps = {
  borderRadius: string;
  marginRight: string;
};

export enum MapRealEstateState {
  EMPTY = 'empty',
  WITHOUT_AREA = 'withoutArea',
  READY_TO_DRAW = 'readyToDraw',
  DRAWING_AREA = 'drawingArea',
  RENDERED_AREA = 'renderedArea',
}

interface ButtonHighlightAreaProps {
  mapStore: MapStore;
}

const ButtonHighlightArea: React.FC<ButtonHighlightAreaProps> = observer(({ mapStore }) => {
  const theme = useTheme();

  return match(mapStore.state)
    .with(MapRealEstateState.RENDERED_AREA, () => (
      <ControlButton
        background={theme.custom.bg.button.black.default}
        sx={{
          color: theme.custom.base.primary.contrast,
        }}
        startIcon={<CloseOutlinedIcon />}
        text={'Сбросить'}
        onClick={() => {
          mapStore.clearGeometryOnMarkerAndPolygon(true);
        }}
      />
    ))
    .otherwise(() => (
      <ControlButton
        startIcon={<DrawOnMapOutlinedIcon />}
        onClick={
          {
            [MapRealEstateState.EMPTY]: () => null,
            [MapRealEstateState.WITHOUT_AREA]: () => {
              mapStore.setStateMap(MapRealEstateState.READY_TO_DRAW);
              mapStore.removePolygonSelectedArea();
              mapStore.draggingDisable();
              mapStore.addPolyline();
            },
            [MapRealEstateState.READY_TO_DRAW]: () => {
              mapStore.setStateMap(MapRealEstateState.WITHOUT_AREA);
              mapStore.draggingEnable();
            },
            [MapRealEstateState.DRAWING_AREA]: () => {
              return;
            },
            [MapRealEstateState.RENDERED_AREA]: () => null,
          }[mapStore.state]
        }
        text={
          {
            [MapRealEstateState.EMPTY]: '',
            [MapRealEstateState.WITHOUT_AREA]: 'Выделить область',
            [MapRealEstateState.READY_TO_DRAW]: 'Отменить выделение',
            [MapRealEstateState.DRAWING_AREA]: 'Выделить область',
            [MapRealEstateState.RENDERED_AREA]: '',
          }[mapStore.state]
        }
      />
    ));
});

interface ButtonToTableRealEstateProps {
  mapStore: MapStore;
  store: Store<Flat | House | Land, RealEstateFilterType>;
  selectedTab: TabLink;
  setItemStorage: (value: unknown) => void;
}

const ButtonToTableRealEstate: React.FC<ButtonToTableRealEstateProps> = observer(
  ({ mapStore, store, setItemStorage, selectedTab }) => {
    const navigate = useNavigate();

    return (
      <ControlButton
        loading={store.loadingCount}
        startIcon={<ViewAgendaOutlinedIcon />}
        onClick={() => {
          if (mapStore?.geometry) {
            const geoParams = mapStore.getGeometryAndZoomObjectForSave();

            const newFilter = {
              ...store.currentFilter,
              ...geoParams,
            } as FlatFilterType;

            runInAction(() => {
              store.changeValueByCurrentFilter('geometry', geoParams.geometry);
              store.changeValueByCurrentFilter('zoom', geoParams.zoom);
            });

            setItemStorage(newFilter);
          }

          navigate(getPath(selectedTab));
        }}
        text={`${getItemDeclension(store.count, ['объект', 'объекта', 'объектов'])} списком`}
      />
    );
  },
);

type ZoomControlMapProps = {
  mapStore: MapStore;
};

const ZoomControlMap: React.FC<ZoomControlMapProps> = observer(({ mapStore }) => {
  const theme = useTheme();

  const buttonZoomIn = (
    <Box
      borderRadius={'50%'}
      width={'40px'}
      height={'40px'}
      sx={{
        background: theme.custom.bg.button.white.default,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1px',
      }}
      onClick={() => mapStore.zoomIn()}
    >
      <AddIcon
        sx={{
          width: theme.custom.icon.icon.md,
          height: theme.custom.icon.icon.md,
          color: theme.palette.text.primary,
        }}
      />
    </Box>
  );

  const buttonZoomOut = (
    <Box
      borderRadius={'50%'}
      width={'40px'}
      height={'40px'}
      sx={{
        background: theme.custom.bg.button.white.default,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '1px',
      }}
      onClick={() => mapStore.zoomOut()}
    >
      <RemoveIcon
        sx={{
          width: theme.custom.icon.icon.md,
          height: theme.custom.icon.icon.md,
          color: theme.palette.text.primary,
        }}
      />
    </Box>
  );

  return (
    <Stack padding={'0px'} alignItems={'flex-start'} gap={'6px'}>
      {buttonZoomIn}
      {buttonZoomOut}
    </Stack>
  );
});

type WidgetRealEstateMapProps = {
  cardWrapper: ReactNode;
  mapStore: MapStore;
  store: Store<Flat | House | Land, RealEstateFilterType>;
  selectedTab: TabLink;
  setItemStorage: (value: unknown) => void;
};

export const WidgetRealEstateMap: React.FC<WidgetRealEstateMapProps> = ({
  cardWrapper,
  store,
  mapStore,
  setItemStorage,
  selectedTab,
}) => {
  const theme = useTheme();
  const zIndexBiggerThenMap = 2;

  return (
    <Box position={'absolute'} top={'24px'} left={'24px'} right={'24px'} bottom={'24px'}>
      {cardWrapper}
      <Box
        display={'flex'}
        gap={theme.custom.base.module.second}
        position={'absolute'}
        top={'0px'}
        right={'0px'}
        zIndex={zIndexBiggerThenMap}
      >
        <ButtonHighlightArea mapStore={mapStore} />
        <ButtonToTableRealEstate
          mapStore={mapStore}
          store={store}
          selectedTab={selectedTab}
          setItemStorage={setItemStorage}
        />
      </Box>
      <Box position={'absolute'} top={'500px'} right={'0px'} zIndex={zIndexBiggerThenMap}>
        <ZoomControlMap mapStore={mapStore} />
      </Box>
    </Box>
  );
};

const RealEstateMap: React.FC<LocationMapPointProps> = ({ borderRadius, marginRight }) => {
  return (
    <MapContainer>
      <Box height={'100%'} marginRight={marginRight} borderRadius={borderRadius} id='map'></Box>
    </MapContainer>
  );
};

export default RealEstateMap;
