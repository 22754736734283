import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { environments } from '#/environment';

export const AXIOS_INSTANCE = Axios.create({ baseURL: environments.REACT_APP_PROXY, withCredentials: true }); // use

// add a second `options` argument here if you want to pass extra options to
// each generated query
export const customInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
  }).then(({ data }) => data);

  return promise;
};

// In some case with react-query and swr you want to be able to override the
// return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;
