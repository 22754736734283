import React, { FC } from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Size } from '#shared/enums';

type ResetIconButtonProps = {
  hasDisplay: boolean;
  reset: () => void;
};

export const ResetFilterIconButton: FC<ResetIconButtonProps> = ({ hasDisplay, reset }) => {
  return (
    <IconButton
      id={'resetFilterIconButton'}
      disableRipple
      sx={{
        borderRadius: '8px',
        '&:hover': { backgroundColor: 'rgba(252,106,106,0.05)' },
        display: hasDisplay ? 'flex' : 'none',
      }}
      size={Size.SMALL}
      onClick={reset}
    >
      <Close color={'error'} fontSize={Size.SMALL} />
    </IconButton>
  );
};
