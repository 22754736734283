import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useRootStore } from '#app/root/use-root-store';
import LoginPage from '#pages/login/login.page';
import AccountLayout from '#widgets/layout/account-layout';
import Layout from '#widgets/layout/layout';
import TaskPageLayout from '#widgets/layout/task-page-layout';
import CircularProgress from '#shared/circular-progress/circular-progress';
import { PagePath, ParserRole, TabLink } from '#shared/enums';
import { SpinnerUiProvider } from '#shared/ui-kit/spinner/spinner';
import ProtectedRoute from './protected-route';
import ProtectedRouteByRole from './protected-route-by-role';

const CallHistory = lazy(() => import('#features/account/components/call-history'));
const Payments = lazy(() => import('#features/account/components/payments'));
const Rates = lazy(() => import('#features/account/components/rates'));
const Subscriptions = lazy(() => import('#features/account/components/subscriptions'));
const TelegramBot = lazy(() => import('#features/account/components/telegram-bot'));
const FlatPage = lazy(() => import('#pages/flat/flat.page'));
const HousePage = lazy(() => import('#pages/house/house.page'));
const LandPage = lazy(() => import('#pages/land/land.page'));
const FavoritePage = lazy(() => import('#pages/favorite/favorite.page'));
const FlatCard = lazy(() => import('#features/card/flat-card'));
const HouseCard = lazy(() => import('#features/card/house-card'));
const LandCard = lazy(() => import('#features/card/land-card'));
const ForbiddenPage = lazy(() => import('#pages/forbidden/forbidden.page'));
const NotFoundPage = lazy(() => import('#pages/not-found/not-found.page'));
const TaskPage = lazy(() => import('#pages/task/task.page'));
const MyObjectsPage = lazy(() => import('#pages/objects/my-objects.page'));
const StatParserPage = lazy(() => import('#pages/stat/stat-parser.page'));
const StatTeamPage = lazy(() => import('#pages/stat/stat-team.page'));
const Routing = () => {
  const location = useLocation();
  const { userInstructionStore } = useRootStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const pathToTabMapping: Record<string, TabLink> = {
    flats: TabLink.FLATS,
    houses: TabLink.HOUSES,
    lands: TabLink.LANDS,
  };
  const getSelectedTabFromPathname = (pathname: string) => {
    const pathnameParts = pathname.split(PagePath.EMPTY_PATH);

    for (const key of Object.keys(pathToTabMapping)) {
      if (pathnameParts.includes(key)) {
        return pathToTabMapping[key];
      }
    }

    return TabLink.FLATS;
  };
  const [selectedTab, setSelectedTab] = useState<TabLink>(getSelectedTabFromPathname(location.pathname));
  const handleTabChange = (newTabId: TabLink) => {
    setSelectedTab(newTabId);
  };

  useEffect(() => {
    userInstructionStore
      .initialize()
      .catch(() => navigate(PagePath.LOGIN))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setSelectedTab(getSelectedTabFromPathname(location.pathname));
  }, [location.pathname]);

  if (loading) {
    return null;
  }

  return (
    <Routes>
      <Route
        path={PagePath.EMPTY_PATH}
        element={
          location.pathname === PagePath.EMPTY_PATH ? (
            <Navigate to={PagePath.FLATS} />
          ) : (
            <ProtectedRoute>
              <Layout selectedTab={selectedTab} setSelectedTab={setSelectedTab} handleTabChange={handleTabChange} />
            </ProtectedRoute>
          )
        }
      >
        <Route
          path={PagePath.FLATS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <FlatPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.HOUSES}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <HousePage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.LANDS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <LandPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.FLATS_MAP}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <FlatCard selectedTab={selectedTab} />
            </Suspense>
          }
        />
        <Route
          path={PagePath.HOUSES_MAP}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <HouseCard selectedTab={selectedTab} />
            </Suspense>
          }
        />
        <Route
          path={PagePath.LANDS_MAP}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <LandCard selectedTab={selectedTab} />
            </Suspense>
          }
        />
      </Route>
      <Route
        path={PagePath.EMPTY_PATH}
        element={
          <ProtectedRoute>
            <TaskPageLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path='*'
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <NotFoundPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.FORBIDDEN}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <ForbiddenPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.TASKS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <TaskPage selectedTab={selectedTab} />
            </Suspense>
          }
        />
        <Route
          path={PagePath.MY_OBJECTS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <MyObjectsPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.STATS_PARSER}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <StatParserPage />
            </Suspense>
          }
        />
        <Route
          path={PagePath.STATS_TEAM}
          element={
            <ProtectedRouteByRole
              allowedRoles={[ParserRole.MANAGER, ParserRole.MODERATOR, ParserRole.ADMINISTRATOR]}
              children={
                <Suspense
                  fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
                >
                  <StatTeamPage />
                </Suspense>
              }
            />
          }
        />
        <Route
          path={PagePath.FAVORITES}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <FavoritePage />
            </Suspense>
          }
        />
      </Route>
      <Route path={PagePath.LOGIN} element={<LoginPage />} />
      <Route
        path={PagePath.EMPTY_PATH}
        element={
          <ProtectedRoute>
            <AccountLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path={PagePath.ACCOUNT_SUBSCRIPTIONS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <Subscriptions />
            </Suspense>
          }
        />
        <Route
          path={`${PagePath.ACCOUNT_TARIFFS}/:id?`}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <Rates />
            </Suspense>
          }
        />
        <Route
          path={PagePath.ACCOUNT_BOT}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <TelegramBot />
            </Suspense>
          }
        />
        <Route
          path={PagePath.ACCOUNT_CALLS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <CallHistory />
            </Suspense>
          }
        />
        <Route
          path={PagePath.ACCOUNT_PAYMENTS}
          element={
            <Suspense
              fallback={<SpinnerUiProvider statePending={true} spinner={<CircularProgress />} children={null} />}
            >
              <Payments />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default observer(Routing);
