import React from 'react';

type TelegramIconProps = {
  opacity: string;
};

const TelegramIcon: React.FC<TelegramIconProps> = ({ opacity }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.3749 11.1353C7.74359 8.7962 11.3236 7.25415 13.1148 6.50911C18.2292 4.38186 19.2919 4.01233 19.9846 4.00013C20.1369 3.99745 20.4776 4.0352 20.6982 4.21424C20.8845 4.36542 20.9358 4.56964 20.9603 4.71298C20.9848 4.85631 21.0154 5.18283 20.9911 5.43796C20.714 8.35001 19.5147 15.4168 18.9046 18.6783C18.6465 20.0584 18.1382 20.5211 17.6461 20.5664C16.5766 20.6648 15.7645 19.8596 14.7287 19.1806C13.1078 18.1181 12.1921 17.4567 10.6188 16.4199C8.80053 15.2217 9.97923 14.5631 11.0154 13.4869C11.2866 13.2052 15.9987 8.91921 16.0899 8.53041C16.1013 8.48178 16.1119 8.30052 16.0042 8.20481C15.8965 8.1091 15.7376 8.14183 15.6229 8.16786C15.4604 8.20475 12.871 9.91622 7.85486 13.3023C7.11988 13.807 6.45416 14.0529 5.8577 14.04C5.20015 14.0258 3.93528 13.6682 2.99498 13.3625C1.84166 12.9876 0.925027 12.7894 1.00485 12.1527C1.04642 11.8211 1.5031 11.4819 2.3749 11.1353Z'
        fill='#131D35'
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default TelegramIcon;
