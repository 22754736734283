import axios from 'axios';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { environments } from '#/environment';
import { InitialState, RecognitionStatus } from '#shared/enums';
import { CallStore } from '#shared/lib/call-store';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { CallDataFlat } from '../types/type';

export class FlatCallStore implements CallStore<CallDataFlat> {
  @observable realEstateCalls: CallDataFlat[] = [];
  @observable page: number = InitialState.FIRST;
  @observable realEstateCallsCount: number = InitialState.ZERO;
  recognizedCallsCount: number = InitialState.ZERO;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchCallsCount(userId: string) {
    try {
      const flatCallsCount = await axios.get(`${environments.REACT_APP_PROXY}/api/users/${userId}/flat-calls/count/`);

      runInAction(() => {
        this.realEstateCallsCount = flatCallsCount.data.count;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchCalls(userId: string) {
    try {
      const flatCalls = await axios.get(
        `${environments.REACT_APP_PROXY}/api/users/${userId}/flat-calls?page=${this.page}`,
      );

      runInAction(() => {
        this.realEstateCalls = flatCalls.data;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  setPage(page: number) {
    this.page = page;
  }

  @action
  async fetchRecognizedCallsCount(userId: string) {
    try {
      const response = await axios.get(
        `${environments.REACT_APP_PROXY}/api/users/${userId}/flat-calls/count/recognized`,
      );

      runInAction(() => {
        this.recognizedCallsCount = response.data.count;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async recognizeCall(userId: string, callId: string) {
    try {
      await axios.post(`${environments.REACT_APP_PROXY}/api/users/${userId}/flat-calls/${callId}/recognize`);

      const call = this.realEstateCalls.find((call) => call.id === callId);

      if (!call) {
        return;
      }

      call.recognitionStatus = RecognitionStatus.PROCESSING;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
