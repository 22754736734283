import React, { FC } from 'react';
import { observer } from 'mobx-react';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Typography, useTheme } from '@mui/material';
import { FontFamily } from '#shared/enums';

type ControlButtonProps = LoadingButtonProps & {
  text: string;
  background?: string;
};

const ControlButton: FC<ControlButtonProps> = ({ text, onClick, startIcon, loading, sx, background }) => {
  const theme = useTheme();
  const backgroundColor = background ?? theme.custom.bg.button.white.default;

  return (
    <LoadingButton
      loading={loading}
      variant='outlined'
      color='inherit'
      size='medium'
      sx={{
        borderColor: 'transparent',
        background: backgroundColor,
        borderRadius: '8px',
        color: theme.custom.text.text.dark,
        '&:hover': {
          background: backgroundColor,
        },
        '&:disabled': {
          background: backgroundColor,
        },
        ...sx,
      }}
      startIcon={startIcon}
      disableRipple
      onClick={onClick}
    >
      <Typography
        sx={{
          fontFamily: FontFamily.ROBOTO,
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '0.021px',
          textTransform: 'none',
        }}
      >
        {text}
      </Typography>
    </LoadingButton>
  );
};

export default observer(ControlButton);
