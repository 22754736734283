import React from 'react';
import { observer } from 'mobx-react';
import { NoDataMessage, Size } from '#shared/enums';
import { getItemDeclensionRealEstate } from '#shared/lib/get-item-declension-real-estate';
import { ShowObjectsButton } from '#shared/styles/styles';
import { Spinner, SpinnerUiProvider } from '#shared/ui-kit/spinner/spinner';

type ShowFullFilterFormButtonsProps = {
  onClick: () => void;
  active?: boolean;
  loadingCount: boolean;
  realEstateCount: number;
};

export const ShowFullFilterFormButton: React.FC<ShowFullFilterFormButtonsProps> = observer(
  ({ onClick, active, loadingCount, realEstateCount }) => {
    return (
      <ShowObjectsButton
        id={'showFullFilterFormButton'}
        onClick={() => onClick()}
        size={Size.LARGE}
        disabled={!active}
        sx={{ width: '280px', height: '40px' }}
      >
        <SpinnerUiProvider
          statePending={loadingCount}
          spinner={<Spinner sx={{ color: 'white', padding: '4.9px 0' }} size={'15px'} />}
          children={
            <>
              {active
                ? `Показать ${getItemDeclensionRealEstate(realEstateCount, ['объект', 'объекта', 'объектов'])}`
                : NoDataMessage.REAL_ESTATE_NOT_FOUND}
            </>
          }
        />
      </ShowObjectsButton>
    );
  },
);
