import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { FavoriteBorderOutlined, HelpOutline } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import { useRootStore } from '#app/root/use-root-store';
import { UserAvatar } from '#entities/user';
import { useUser } from '#entities/user/model/user-context';
import logo from '#shared/assets/images/RHOODLOGO_White.svg';
import { PagePath } from '#shared/enums/page-path';
import { ParserRole } from '#shared/enums/parser-role';
import { ButtonContainerHeader, ButtonsContainer, Header, HeaderButton, IconBox } from '#shared/styles/styles';
import PopoverAccountMenu from '#shared/ui-kit/popover/popover-account-menu';
import PopoverHelpingMenu from '#shared/ui-kit/popover/popover-helping-menu';

type LinkHeader = {
  title: string;
  path: PagePath;
  hasAdminRights?: boolean;
};

const linkHeaders: LinkHeader[] = [
  {
    title: 'Мои объекты',
    path: PagePath.MY_OBJECTS,
  },
  {
    title: 'Подключение городов',
    path: PagePath.ADMIN_TASKS,
    hasAdminRights: true,
  },
  {
    title: 'Статистика',
    path: PagePath.STATS_PARSER,
  },
];

const HeaderComponent = observer(() => {
  const theme = useTheme();
  const { userInstructionStore } = useRootStore();
  const [anchorElHelpingMenu, setAnchorElHelpingMenu] = useState<null | HTMLElement>(null);
  const [anchorElAccountMenu, setAnchorElAccountMenu] = useState<null | HTMLElement>(null);
  const handleHelpingMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElHelpingMenu(event.currentTarget);
  };
  const handleAccountMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccountMenu(event.currentTarget);

    if (userInstructionStore.lkTour.data.run) {
      userInstructionStore.lkTour.handleClose();
      navigate(PagePath.ACCOUNT_SUBSCRIPTIONS);
    }
  };
  const handleCloseHelpingMenu = () => {
    setAnchorElHelpingMenu(null);
  };
  const handleCloseAccountMenu = () => {
    setAnchorElAccountMenu(null);
  };
  const location = useLocation();
  const isOpenHelpingMenu = Boolean(anchorElHelpingMenu);
  const isOpenAccountMenu = Boolean(anchorElAccountMenu);
  const user = useUser();
  const navigate = useNavigate();

  const parserPages: string[] = [
    PagePath.FLATS,
    PagePath.FLATS_MAP,
    PagePath.HOUSES,
    PagePath.HOUSES_MAP,
    PagePath.LANDS,
    PagePath.LANDS_MAP,
  ];

  return user ? (
    <Header>
      <ButtonContainerHeader>
        <img alt={''} src={logo} onClick={() => navigate(PagePath.FLATS)} />
        <ButtonsContainer>
          <HeaderButton onClick={() => navigate(PagePath.FLATS)} selected={parserPages.includes(location.pathname)}>
            Парсер
          </HeaderButton>
          {linkHeaders.map((header) =>
            !header.hasAdminRights || user.role.name === ParserRole.ADMINISTRATOR ? (
              <Link to={header.path} key={header.path}>
                <HeaderButton selected={location.pathname === header.path}>
                  <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                    <Box>{header.title}</Box>
                  </Box>
                </HeaderButton>
              </Link>
            ) : null,
          )}
        </ButtonsContainer>
      </ButtonContainerHeader>
      <IconBox>
        <Link
          to={PagePath.FAVORITES}
          style={{ display: 'flex' }}
          className={location.pathname === PagePath.FAVORITES ? 'active-favorite' : ''}
        >
          <FavoriteBorderOutlined sx={{ color: 'white', cursor: 'pointer', padding: '8px' }} />
        </Link>
        <IconButton onClick={handleHelpingMenuOpen}>
          <HelpOutline className={'dropMenu'} sx={{ color: theme.palette.common.white }} />
        </IconButton>
        <UserAvatar
          id={'userAvatarHeader'}
          onClick={handleAccountMenuOpen}
          avatarUrl={user.avatarUrl}
          name={user.fullName}
        />
        <PopoverHelpingMenu
          isOpen={isOpenHelpingMenu}
          anchorEl={anchorElHelpingMenu}
          handleCloseMenu={handleCloseHelpingMenu}
        />
        <PopoverAccountMenu
          isOpenAccountMenu={isOpenAccountMenu}
          anchorEl={anchorElAccountMenu}
          handleCloseAccountMenu={handleCloseAccountMenu}
        />
      </IconBox>
    </Header>
  ) : null;
});

export default HeaderComponent;
