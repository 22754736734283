import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

type LinkToExternalSiteProps = {
  to: string;
  text: string;
  underline?: boolean;
};

export const LinkToExternalSite: React.FC<LinkToExternalSiteProps> = ({ to, text, underline = true }) => {
  const theme = useTheme();

  return (
    <Link
      style={{
        color: theme.palette.primary.main,
        textDecoration: underline ? 'underline' : 'none',
      }}
      to={to}
      target={'_blank'}
      rel='noreferrer noopener'
    >
      {text}
    </Link>
  );
};
