export enum FontSize {
  TWELFTH_FONT = 12,
  THIRTEENTH_FONT = 13,
  FOURTEENTH_FONT = 14,
  FIFTEENTH_FONT = 15,
  SIXTEENTH_FONT = 16,
  TWENTY_FONT = 20,
  TWENTY_FOUR_FONT = 24,
  THIRTY_FOUR_FONT = 34,
}
