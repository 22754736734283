import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Close } from '@mui/icons-material';
import { Chip, ChipProps } from '@mui/material';

const FilterChip: FC<ChipProps> = ({ onClick, onDelete, label }) => {
  return (
    <Chip
      size='medium'
      sx={{
        borderRadius: '8px',
        borderColor: '#D1D1D1',
      }}
      label={label}
      variant='outlined'
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<Close color={'primary'} style={{ fontSize: '18px' }} />}
    />
  );
};

export default observer(FilterChip);
