import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Box, LinearProgress } from '@mui/material';
import { environments } from '#/environment';
import { LocalStorageName, PagePath } from '#shared/enums';
import { CrmNameRu } from '#shared/enums/geo/crm-name-ru.enum';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { User } from './type';

interface UserContextProps {
  userData: User | null;
  setUpdateUserFlag: React.Dispatch<React.SetStateAction<string>>;
}

const getUserDataFromStorage = () => {
  const userDataString = localStorage.getItem(LocalStorageName.USER);

  if (!userDataString) {
    window.location.href = PagePath.LOGIN;

    throw new Error();
  }

  return JSON.parse(userDataString);
};

const fetchUserData = async () => {
  try {
    const user = getUserDataFromStorage();
    const userData = await axios.get<User>(`${environments['REACT_APP_PROXY']}/api/users/${user.id}`);

    userData.data.crmName = userData.data.workAddress.city.space?.internalId ? CrmNameRu.SPACE : CrmNameRu.RIES;

    return userData.data;
  } catch (error) {
    handleAxiosError(error);
    throw error;
  }
};

export const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<User | null>(null);
  const [updateUserFlag, setUpdateUserFlag] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === PagePath.LOGIN;

  useEffect(() => {
    const fetchUser = async () => {
      const data = await fetchUserData();

      setUserData(data);
      setLoading(false);
    };

    if (!isLoginPage) {
      fetchUser();
    }
  }, [updateUserFlag]);

  return loading && !isLoginPage ? (
    <Box width={'100%'}>
      <LinearProgress />
    </Box>
  ) : (
    <UserContext.Provider value={{ userData, setUpdateUserFlag }}>{children}</UserContext.Provider>
  );
};

export const useUser = (): User => {
  const context = useContext(UserContext);

  if (!context?.userData) {
    window.location.href = PagePath.LOGIN;
    throw new Error('Context error');
  }

  return context.userData;
};
