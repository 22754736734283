import { action, computed, makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import { AvailableTaskDto, Task } from '#entities/task';
import { ItemCount, RealEstateTypeUpperCase, TabLink } from '#shared/enums';
import { RealEstateTypeCountWithCommon } from '#shared/types/real-estate-type-count-with-common';
import { CityStore } from './city.store';
import { City } from './types';

export class ConnectCityStore {
  @observable selectedTab: TabLink = TabLink.FLATS;
  @observable selectedRealEstateType: RealEstateTypeUpperCase = RealEstateTypeUpperCase.FLAT;
  @observable searchCity: string = '';
  @observable selectedCity: City = {} as City;
  @observable anchorFilterCity: HTMLButtonElement | null = null;
  @observable initializedStore: boolean = false;
  @observable existTasksBySelectedCity: Task[] = [];
  @observable availableTasksBySelectedCity: AvailableTaskDto[] = [];
  @observable loadingTasks: boolean = true;
  @observable isConflictCreatedTask: boolean = false;
  @observable isTestingCityPeriod: boolean = false;

  constructor(public readonly cityStore: CityStore) {
    this.setSelectedTab(TabLink.FLATS);

    makeAutoObservable(this);

    reaction(
      () => this.selectedTab,
      (selectedTab) => {
        this.setSelectedRealEstateType(Object.keys(RealEstateTypeUpperCase)[selectedTab] as RealEstateTypeUpperCase);
      },
    );
  }

  @computed get availableTaskByCityAndRealEstateType() {
    return (
      this.availableTasksBySelectedCity.find((groupTask) => groupTask.realEstateType === this.selectedRealEstateType)
        ?.availableTasks ?? []
    );
  }

  @computed get existTaskByCityAndRealEstateType() {
    return this.existTasksBySelectedCity.filter((task) => task.params.realEstateType === this.selectedRealEstateType);
  }

  @computed
  get filteredGroupCitiesByFirstLetter() {
    const filteredCities = this.cityStore.filterCitiesByCityName(this.searchCity);

    return this.cityStore.generateGroupedCitiesByFirstLetter(filteredCities);
  }

  @computed get countAvailableTaskByCity() {
    const realEstateTypeCounts = Object.values(RealEstateTypeUpperCase).reduce((objectCount, realEstateType, index) => {
      const count =
        this.availableTasksBySelectedCity.find((availableTask) => availableTask.realEstateType === realEstateType)
          ?.availableTasks.length ?? ItemCount.ZERO;

      objectCount[realEstateType] = count;

      return objectCount;
    }, {} as RealEstateTypeCountWithCommon);

    realEstateTypeCounts.TOTAL = realEstateTypeCounts.FLAT + realEstateTypeCounts.HOUSE + realEstateTypeCounts.LAND;

    return realEstateTypeCounts;
  }

  @action
  resetStore() {
    this.setSelectedTab(TabLink.FLATS);
    this.setSearchCity('');
    this.setSelectedCity({} as City);
    this.setAnchorFilterCity(null);
    this.setInitializedStore(false);
    this.setExistTasksBySelectedCity([]);
    this.setAvailableTasksBySelectedCity([]);
    this.setLoadingTasks(true);
    this.setIsConflictCreatedTask(false);
    this.setIsTestingCityPeriod(false);
  }

  @action
  setIsTestingCityPeriod(isState: boolean) {
    this.isTestingCityPeriod = isState;
  }

  @action
  setLoadingTasks(isState: boolean) {
    this.loadingTasks = isState;
  }

  @action
  setSelectedTab(tab: TabLink) {
    this.selectedTab = tab;
  }

  @action
  setSelectedRealEstateType(realEstateType: RealEstateTypeUpperCase) {
    this.selectedRealEstateType = realEstateType;
  }

  @action
  setInitializedStore(initializingState: boolean) {
    this.initializedStore = initializingState;
  }

  @action
  setIsConflictCreatedTask(isState: boolean) {
    this.isConflictCreatedTask = isState;
  }

  @action
  setSearchCity(searchQuery: string) {
    this.searchCity = searchQuery.trimStart();
  }

  @action
  setAnchorFilterCity(cityId: HTMLButtonElement | null) {
    this.anchorFilterCity = cityId;
  }

  @action
  setAvailableTasksBySelectedCity(availableTasks: AvailableTaskDto[]) {
    this.availableTasksBySelectedCity = availableTasks;
  }

  @action
  handleSwitchTask(availableTasks: AvailableTaskDto[]) {
    this.availableTasksBySelectedCity = availableTasks;
  }

  @action
  setSelectedCity(city: City) {
    runInAction(() => {
      this.selectedCity = city;
    });
  }

  @action
  setExistTasksBySelectedCity(tasks: Task[]) {
    this.existTasksBySelectedCity = tasks;
  }

  @action
  addExistTask(task: Task) {
    this.cityStore.addTask(task);
    this.existTasksBySelectedCity.push(task);
  }

  @action
  updateExistTask(newTask: Task) {
    this.existTasksBySelectedCity = this.existTasksBySelectedCity.map((task) =>
      task.id === newTask.id ? newTask : task,
    );
  }
}
