import { createSvgIcon } from '@mui/material';

export default createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.8712 9.6835L13.7412 9.2695C13.599 9.25261 13.444 9.26092 13.2918 9.31036L12.6736 9.51122L11.283 5.23147C10.8566 3.91901 9.44532 3.19993 8.13286 3.62637C6.8204 4.05281 6.10132 5.4641 6.52776 6.77655L9.04625 14.5277L7.14417 14.7251C6.9542 14.7553 6.12774 14.8976 5.67189 15.7923L4.77612 17.5659L11.2493 20.9197C11.7186 21.1668 12.2686 21.2089 12.7726 21.0451L19.0021 19.0211C19.9341 18.7182 20.5072 17.796 20.3596 16.824L19.5535 11.366C19.4304 10.5017 18.7462 9.8198 17.8712 9.6835ZM18.384 17.119L12.1546 19.143L7.46761 16.6914L11.7085 16.2492L8.42987 6.15852C8.34335 5.89222 8.4846 5.61501 8.75089 5.52848C9.01719 5.44196 9.29441 5.58321 9.38093 5.8495L11.2907 11.727L12.9645 11.1832L17.5779 11.6609L18.384 17.119Z'
      fill='black'
    />
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M19.5823 9.30152C18.7856 7.92157 17.6532 6.76522 16.2902 5.93977C14.9272 5.11433 13.3779 4.64654 11.7858 4.57982L11.8503 3.04117C12.1847 3.05518 12.5174 3.08441 12.8475 3.12855C14.3452 3.32881 15.7894 3.83605 17.088 4.62251C18.3866 5.40897 19.5052 6.45377 20.3765 7.68827C20.5686 7.96038 20.7486 8.24171 20.916 8.53152L19.5823 9.30152Z'
      fill='black'
    />
  </svg>,

  'draw-on-map-outlined-icon',
);
