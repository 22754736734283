import { LocalStorageName } from '#shared/enums';

export const resetAllowedUserStorage = () => {
  // Удаляем все storage кроме storage инструкции юзера
  localStorage.removeItem(LocalStorageName.FLAT_FILTER);
  localStorage.removeItem(LocalStorageName.HOUSE_FILTER);
  localStorage.removeItem(LocalStorageName.LAND_FILTER);
  localStorage.removeItem(LocalStorageName.USER);
  localStorage.removeItem(LocalStorageName.IS_SUBSCRIPTION_PLAN_ALERT_CLOSED);
};
