import { FlatClusterType } from '#entities/real-estate/flat';
import { HouseClusterType } from '#entities/real-estate/house';
import { LandClusterType } from '#entities/real-estate/land';
import { MapRealEstateSettings } from '#shared/enums';

export const limitGeoParamZoomApi = (zoom: number | undefined): number | undefined => {
  if (zoom === undefined) {
    return zoom;
  }

  return zoom > MapRealEstateSettings.MAX_GEO_CLUSTER_ZOOM ? MapRealEstateSettings.MAX_GEO_CLUSTER_ZOOM : zoom;
};
export const limitGeoParamsRealEstateClusters = (
  clusterFilter: FlatClusterType | HouseClusterType | LandClusterType,
) => {
  return {
    ...clusterFilter,
    zoom: clusterFilter.zoom ? limitGeoParamZoomApi(clusterFilter.zoom) : undefined,
  };
};
