import React from 'react';
import { DomainSource } from '#entities/domain/model/type';
import type { UrlRealEstateImage } from '#entities/real-estate/common/type';

export const fixImage = (
  url: string,
  cdnDomain: string,
  domain: string,
  domainSource: DomainSource[],
  setUrls: React.Dispatch<React.SetStateAction<UrlRealEstateImage[]>>,
): string => {
  setUrls((prevUrls: UrlRealEstateImage[]) => {
    const newUrls = prevUrls.filter((imageEntry) => imageEntry.url !== url);
    const cdnDomains = domainSource.find((domainSource) => domainSource.value === domain)?.cdnDomains;

    if (!cdnDomains) {
      return newUrls;
    }

    const currentDomainIndex = cdnDomains.findIndex((entry) => entry === cdnDomain);

    if (cdnDomains[currentDomainIndex + 1]) {
      newUrls.push({ domain, cdnDomain: cdnDomains[currentDomainIndex + 1], url });
    }

    return newUrls;
  });

  return url;
};
