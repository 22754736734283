import { Position } from 'geojson';
import { match } from 'ts-pattern';
import * as turf from '@turf/turf';
import { PostGisGeometry } from '#shared/enums/geo/post-gis-geometry';
import {
  CoordinatesLineString,
  CoordinatesMultiPoint,
  CoordinatesMultiPolygon,
  CoordinatesPoint,
  CoordinatesPolygon,
} from '#shared/types/geo/coordinates-geometry';
import { GeometryWithoutGeometryCollection } from '#shared/types/geo/geo-json-geometry-type';

export const flipCoordinatesByGeometry = (geometry: GeometryWithoutGeometryCollection) => {
  return match(geometry)
    .with({ type: PostGisGeometry.POINT }, ({ coordinates }) => flipPointCoordinates(coordinates))
    .with({ type: PostGisGeometry.MULTI_POINT }, ({ coordinates }) => flipMultiPointCoordinates(coordinates))
    .with({ type: PostGisGeometry.LINE_STRING }, ({ coordinates }) => flipLineStringCoordinates(coordinates))
    .with({ type: PostGisGeometry.POLYGON }, ({ coordinates }) => flipPolygonCoordinates(coordinates))
    .otherwise(({ coordinates }) => flipMultiPolygonCoordinates(geometry.coordinates as CoordinatesMultiPolygon));
};

export const flipMultiPointCoordinates = (multiPoint: CoordinatesMultiPoint) => {
  const turfMultiPoint = turf.multiPoint(multiPoint);

  const {
    geometry: { coordinates },
  } = turf.flip(turfMultiPoint);

  return coordinates;
};

export const flipMultiPolygonCoordinates = (multiPolygon: CoordinatesMultiPolygon): Position[][][] => {
  const turfMultiPolygon = turf.multiPolygon(multiPolygon);

  const {
    geometry: { coordinates },
  } = turf.flip(turfMultiPolygon);

  return coordinates;
};

export const flipPolygonCoordinates = (polygon: CoordinatesPolygon) => {
  const turfPolygon = turf.polygon(polygon);
  const {
    geometry: { coordinates },
  } = turf.flip(turfPolygon);

  return coordinates;
};

export const flipLineStringCoordinates = (lineString: CoordinatesLineString) => {
  const turfLineString = turf.lineString(lineString);
  const {
    geometry: { coordinates },
  } = turf.flip(turfLineString);

  return coordinates;
};

export const flipPointCoordinates = (point: CoordinatesPoint): CoordinatesPoint => {
  const turfPoint = turf.point(point);
  const {
    geometry: { coordinates },
  } = turf.flip(turfPoint);

  return coordinates;
};
