import DG from '2gis-maps';
import { GeoJsonObject } from 'geojson';
import L from 'leaflet';
import { cloneDeep, debounce } from 'lodash';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import Supercluster from 'supercluster';
import { match } from 'ts-pattern';
import * as turf from '@turf/turf';
import { RealEstateFilterType } from '#/entities/real-estate/common/type';
import { environments } from '#/environment';
import { City, CityStore } from '#entities/city';
import { BaseRealEstateFilterType, getFilterWithoutMapParams, trimFilters } from '#entities/real-estate';
import { Flat } from '#entities/real-estate/flat';
import { House } from '#entities/real-estate/house';
import { Land } from '#entities/real-estate/land';
import { User } from '#entities/user';
import { ClusterByRealEstateDto } from '#shared/dto/cluster-by-real-estate-dto';
import { InitialState, ItemCount, NumberConvert, PageLimit, TimeConvert } from '#shared/enums';
import { MapRealEstateSettings } from '#shared/enums/geo/map-real-estate-settings.enum';
import { PostGisGeometry } from '#shared/enums/geo/post-gis-geometry';
import { SuperClusterSettingsEnum } from '#shared/enums/geo/super-cluster-settings.enum';
import { uniqueArray } from '#shared/lib/array/array';
import { createFeatureByCoordinates } from '#shared/lib/geo/create-feature-by-coordinates';
import { flipCoordinatesByGeometry, flipPolygonCoordinates } from '#shared/lib/geo/flip-coordinates';
import { isLineStringGeometry, isPointGeometry, isPolygonGeometry } from '#shared/lib/geo/guard/geo-guard';
import { isGeometryViewport } from '#shared/lib/geo/is-geometry-viewport';
import { limitGeoParamZoomApi } from '#shared/lib/geo/limit-geo-search-params';
import { transformPointFeatureToGeometryCoordinates } from '#shared/lib/geo/transform/transform-point-feature-to-geometry-coordinates';
import { Store } from '#shared/lib/store';
import { CoordinatesGeometryType, CoordinatesPoint, Position } from '#shared/types/geo/coordinates-geometry';
import { GeometryWithoutGeometryCollection } from '#shared/types/geo/geo-json-geometry-type';
import { ExtremeCoordinatesType, LatLntPoint, LongitudeLatitudePoint } from '#shared/types/geo/point';
import RenderClusterMarker from './cluster-marker';
import { getNorthWest, getSouthEast } from './lib/lib';
import RenderMarker from './marker';
import { MapRealEstateState } from './real-estate-map';
import { getMarkerIcon } from './ui/marker';

type PropertiesAdditionalOnePointData = Pick<ClusterByRealEstateDto, 'price'>;

const isPointFeatureClusterRealEstate = (
  feature:
    | Supercluster.PointFeature<ClusterByRealEstateDto>
    | Supercluster.ClusterFeature<PropertiesAdditionalOnePointData>,
): feature is Supercluster.PointFeature<ClusterByRealEstateDto> => {
  return (feature as Supercluster.PointFeature<ClusterByRealEstateDto>).properties.id !== undefined;
};

const rename2gisPointCoordinates = (point: LatLntPoint) => {
  return {
    longitude: point.lng,
    latitude: point.lat,
  } as LongitudeLatitudePoint;
};
const getCoordinatesByMainDiagonalCities = <T extends BaseRealEstateFilterType>(filter: T, availableCities: City[]) => {
  const selectedCities = filter.city;
  const selectedCitiesWithCoordinates = availableCities.filter((city) => selectedCities.includes(city.id));
  const latitudeCoordinates = selectedCitiesWithCoordinates.map((city) => {
    return city.latitude;
  });
  const longitudeCoordinates = selectedCitiesWithCoordinates.map((city) => {
    return city.longitude;
  });
  const southWest = {
    lat: Math.min(...latitudeCoordinates),
    lng: Math.min(...longitudeCoordinates),
  };
  const northEast = {
    lat: Math.max(...latitudeCoordinates),
    lng: Math.max(...longitudeCoordinates),
  };

  return {
    southWest,
    northEast,
  };
};

const getCoordinatesMainCity = <T extends BaseRealEstateFilterType>(filter: T, cityStore: CityStore, user: User) => {
  const mainUserCity = user.workAddress.city;
  const selectedCities = filter.city;
  const firstSelectedCity = cityStore.findCityById(selectedCities[0]);

  // Filter does not exist
  if (!filter || !firstSelectedCity) {
    return [mainUserCity.latitude, mainUserCity.longitude];
  }

  // Take first city from filter
  return [firstSelectedCity.latitude, firstSelectedCity.longitude];
};

const textPricePopup = (number: number) => {
  const million = NumberConvert.MILLION;
  const thousand = NumberConvert.THOUSAND;

  if (number >= NumberConvert.MILLION) {
    const numberPrice = Number((number / million).toFixed(1)).toLocaleString('en-US');

    return `от ${numberPrice} млн`;
  }

  const numberPrice = Number((number / thousand).toFixed(1)).toLocaleString('en-US');

  return `от ${numberPrice} тыс.`;
};

export class MapStore {
  @observable mapRef: typeof DG | null = null;
  @observable filter: null | RealEstateFilterType = null;
  @observable cityStore: CityStore;
  @observable state: MapRealEstateState = MapRealEstateState.EMPTY;
  @observable polyline: Record<string, any> | null = null;
  @observable polygonSelectedArea: Record<string, any> | null = null;
  @observable extremeValueLatAndLng: ExtremeCoordinatesType | null = null;
  @observable realEstateStore: Store<Flat | House | Land, RealEstateFilterType>;
  @observable selectedCluster:
    | Supercluster.PointFeature<ClusterByRealEstateDto>
    | Supercluster.ClusterFeature<PropertiesAdditionalOnePointData>
    | null = null;

  @observable loadingCount: boolean = true;
  @observable isProcessingDraggingMap: boolean = false;
  @observable polygonMarker: Record<string, any> | null = null;
  @observable isShowRealEstate: boolean = true;
  @observable isDraggingMap: boolean = true;
  @observable geometry?: GeometryWithoutGeometryCollection = undefined;
  @observable zoom = MapRealEstateSettings.DEFAULT_GEO_ZOOM;
  @observable isLoadingNextPage: boolean = false;
  @observable isZoomingMap: boolean = false;
  @observable indexSuperCluster: Supercluster<ClusterByRealEstateDto, PropertiesAdditionalOnePointData> =
    new Supercluster<ClusterByRealEstateDto, PropertiesAdditionalOnePointData>({
      maxZoom: MapRealEstateSettings.MAX_GEO_ZOOM,
      minZoom: MapRealEstateSettings.MIN_GEO_ZOOM,
      radius: SuperClusterSettingsEnum.RADIUS,
      generateId: true,
      map: (props) => ({
        price: props.price,
      }),
      reduce: (accumulatedProperties, properties) => {
        accumulatedProperties.price = Math.min(
          Number(accumulatedProperties.price),
          Number(properties.price),
        ).toString();

        return accumulatedProperties;
      },
    });

  constructor(cityStore: CityStore, realEstateStore: Store<Flat | House | Land, RealEstateFilterType>) {
    this.cityStore = cityStore;
    this.realEstateStore = realEstateStore;

    reaction(
      () =>
        this.realEstateStore.currentFilter
          ? JSON.stringify(getFilterWithoutMapParams(this.realEstateStore.currentFilter))
          : this.realEstateStore.currentFilter,
      async () => {
        if (!this.realEstateStore.currentFilter || this.state === MapRealEstateState.EMPTY) {
          return;
        }

        await this.fetchClusters();
      },
      { fireImmediately: false },
    );

    reaction(
      () => JSON.stringify(this.realEstateStore.currentFilter),
      async () => {
        this.fetchDebouncedRealEstate.cancel();

        if (
          !this.realEstateStore.currentFilter ||
          this.state === MapRealEstateState.EMPTY ||
          !this.geometry ||
          !this.extremeValueLatAndLng
        ) {
          // Если 1) Фильтра нет 2) карта еще не создана
          // (MapRealEstateState.EMPTY) 3) Не создана геометрия 4) Не созданы
          // крайние точки карты

          return;
        }

        await this.fetchDebouncedRealEstate();
      },
      {
        fireImmediately: false,
      },
    );

    makeAutoObservable(this, {
      fetchDebouncedRealEstate: false,
      fetchDebouncedRealEstateCount: false,
    });
  }

  @action createMarker = (
    feature:
      | Supercluster.PointFeature<ClusterByRealEstateDto>
      | Supercluster.ClusterFeature<PropertiesAdditionalOnePointData>,
    latLngPoint: LatLntPoint,
  ) => {
    if (isPointFeatureClusterRealEstate(feature)) {
      return RenderMarker({
        popupElement: {
          contentHtml: textPricePopup(Number(feature.properties.price)),
        },
        markerIcon: getMarkerIcon({
          count: ItemCount.ONE,
          mapZoom: this.getZoom(),
          isSelectedCluster: this.selectedCluster?.id === feature.properties.id,
        }),
        latLngPoint: latLngPoint,
        eventHandlers: {
          onMouseOver: (marker) => {
            if (this.getZoom() < MapRealEstateSettings.MIN_GEO_CLUSTER_ZOOM) {
              return;
            }

            marker.openPopup();
          },
          onClick: async (marker) => {
            if (this.getZoom() < MapRealEstateSettings.MIN_GEO_CLUSTER_ZOOM) {
              marker.closePopup();

              this.zoomMapByAroundDot(latLngPoint, this.getZoom() + 1);

              return;
            }

            if (this.selectedCluster?.id === feature.properties.id) {
              return;
            }

            this.hideRealEstate();
            this.setStateMap(MapRealEstateState.WITHOUT_AREA);
            this.draggingEnable();
            this.setSelectedCluster(feature);
            this.removeSelectedMarker();
            this.removePolygonMarker();
            this.removePolygonSelectedArea();

            marker.setIcon(
              getMarkerIcon({
                count: ItemCount.ONE,
                mapZoom: this.getZoom(),
                isSelectedCluster: true,
              }),
            );

            this.realEstateStore.setPage(InitialState.FIRST);
            this.prepareGeometryAndZoomInFilter();
            await this.fetchDebouncedRealEstate();
            this.showRealEstate();
          },
        },
      });
    }

    return RenderClusterMarker({
      popupElement: {
        contentHtml: textPricePopup(Number(feature.properties.price)),
      },
      latLngPoint: latLngPoint,
      selectedMarkerId: this.selectedCluster?.id,
      getZoom: this.getZoom,
      indexSuperCluster: this.indexSuperCluster,
      feature,
      eventHandlers: {
        onMouseOut: () => {
          if (this.selectedCluster) {
            return;
          }

          this.removePolygonMarker();
        },

        onMouseOver: (marker, featurePolygon, childrenUniqueCoordinatePoints) => {
          if (this.getZoom() < MapRealEstateSettings.MIN_GEO_CLUSTER_ZOOM) {
            return;
          }

          marker.openPopup();

          if (this.getPolygonMarker() || this.selectedCluster) {
            return;
          }

          if (featurePolygon?.geometry.type === PostGisGeometry.POLYGON) {
            this.addPolygonMarker(featurePolygon.geometry.coordinates);
          }
        },
        onClick: async (marker, featurePolygon, childrenClusterPoints) => {
          if (this.getZoom() < MapRealEstateSettings.MIN_GEO_CLUSTER_ZOOM) {
            marker.closePopup();
            this.zoomMapByAroundDot(latLngPoint, this.getZoom() + 1);

            return;
          }

          if (this.selectedCluster?.id === feature.properties.cluster_id) {
            return;
          }

          this.hideRealEstate();
          this.setStateMap(MapRealEstateState.WITHOUT_AREA);
          this.draggingEnable();
          this.setSelectedCluster(feature);
          this.removeSelectedMarker();
          this.removePolygonMarker();
          this.removePolygonSelectedArea();

          marker.setIcon(
            getMarkerIcon({
              count: feature.properties.point_count,
              mapZoom: this.getZoom(),
              isSelectedCluster: true,
            }),
          );

          if (featurePolygon?.geometry.type === PostGisGeometry.POLYGON) {
            this.addPolygonMarker(featurePolygon.geometry.coordinates);
          }

          this.realEstateStore.setPage(InitialState.FIRST);
          this.prepareGeometryAndZoomInFilter();
          await this.fetchDebouncedRealEstate();
          this.showRealEstate();
        },
      },
    });
  };

  @observable markers: L.GeoJSON = DG.geoJson(null, {
    pointToLayer: this.createMarker,
  });

  @action setIsLoadingNextPage = (state: boolean) => {
    this.isLoadingNextPage = state;
  };

  @action resetStore = () => {
    this.removeMap();
    this.setStateMap(MapRealEstateState.EMPTY);
    this.removeAllMarkers();
    this.filter = null;
    this.removePolyline();
    this.removePolygonSelectedArea();
    this.extremeValueLatAndLng = null;
    this.setSelectedCluster(null);
    this.isShowRealEstate = true;
    this.isDraggingMap = true;
    this.setGeometryAndZoom(undefined, MapRealEstateSettings.DEFAULT_GEO_ZOOM);
    this.setIsLoadingNextPage(false);
  };

  @action showRealEstate = () => {
    this.isShowRealEstate = true;
  };

  @action hideRealEstate = () => {
    this.isShowRealEstate = false;
  };

  @action
  setSelectedCluster = (
    cluster:
      | Supercluster.PointFeature<ClusterByRealEstateDto>
      | Supercluster.ClusterFeature<PropertiesAdditionalOnePointData>
      | null,
  ) => {
    this.selectedCluster = cluster;
  };

  @action generateViewPortPostGisPolygonGeometry = () => {
    const coordinates = this.mapRef.getBounds();
    const southWest = rename2gisPointCoordinates(coordinates._southWest);
    const northEast = rename2gisPointCoordinates(coordinates._northEast);
    const { geometry: geometryJson } = turf.bboxPolygon([
      southWest.longitude,
      southWest.latitude,
      northEast.longitude,
      northEast.latitude,
    ]);

    return geometryJson;
  };

  @action
  initializeMap = async (filter: RealEstateFilterType | null, user: User) => {
    await DG.then(() => {
      // загрузка кода модуля
      return DG.plugin('https://2gis.github.io/mapsapi/vendors/Leaflet.markerCluster/leaflet.markercluster-src.js');
    }).then(async () => {
      this.filter = filter;

      if (!this.filter) {
        return;
      }

      this.removeMap();

      if (this.filter.geometry && this.filter.zoom) {
        this.setGeometryAndZoom(this.filter.geometry, this.filter.zoom);
      }

      this.mapRef = DG.map('map', {
        fullscreenControl: false,
        zoomControl: false,
        center: getCoordinatesMainCity(this.filter, this.cityStore, user),
        zoom: this.zoom,
        minZoom: MapRealEstateSettings.MIN_GEO_ZOOM,
        doubleClickZoom: false,
      });

      const handleInitializeMapCommonActions = () => {
        this.customizeEventsMapRef();
        this.setExtremeValueLatAndLng();
        this.prepareGeometryAndZoomInFilter();
      };

      match(this)
        .when(
          () => this.geometry && isGeometryViewport(this.geometry),
          () => {
            if (this.geometry && isGeometryViewport(this.geometry)) {
              const correctCoordinatesForLeaflet = flipPolygonCoordinates(this.geometry.coordinates);

              this.scaleMapToViewPort(correctCoordinatesForLeaflet);
              handleInitializeMapCommonActions();
              this.setStateMap(MapRealEstateState.WITHOUT_AREA);
            }
          },
        )
        .when(
          () => this.geometry && !isGeometryViewport(this.geometry) && isPolygonGeometry(this.geometry),
          () => {
            if (!this.geometry) {
              return;
            }

            const correctCoordinatesForLeaflet = flipCoordinatesByGeometry(this.geometry);

            this.addPolygonSelectedAreaToMap(correctCoordinatesForLeaflet as Position[]);
            this.scaleMapToPolygonSelectedArea();
            handleInitializeMapCommonActions();
            this.setStateMap(MapRealEstateState.RENDERED_AREA);
          },
        )
        .when(
          () => this.filter && this.filter.city.length > 1,
          () => {
            this.scaleMapToCity();
            handleInitializeMapCommonActions();
            this.setStateMap(MapRealEstateState.WITHOUT_AREA);
          },
        )
        .otherwise(() => {
          handleInitializeMapCommonActions();
          this.setStateMap(MapRealEstateState.WITHOUT_AREA);
        });
    });

    return null;
  };

  @action
  fetchClusters = async () => {
    await this.realEstateStore.fetchDataClusters();
    await this.addClusters();
  };

  @action
  generateGeoJsonPoint<T>(lat: number, lng: number, properties: T) {
    return {
      type: 'Feature',
      geometry: {
        type: PostGisGeometry.POINT,
        coordinates: [lng, lat],
      },
      properties: properties,
    } as Supercluster.PointFeature<T>;
  }

  @action
  generateSuperCluster = (clusters: ClusterByRealEstateDto[]) => {
    const geoJsonPoints = clusters.map((val) => this.generateGeoJsonPoint(val.latitude, val.longitude, val));

    this.indexSuperCluster.load(geoJsonPoints);
  };

  @action
  getClustersSuperCluster = () => {
    const extremeValue = this.getExtremeValueLatAndLng();
    const superClusterParams = this.indexSuperCluster as unknown as Record<'points', unknown[]>;

    if (!extremeValue || !superClusterParams.points) {
      return;
    }

    const { northEast, southWest } = this.getBoundPoints();

    return this.indexSuperCluster.getClusters(
      [southWest.lng, southWest.lat, northEast.lng, northEast.lat],
      this.getZoom(),
    );
  };

  @action
  loadMarkers = async () => {
    this.removeAllMarkers();

    const superClusters = this.getClustersSuperCluster();

    if (!superClusters) {
      return;
    }

    this.markers.addData(superClusters as unknown as GeoJsonObject);
    await this.fetchDebouncedRealEstateCount();
  };

  @action
  updateMarkers = async () => {
    this.removeAllMarkers();

    const superClusters = this.getClustersSuperCluster();

    if (!superClusters) {
      return;
    }

    this.markers.addData(superClusters as unknown as GeoJsonObject);
  };

  @action
  addClusters = async () => {
    const extremeValueLatAndLng = this.getExtremeValueLatAndLng();

    if (!extremeValueLatAndLng) {
      return;
    }

    this.generateSuperCluster(this.realEstateStore.clusters);
    this.markers.addTo(this.mapRef);
    await this.loadMarkers();
  };

  @action
  removeSelectedMarker = () => {
    const selectedMarkerEl = document.getElementsByClassName('selectedMarker')[0];

    if (selectedMarkerEl) {
      selectedMarkerEl.classList.remove('selectedMarker');
    }
  };

  @action setExtremeValueLatAndLng() {
    const { northWest, southEast } = this.getBoundPoints();

    this.extremeValueLatAndLng = {
      topLatitude: northWest.lat,
      topLongitude: northWest.lng,
      bottomLatitude: southEast.lat,
      bottomLongitude: southEast.lng,
    } as ExtremeCoordinatesType;
  }

  @action
  zoomMapByAroundDot = (point: LatLntPoint | CoordinatesPoint, zoom: number) => {
    this.mapRef.setZoomAround(point, zoom);
  };

  @action getBoundPoints = () => {
    const { _southWest: southWest, _northEast: northEast } = this.mapRef.getBounds();
    const northWest = getNorthWest(southWest, northEast);
    const southEast = getSouthEast(southWest, northEast);

    return {
      southWest,
      northEast,
      northWest,
      southEast,
    };
  };

  @action getExtremeValueLatAndLng = () => {
    return this.extremeValueLatAndLng;
  };

  @action
  createScriptInitializeMap = async (filter: RealEstateFilterType | null, user: User) => {
    const script = document.createElement('script');

    script.src = `${environments.REACT_APP_MAPS_SCRIPT_URL}/2.0/loader.js?pkg=full`;
    script.async = true;
    script.onload = await this.initializeMap(filter, user);
    document.body.appendChild(script);
  };

  @action
  removeAllMarkers = () => {
    this.markers.clearLayers();
  };

  @action
  fetchDebouncedRealEstate = debounce(async () => {
    this.fetchDebouncedRealEstate.cancel();

    if (!this.mapRef) {
      return;
    }

    this.realEstateStore.setPage(InitialState.FIRST);
    await this.realEstateStore.fetchData(DateTime.now(), this.geometry, this.zoom);
  }, TimeConvert.MILLISECONDS_IN_MILLISECONDS * 500);

  @action
  fetchDebouncedRealEstateCount = debounce(async () => {
    this.fetchDebouncedRealEstateCount.cancel();

    if (!this.mapRef || !this.realEstateStore.currentFilter) {
      return;
    }

    await this.realEstateStore.fetchCount({
      ...trimFilters(this.realEstateStore.currentFilter),
      ...this.getGeometryAndZoomObjectForSave(),
    });
  }, TimeConvert.MILLISECONDS_IN_MILLISECONDS * 500);

  @action
  customizeEventsMapRef = () => {
    this.mapRef.on('mousedown', () => {
      if (this.state !== MapRealEstateState.READY_TO_DRAW) {
        return;
      }

      this.setStateMap(MapRealEstateState.DRAWING_AREA);
    });

    this.mapRef.on('dragstart', () => {
      this.isProcessingDraggingMap = true;
    });

    this.mapRef.on(
      'drag',
      debounce(async () => {
        if (!this.mapRef) {
          return;
        }

        this.setExtremeValueLatAndLng();
      }, 250 * TimeConvert.MILLISECONDS_IN_MILLISECONDS),
    );

    this.mapRef.on(
      'dragend',
      debounce(async () => {
        this.fetchDebouncedRealEstate.cancel();

        if (!this.mapRef) {
          return;
        }

        this.isProcessingDraggingMap = false;

        this.prepareGeometryAndZoomInFilter();
        await this.updateMarkers();

        if (!this.polygonSelectedArea) {
          await this.fetchDebouncedRealEstateCount();
          await this.fetchDebouncedRealEstate();
        }
      }, 250 * TimeConvert.MILLISECONDS_IN_MILLISECONDS),
    );

    this.mapRef.on('mousemove', (event: { latlng: LatLntPoint }) => {
      if (this.state !== MapRealEstateState.DRAWING_AREA) {
        return;
      }

      const { latlng } = event;

      this.drawPointOnPolyline([latlng.lat, event.latlng.lng]);
    });

    this.mapRef.on('mouseup', async () => {
      if (this.state === MapRealEstateState.DRAWING_AREA && this.polyline) {
        if (this.polyline._latlngs.length <= 2) {
          this.removePolyline();
          this.setStateMap(MapRealEstateState.WITHOUT_AREA);
          this.draggingEnable();

          return;
        }

        this.realEstateStore.setPage(InitialState.FIRST);
        this.setSelectedCluster(null);
        this.removePolygonMarker();
        this.addPolygonSelectedAreaToMap(this.polyline._latlngs);
        this.scaleMapToPolygonSelectedArea();
        this.setStateMap(MapRealEstateState.RENDERED_AREA);
        this.draggingEnable();
        this.removePolyline();
        this.showRealEstate();

        await this.fetchDebouncedRealEstate();
        await this.fetchDebouncedRealEstateCount();
      }
    });

    this.mapRef.on('zoomstart', () => {
      this.isZoomingMap = true;
      this.removeAllMarkers();
      this.mapRef.closePopup();

      if (!this.selectedCluster) {
        this.removePolygonMarker();
      }
    });

    this.mapRef.on('zoomend', async () => {
      this.fetchDebouncedRealEstate.cancel();

      if (!this.mapRef) {
        return;
      }

      this.isZoomingMap = false;
      this.setExtremeValueLatAndLng();
      this.prepareGeometryAndZoomInFilter();
      await this.updateMarkers();
      await this.fetchDebouncedRealEstate();

      if (!this.polygonSelectedArea) {
        await this.fetchDebouncedRealEstateCount();

        return;
      }
    });

    this.mapRef.on('click', async (event: { latlng: LatLntPoint }) => {
      if (this.isProcessingDraggingMap) {
        return;
      }

      if (this.getZoom() < MapRealEstateSettings.MIN_GEO_CLUSTER_ZOOM) {
        this.zoomMapByAroundDot(event.latlng, this.getZoom() + 1);

        return;
      }
    });
  };

  @action
  addPolyline = () => {
    this.polyline = DG.polyline([]).addTo(this.mapRef);
  };

  @action
  removePolyline = () => {
    if (this.polyline) {
      this.polyline.remove();
    }
  };

  @action
  getPolygonSelectedArea = () => {
    return this.polygonSelectedArea;
  };

  @action
  removePolygonSelectedArea = () => {
    if (this.polygonSelectedArea) {
      this.polygonSelectedArea.remove();
      this.polygonSelectedArea = null;
    }
  };

  @action
  removePolygonMarker = () => {
    if (this.polygonMarker) {
      this.polygonMarker.remove();
      this.polygonMarker = null;
    }
  };

  @action
  addPolygonSelectedAreaToMap = (coordinates: LatLntPoint[] | Position[]) => {
    this.polygonSelectedArea = DG.polygon(coordinates, {
      color: 'rgba(52, 121, 232, 1)', // polygon border color
      weight: 2, // border-width
      fillColor: 'rgba(52, 121, 232, 0.60)',
    });

    if (!this.polygonSelectedArea) {
      return;
    }

    this.polygonSelectedArea.addTo(this.mapRef);
  };

  @action drawPointOnPolyline = (coordinates: CoordinatesPoint) => {
    if (this.polyline) {
      this.polyline.addLatLng(coordinates);
    }
  };

  @action
  zoomIn = () => {
    if (!this.mapRef) {
      return;
    }

    this.mapRef.setZoom(this.getZoom() + 1);
  };

  @action
  setZoom = (zoom: number) => {
    this.mapRef.setZoom(zoom);
  };

  @action
  getZoom = (): number => {
    return this.mapRef.getZoom();
  };

  @action
  handleScrollEndContainer = async (element: HTMLDivElement) => {
    const containerHeight = element.offsetHeight; // Высота контейнера
    const scrollTop = element.scrollTop; // Текущая прокрутка контейнера
    const scrollHeight = element.scrollHeight; // Всего прокручиваемая высота
    // контента в контейнере
    const measurementErrorPx = 200;
    const isEndContainer = containerHeight + scrollTop + measurementErrorPx >= scrollHeight;

    if (isEndContainer) {
      const isLastPage = this.realEstateStore.realEstate.length < PageLimit.FIFTEEN * this.realEstateStore.page;

      if (isLastPage || this.isLoadingNextPage) {
        // Если страница не последняя или происходит загрузка следующей
        // страницы(так как действие scroll может несколько раз пройти при
        // одном скролле)
        return;
      }

      this.setIsLoadingNextPage(true);

      const isExistNextPage = await this.realEstateStore.summaryFetchData(
        this.realEstateStore.page + 1,
        this.getZoom(),
        this.geometry,
      );

      if (isExistNextPage) {
        this.realEstateStore.setPage(this.realEstateStore.page + 1);
      }

      this.setIsLoadingNextPage(false);
    }
  };

  @action
  setStateMap = (state: MapRealEstateState) => {
    this.state = state;
  };

  @action
  prepareGeometryAndZoomInFilter = () => {
    const selectedCluster = this.selectedCluster;

    if (selectedCluster) {
      if (isPointFeatureClusterRealEstate(selectedCluster)) {
        this.setGeometryAndZoom(selectedCluster.geometry, this.mapRef.getZoom());

        return;
      }

      const allClusterPoints = this.indexSuperCluster.getLeaves(
        selectedCluster.properties.cluster_id,
        SuperClusterSettingsEnum.LIMIT_LEAVES_POINTS,
      );

      const uniqueCoordinatePointClusters = uniqueArray(transformPointFeatureToGeometryCoordinates(allClusterPoints));
      const feature = createFeatureByCoordinates(uniqueCoordinatePointClusters);

      if (!feature) {
        return;
      }

      if (
        !isPointGeometry(feature.geometry) &&
        !isLineStringGeometry(feature.geometry) &&
        !isPolygonGeometry(feature.geometry)
      ) {
        return;
      }

      this.setGeometryAndZoom(feature.geometry, this.mapRef.getZoom());

      return;
    }

    if (this.polygonSelectedArea && !this.selectedCluster) {
      const polygonFeature = this.createGeometryPolygonSelectedArea(this.polygonSelectedArea);

      this.setGeometryAndZoom(polygonFeature.geometry, this.mapRef.getZoom());

      return;
    }

    this.setGeometryAndZoom(this.generateViewPortPostGisPolygonGeometry(), this.mapRef.getZoom());
  };

  @action
  createGeometryPolygonSelectedArea = (polygonSelectedArea: Record<string, Array<Array<LatLntPoint>>>) => {
    const coordinates = polygonSelectedArea?._latlngs[0].map((point: LatLntPoint) => [point.lat, point.lng]);

    return turf.flip(turf.lineToPolygon(turf.lineString(coordinates)));
  };

  @action
  zoomOut = () => {
    if (!this.mapRef) {
      return;
    }

    this.mapRef.setZoom(this.getZoom() - 1);
  };

  @action
  draggingEnable = () => {
    this.isDraggingMap = true;
    this.mapRef.dragging.enable();
  };

  @action
  draggingDisable = () => {
    this.isDraggingMap = false;
    this.mapRef.dragging.disable();
  };

  @action
  scaleMapToPolygonSelectedArea = () => {
    if (!this.polygonSelectedArea) {
      return;
    }

    const { _southWest: southWest, _northEast: northEast } = this.polygonSelectedArea._bounds;

    if (!southWest || !northEast) {
      return;
    }

    this.mapRef.fitBounds([southWest, northEast]);
  };

  @action
  scaleMapToViewPort = (coordinates: Position[][]) => {
    const [southWest, southEast, northEast, northWest] = coordinates;

    this.mapRef.fitBounds([southWest, northEast]);
  };

  @action
  scaleMapToCity = () => {
    if (!this.filter) {
      return;
    }

    const coordinates = getCoordinatesByMainDiagonalCities(this.filter, this.cityStore.findCities());

    this.mapRef.fitBounds([coordinates.northEast, coordinates.southWest]);
  };

  @action
  clearGeometryOnMarkerAndPolygon = async (clearMapParamsInFilterAndMap = false) => {
    if (this.mapRef) {
      this.setStateMap(MapRealEstateState.WITHOUT_AREA);
      this.draggingEnable();
      this.removeSelectedMarker();
      this.removePolygonMarker();
      this.setSelectedCluster(null);
      this.removePolygonSelectedArea();
      this.setZoom(MapRealEstateSettings.DEFAULT_GEO_ZOOM);
    }

    runInAction(() => {
      this.setGeometryAndZoom(undefined, MapRealEstateSettings.DEFAULT_GEO_ZOOM);

      if (this.realEstateStore.currentFilter && clearMapParamsInFilterAndMap) {
        const copyFilter = cloneDeep(this.realEstateStore.currentFilter);

        delete copyFilter.geometry;
        delete copyFilter.zoom;

        this.realEstateStore.setCurrentFilter(copyFilter);
      }

      this.realEstateStore.setPage(InitialState.FIRST);
    });

    await this.fetchDebouncedRealEstate();
    await this.fetchDebouncedRealEstateCount();
  };

  @action
  addPolygonMarker = (polygonCoordinates: CoordinatesGeometryType) => {
    this.polygonMarker = DG.polygon(polygonCoordinates, {
      color: 'rgba(52, 121, 232, 1)', // polygon border color
      weight: 2, // border-width
      fillColor: 'rgba(52, 121, 232, 0.60)',
    });

    this.mapRef.addLayer(this.polygonMarker);
  };

  @action
  getPolygonMarker = () => {
    return this.polygonMarker;
  };

  @action
  setGeometryAndZoom = (geometry: GeometryWithoutGeometryCollection | undefined, zoom: number) => {
    runInAction(() => {
      this.geometry = geometry;
      this.zoom = zoom;
    });
  };

  @action
  removeMap = () => {
    if (this.mapRef) {
      this.mapRef.remove();
      this.mapRef = null;
    }
  };

  @action
  getGeometryAndZoomObjectForSave = () => {
    if (!this.mapRef) {
      return {};
    }

    const polygon = this.polygonSelectedArea
      ? this.createGeometryPolygonSelectedArea(this.polygonSelectedArea).geometry
      : this.generateViewPortPostGisPolygonGeometry();

    return {
      geometry: polygon,
      zoom: limitGeoParamZoomApi(this.zoom),
    };
  };
}
