import { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';
import { match } from 'ts-pattern';
import { CatalogStore } from '#/entities/domain/catalog.store';
import { Flat, FlatStore } from '#/entities/real-estate/flat';
import { Comment } from '#entities/comment';
import { User } from '#entities/user';
import { CallTourStepNameIndex } from '#entities/user/model/tour/call-tour/call-tour-step-name-index';
import { defaultStylesByStep } from '#entities/user/model/tour/common/const/styles';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { userStore } from '#entities/user/model/user-store.context';
import { Anchor, InitialState, ItemCount, StatusName, TimeConvert } from '#shared/enums';
import { DrawerPositionRight } from '#shared/types/drawer-position';
import { FlatFakeInfo, generateFlatFakeData } from '../common/builder/flat-response-builder';
import { ITourWithFakeFlat } from '../common/interface/tour-with-fake-flat.interface';
import { AdvancedStep, State } from '../user-Instruction-store';

interface ICallState extends State {
  fakeCall: boolean;
  fakeComments: Comment[];
  openedMakeCallDrawerId: string | null;
  openCommentDrawer: DrawerPositionRight;
  isOpenHowToCall: boolean;
}

export class CallTour implements ITourWithFakeFlat {
  data: ICallState;
  flatStore: FlatStore = {} as FlatStore;
  catalogStore: CatalogStore = {} as CatalogStore;
  fakeFlat: Flat | null = null;

  constructor(onboardingStep: IOnboardingStep) {
    const { content, ...otherFields } = onboardingStep;

    this.data = {
      ...otherFields,
      run: false,
      stepIndex: 0,
      fakeCall: false,
      openedMakeCallDrawerId: null,
      steps: [
        {
          content: content.step1,
          placement: 'right-end',
          disableBeacon: true,
          spotlightPadding: 0,
          spotlightClicks: true,
          offset: -8,
          target: '.realEstate:first-child>.detail-button-cell',
          showNavigationButtons: false,
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              right: 30,
            },
          },
        },
        {
          content: content.step2,
          placement: 'bottom-end',
          spotlightPadding: 4,
          offset: 8,
          target: '.call-button',
          showNavigationButtons: true,
          disableBeacon: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              right: '28px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: 4,
            },
          },
        },
        {
          content: content.step3,
          placement: 'bottom-end',
          spotlightPadding: 4,
          offset: 4,
          target: '.how-to-call-instruction-button',
          showNavigationButtons: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              right: '28px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: 4,
            },
          },
        },
        {
          content: content.step4,
          placement: 'bottom-end',
          spotlightPadding: 4,
          offset: 0,
          target: '.call-button',
          showNavigationButtons: true,
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              right: 104,
            },
          },
        },
        {
          content: content.step5,
          placement: 'bottom-end',
          spotlightPadding: 4,
          offset: 0,
          target: '.call-button',
          showNavigationButtons: true,
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              right: 104,
            },
          },
        },
        {
          content: content.step6,
          placement: 'top-end',
          spotlightPadding: 12,
          offset: 16,
          target: '#container-for-status-chip',
          showNavigationButtons: true,
          disableBeacon: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              bottom: '-8px',
              right: '28px',
              rotate: '180deg',
            },
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: 8,
            },
          },
        },
        {
          content: content.step7,
          placement: 'bottom-start',
          spotlightPadding: 0,
          offset: 4,
          target: '.comment-box-container-with-header',
          showNavigationButtons: false,
          disableBeacon: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              left: '10px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: 35,
            },
          },
        },
      ],
      fakeComments: [],
      openCommentDrawer: {
        right: false,
      },
      isOpenHowToCall: false,
    };
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setData = (data: CallTour['data']) => {
    this.data = data;
  };

  goNextStep = () => {
    this.setStepIndex(this.data.stepIndex + ItemCount.ONE);
  };

  setFakeCall = (fakeCall: boolean) => {
    this.data.fakeCall = fakeCall;
  };

  goPrevStep = () => {
    this.setStepIndex(this.data.stepIndex - ItemCount.ONE);
  };

  setRun = (run: boolean) => {
    this.data.run = run;
  };

  setStepIndex = (index: number) => {
    this.data.stepIndex = index;
  };

  handleCallback = (data: CallBackProps, user: User) => {
    const { action, index, status, type } = data;
    const countStepWithMinusOne = this.data.steps.length - ItemCount.ONE;

    if (index === countStepWithMinusOne && type === EVENTS.TOOLTIP) {
      // Завершение тура
      if (!userStore.checkLocalHasCompleteOnboarding(this.data.id)) {
        userStore.createOnboardingProgress(user.id, this.data.id);
      }

      return;
    }

    if (action === ACTIONS.CLOSE) {
      this.handleClose();
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const futureIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      match({ data, futureIndex })
        .when(
          ({ futureIndex }) =>
            [CallTourStepNameIndex.NOT_FOUND_PHONE, CallTourStepNameIndex.BUTTON_INSTRUCTION_HOW_TO_CALL].includes(
              futureIndex,
            ),
          () => {
            this.setFakeCall(false);

            if (this.fakeFlat) {
              this.fakeFlat.phone = null;
            }

            this.setOpenCommentDrawer(Anchor.ANCHOR_RIGHT, false);
            this.setStepIndex(futureIndex);
          },
        )
        .with({ futureIndex: CallTourStepNameIndex.FOUND_PHONE }, () => {
          this.setFakeCall(false);

          if (this.fakeFlat) {
            this.fakeFlat.phone = FlatFakeInfo.phone;
          }

          this.setOpenCommentDrawer(Anchor.ANCHOR_RIGHT, false);
          this.setStepIndex(futureIndex);
        })
        .when(
          ({ futureIndex }) =>
            [CallTourStepNameIndex.CALLED_USER, CallTourStepNameIndex.STATUS_CHIP].includes(futureIndex),
          () => {
            // На this.fakeCall навешана реакция в компоненте
            // main-section-object-info-container - disposeFakeCall
            this.setFakeCall(true);
            this.setStepIndex(futureIndex);
          },
        )
        .with({ futureIndex: CallTourStepNameIndex.COMMENT_DRAWER }, () => {
          // На this.fakeCall навешана реакция в компоненте
          // main-section-object-info-container - disposeFakeCall
          this.setOpenCommentDrawer(Anchor.ANCHOR_RIGHT, true);
          this.setFakeComments(user);
          this.setFakeStatus(user);

          setTimeout(() => {
            this.setStepIndex(futureIndex);
          }, TimeConvert.MILLISECONDS_IN_MILLISECONDS * 500);
        })
        .otherwise(() => {
          this.setFakeCall(false);

          if (this.fakeFlat) {
            this.fakeFlat.phone = null;
          }

          this.setOpenedMakeCallDrawerId(null);
          this.setOpenCommentDrawer(Anchor.ANCHOR_RIGHT, false);
          this.setStepIndex(futureIndex);
        });
    }
  };

  handleOpen = (user?: User) => {
    if (user) {
      this.setFakeFlat(this.makeMutationFlat(generateFlatFakeData({ userId: user.id }), user.id));
    }

    if (this.fakeFlat) {
      this.addFakeFlatToList(this.fakeFlat);
    }

    this.setRun(true);
    this.setStepIndex(0);
  };

  @action
  handleClose = () => {
    this.setRun(false);
    this.setFakeCall(false);
    this.setOpenedMakeCallDrawerId(null);
    this.setOpenCommentDrawer(Anchor.ANCHOR_RIGHT, false);
    this.data.fakeComments = [];
    this.setStepIndex(CallTourStepNameIndex.BUTTON_DETAIL);
    this.setIsOpenHowToCall(false);
    this.flatStore.clearComments();
    this.removeFakeFlatToList();
    this.setFakeFlat(null);
  };

  getData = () => {
    return this.data;
  };

  setOpenedMakeCallDrawerId = (openedMakeCallDrawerId: string | null) => {
    this.data.openedMakeCallDrawerId = openedMakeCallDrawerId;
  };

  setOpenCommentDrawer = (anchor: Anchor.ANCHOR_RIGHT, open: boolean) => {
    const prevAnchor = this.data.openCommentDrawer[anchor];

    this.data.openCommentDrawer = {
      ...this.data.openCommentDrawer,
      [anchor]: open ? !prevAnchor : false,
    };
  };

  setFakeComments = (user: User) => {
    this.flatStore.comments = [
      {
        id: '9f640802-f1d4-45da-96e8-693cdc93590c',
        userId: user.id,
        text: 'Клиент еще не принял решение',
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
        isAutoCreated: true,
        user: {
          fullName: user.fullName,
          avatarUrl: user.avatarUrl,
        },
      },
    ];
    this.flatStore.currentCommentPage = InitialState.FIRST;
  };

  setSteps = (steps: AdvancedStep[]) => {
    this.data.steps = steps;
  };

  setIsOpenHowToCall = (isOpenHowToCall: boolean) => [(this.data.isOpenHowToCall = isOpenHowToCall)];

  setFlatStore = (flatStore: FlatStore) => {
    this.flatStore = flatStore;
  };

  setCatalogStore = (catalogStore: CatalogStore) => {
    this.catalogStore = catalogStore;
  };

  setFakeStatus = (user: User) => {
    const fakeFlat = this.fakeFlat;

    if (fakeFlat) {
      const thinksStatus = this.catalogStore.findStatusByName(StatusName.THINKS);

      if (thinksStatus?.id) {
        fakeFlat.userStatus = {
          userId: user.id,
          statusId: thinksStatus.id,
        };
      }

      const index = this.flatStore.realEstate.findIndex((flat) => flat.id === fakeFlat.id);

      if (index !== undefined) {
        this.flatStore.realEstate.splice(index, 1, fakeFlat);
      }
    }
  };

  makeMutationFlat = (fakeFlat: Flat | null, userId: string) => {
    if (this.flatStore && fakeFlat && this.catalogStore) {
      const notAnswerStatus = this.catalogStore.findStatusByName(StatusName.NO_RESPONSE);

      if (notAnswerStatus && notAnswerStatus.id) {
        fakeFlat.phone = null;
        fakeFlat.userStatus = {
          userId,
          statusId: notAnswerStatus.id,
        };
      }

      return fakeFlat;
    }

    return null;
  };

  setFakeFlat = (flat: Flat | null) => {
    this.fakeFlat = flat;
  };

  addFakeFlatToList = (flat: Flat) => {
    this.flatStore.realEstate.unshift(flat);
  };

  removeFakeFlatToList = () => {
    const fakeFlat = this.fakeFlat;

    if (fakeFlat) {
      const index = this.flatStore.realEstate.findIndex((flat) => flat.id === fakeFlat.id);

      if (index !== undefined) {
        this.flatStore.realEstate.splice(index, 1);
      }
    }
  };
}
