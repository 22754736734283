import axios from 'axios';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { ThemeProvider } from '@mui/material/styles';
import { socket, SocketContext } from '#/socket';
import { UserProvider } from '#entities/user/model/user-context';
import { UserStoreProvider } from '#entities/user/model/user-store.context';
import { Localization } from '#shared/enums';
import './index.css';
import Routing from './providers/router/router';
import { theme } from './providers/theme';
import { RootStoreProvider } from './root/root-store.context';

dayjs.locale(Localization.RU);
axios.defaults.withCredentials = true;

const App = observer(() => {
  return (
    <UserProvider>
      <UserStoreProvider>
        <SocketContext.Provider value={socket}>
          <RootStoreProvider>
            <ThemeProvider theme={theme}>
              <Routing />
            </ThemeProvider>
          </RootStoreProvider>
        </SocketContext.Provider>
      </UserStoreProvider>
    </UserProvider>
  );
});

export default App;
