import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Stack, styled, SxProps, Typography, useTheme } from '@mui/material';
import { CrmNameRu } from '#/shared/enums/geo/crm-name-ru.enum';
import { useUser } from '#entities/user/model/user-context';
import { WorkAddressUserDto } from '#shared/dto/work-address-user-dto';
import { Anchor } from '#shared/enums';
import { getPathUrl } from '#shared/lib/get-path-url';
import { separateThousand } from '#shared/lib/separate-thousand';
import { DrawerInfoContainer } from '#shared/styles/styles';
import { UserAvatar } from './user-avatar';

type DrawerObjectInfoProps = {
  state: {
    right: boolean;
  };
  setOpenedDrawer: React.Dispatch<React.SetStateAction<string | null>>;
  userId: string;
  userData: WorkAddressUserDto;
};

const HeaderStatTeam = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.custom.base.module.first,
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  padding: '16px 24px 8px 24px',
}));

const ContainerBodyStatTeam = styled(Stack)(({ theme }) => ({
  padding: '8px 24px',
  gap: theme.custom.base.module.first,
  overflowY: 'auto',
  overflowX: 'hidden',
}));

type ContainerRealEstateStatTeamProps = {
  title: string;
  callsCount: number | string;
  saveCount: number | string;
  amountCommission: number | string;
  sx?: SxProps;
};

const ContainerRealEstateStatTeam: React.FC<ContainerRealEstateStatTeamProps> = ({
  title,
  callsCount,
  saveCount,
  amountCommission,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Stack
      borderRadius={'12px'}
      border={`1px solid ${theme.custom.border.default}`}
      padding={'12px 16px'}
      gap={theme.custom.base.module.first}
      sx={sx}
    >
      <Typography variant={'body1Medium'}>{title}</Typography>
      <Box display={'grid'} gridTemplateColumns={'repeat(3,1fr)'}>
        <Stack gap={theme.custom.base.module.zero_five}>
          <Typography variant={'body2'} color={theme.palette.text.secondary}>
            Звонки
          </Typography>
          <Typography variant={'body1NumMedium'} color={theme.palette.text.primary}>
            {callsCount}
          </Typography>
        </Stack>
        <Stack gap={theme.custom.base.module.zero_five}>
          <Typography variant={'body2'} color={theme.palette.text.secondary}>
            Сохранено
          </Typography>
          <Typography variant={'body1NumMedium'} color={theme.palette.text.primary}>
            {saveCount}
          </Typography>
        </Stack>
        <Stack gap={theme.custom.base.module.zero_five}>
          <Typography variant={'body2'} color={theme.palette.text.secondary}>
            Комиссия
          </Typography>
          <Typography variant={'body1NumMedium'} color={theme.palette.text.primary}>
            {separateThousand(amountCommission)} ₽
          </Typography>
        </Stack>
      </Box>
    </Stack>
  );
};

export const DrawerTeamInfo: React.FC<DrawerObjectInfoProps> = observer(
  ({ state, setOpenedDrawer, userId, userData }) => {
    const theme = useTheme();
    const user = useUser();
    const getContentDrawer = () => {
      const closeDrawer = () => {
        setOpenedDrawer(null);
      };
      const totalCount = {
        callsCount: userData.flatCallsCount + userData.houseCallsCount + userData.landCallsCount,
        saveCount: userData.crmFlatCount + userData.crmHouseCount + userData.crmLandCount,
        amountCommission: userData.crmFlatCommissionSum + userData.crmHouseCommission + userData.crmLandCommission,
      };
      const { getRiesUserLink, getSpaceUserLink } = getPathUrl();

      return (
        <DrawerInfoContainer key={userId} sx={{ width: '450px' }}>
          <HeaderStatTeam>
            <UserAvatar
              sx={{ width: '40px', height: '40px', margin: '3px 4px 0px 0px' }}
              avatarUrl={userData.avatarUrl}
            />
            <Stack padding={'6px 0px'} gap={theme.custom.base.module.zero_five} flexGrow={1}>
              <Typography variant={'headline5'} color={theme.palette.text.primary}>
                {userData.fullName}
              </Typography>
              <Link
                style={{
                  color: theme.palette.primary.main,
                }}
                to={user.crmName === CrmNameRu.RIES ? getRiesUserLink(userData.riesId) : getSpaceUserLink()}
                target={'_blank'}
                rel='noreferrer noopener'
              >
                <Typography variant={'body2'}>Перейти в {user.crmName}</Typography>
              </Link>
            </Stack>
            <Box onClick={() => closeDrawer()}>
              <CloseIcon
                className={'closeButton'}
                sx={{
                  color: theme.palette.text.secondary,
                  cursor: 'pointer',
                }}
              />
            </Box>
          </HeaderStatTeam>
          <ContainerBodyStatTeam padding={'0px 24px'}>
            <ContainerRealEstateStatTeam
              title={'Квартиры'}
              callsCount={separateThousand(userData.flatCallsCount)}
              saveCount={separateThousand(userData.crmFlatCount)}
              amountCommission={userData.crmFlatCommissionSum}
            />
            <ContainerRealEstateStatTeam
              title={'Дома'}
              callsCount={separateThousand(userData.houseCallsCount)}
              saveCount={separateThousand(userData.crmHouseCount)}
              amountCommission={separateThousand(userData.crmHouseCommission)}
            />
            <ContainerRealEstateStatTeam
              title={'Участок'}
              callsCount={separateThousand(userData.landCallsCount)}
              saveCount={separateThousand(userData.crmLandCount)}
              amountCommission={separateThousand(userData.crmLandCommission)}
            />
            <ContainerRealEstateStatTeam
              sx={{ border: `1px solid ${theme.custom.border.hover}` }}
              title={'Всего'}
              callsCount={separateThousand(totalCount.callsCount)}
              saveCount={separateThousand(totalCount.saveCount)}
              amountCommission={separateThousand(totalCount.amountCommission)}
            />
          </ContainerBodyStatTeam>
        </DrawerInfoContainer>
      );
    };

    return (
      <React.Fragment>
        <Drawer
          anchor={Anchor.ANCHOR_RIGHT}
          open={state[Anchor.ANCHOR_RIGHT]}
          sx={{
            width: '450px',
            right: '32px',
            left: 'auto',
            '.MuiDrawer-paper': {
              boxShadow: 'none',
              backgroundColor: 'transparent',
              right: 'auto',
              padding: '16px',
              height: 'calc(100% - 32px)',
            },
          }}
        >
          {getContentDrawer()}
        </Drawer>
      </React.Fragment>
    );
  },
);
