import axios from 'axios';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { environments } from '#/environment';
import { Status } from '#entities/status';
import { Domain, StatusName } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { CrmStatus } from '../crm-status';
import { DomainSource } from './model/type';

export class CatalogStore {
  @observable
  domains: DomainSource[] = [];

  @observable
  private statuses: Status[] = [];

  @observable
  private crmStatuses: CrmStatus[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchDomains() {
    try {
      const response = await axios.get(`${environments.REACT_APP_PROXY}/api/domains`);

      runInAction(() => {
        this.domains = response.data;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  findDomains() {
    return this.domains;
  }

  @action
  findDomainByValue(domain: Domain) {
    return this.domains.find((domainSource) => domainSource.value === domain);
  }

  @action
  generateFullUrlByDomain(domain: Domain, url: string) {
    const domainUrl = this.findDomainByValue(domain)?.url;

    return `https://${domainUrl}${url}`;
  }

  @action
  async fetchStatuses() {
    try {
      const response = await axios.get<Status[]>(`${environments.REACT_APP_PROXY}/api/statuses`);

      runInAction(() => {
        const statuses = response.data;
        const defaultStatus: Status = {
          id: null,
          name: StatusName.NOT_SELECTED,
          description: StatusName.NOT_SELECTED,
          hexColor: '',
          createdAt: '',
          updatedAt: '',
        };

        statuses.unshift(defaultStatus);

        this.statuses = statuses;
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchCrmStatuses() {
    try {
      const response = await axios.get<CrmStatus[]>(`${environments.REACT_APP_PROXY}/api/crm-statuses`);

      runInAction(() => {
        this.crmStatuses = response.data;
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  findStatuses() {
    return this.statuses;
  }

  @action
  findCrmStatuses() {
    return this.crmStatuses;
  }

  @action
  findStatusByUserId(statusId: string | null): Status | undefined {
    return this.statuses.find((status) => status.id === statusId);
  }

  @action
  findStatusByName(name: string): Status | undefined {
    return this.statuses.find((status) => status.name === name);
  }
}
