export class LocalStorageService {
  static getItemStorage<T>(name: string) {
    try {
      const item = localStorage.getItem(name);

      if (item) {
        return JSON.parse(item) as T; // Тип any используется, так как конкретный тип неизвестен
      }
    } catch (e) {
      alert('Ошибка получения storage');
    }
  }

  static setItemStorage<T>(name: string, data: T) {
    localStorage.setItem(name, JSON.stringify(data));
  }
}
