export enum EventKey {
  BACKSPACE = 'Backspace',
  TAB = 'Tab',
  ENTER = 'Enter',
  LEFT_SHIFT = 'Shift',
  RIGHT_SHIFT = 'Shift',
  LEFT_CONTROL = 'Control',
  RIGHT_CONTROL = 'Control',
  LEFT_ALT = 'Alt',
  RIGHT_ALT = 'Alt',
  PAUSE = 'Pause',
  CAPSLOCK = 'CapsLock',
  ESCAPE = 'Escape',
  SPACE = '',
  PAGE_UP = 'PageUp',
  PAGE_DOWN = 'PageDown',
  END = 'End',
  HOME = 'Home',
  LEFT_ARROW = 'ArrowLeft',
  RIGHT_ARROW = 'ArrowRight',
  UP_ARROW = 'ArrowUp',
  DOWN_ARROW = 'ArrowDown',
  PRINT_SCREEN = 'PrintScreen',
  INSERT = 'Insert',
  DELETE = 'Delete',
}
