import React, { CSSProperties, useState } from 'react';
import { Stack, useTheme } from '@mui/material';
import { Domain } from '#shared/enums';
import { fixImage } from '#shared/lib/fix-image';
import { MoveImagePlug } from '#shared/models/move-image-plug';
import { Spinner } from '#shared/ui-kit/spinner/spinner';
import { DomainSource } from '../../../domain';
import type { UrlRealEstateImage } from '../type';

type ImageWithDoubleExpositionProps = {
  backImageProps?: {
    style?: CSSProperties;
  };
  forwardImageProps?: {
    style?: CSSProperties;
  };
  width: string;
  height: string;
  image: UrlRealEstateImage;
  domainSource: DomainSource[];
  setUrls: React.Dispatch<React.SetStateAction<UrlRealEstateImage[]>>;
  showLoader?: boolean;
};

export const ImageWithDoubleExposition: React.FC<ImageWithDoubleExpositionProps> = ({
  width,
  height,
  backImageProps,
  forwardImageProps,
  image,
  domainSource,
  setUrls,
  showLoader = true,
}) => {
  const [isLoadingImage, setImageLoadingStates] = useState<boolean>(true);
  const theme = useTheme();
  const isApplyShowFilter = isLoadingImage && showLoader;
  const handleLoadImage = (currentTarget: EventTarget & HTMLImageElement, image: UrlRealEstateImage) => {
    if (!isLoadingImage) {
      return;
    }

    if (
      image.domain === Domain.MOVE &&
      currentTarget.naturalWidth === MoveImagePlug.width &&
      currentTarget.naturalHeight === MoveImagePlug.height
    ) {
      currentTarget.src = `${image.cdnDomain}${fixImage(
        image.url,
        image.cdnDomain,
        image.domain,
        domainSource,
        setUrls,
      )}`;

      return;
    }

    setImageLoadingStates(false);
    currentTarget.onerror = null;
  };

  return (
    <>
      {isApplyShowFilter && (
        <Stack
          sx={{
            marginTop: '4px',
            width: width,
            height: height,
            borderRadius: '12px',
            background: '#131D3514',
          }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Spinner sx={{ color: theme.palette.primary.main }} size={'24px'} />
        </Stack>
      )}
      <img
        style={{
          position: 'absolute',
          borderRadius: '12px',
          backgroundImage: `url(${image.cdnDomain}${image.url})`,
          width: width,
          height: height,
          ...backImageProps?.style,
          display: isLoadingImage ? 'none' : 'block',
        }}
        onLoad={({ currentTarget }) => {
          handleLoadImage(currentTarget, image);
        }}
        src={`${image.cdnDomain}${image.url}`}
        onError={({ currentTarget }) => {
          currentTarget.src = `${image.cdnDomain}${fixImage(
            image.url,
            image.cdnDomain,
            image.domain,
            domainSource,
            setUrls,
          )}`;
        }}
        rel='noreferrer noopener'
        alt={''}
      />
      <img
        style={{
          position: 'relative',
          borderRadius: '12px',
          objectFit: 'contain',
          width: width,
          height: height,
          ...forwardImageProps?.style,
          display: isLoadingImage ? 'none' : 'block',
        }}
        onLoad={({ currentTarget }) => {
          handleLoadImage(currentTarget, image);
        }}
        src={`${image.cdnDomain}${image.url}`}
        onError={({ currentTarget }) => {
          currentTarget.src = `${image.cdnDomain}${fixImage(
            image.url,
            image.cdnDomain,
            image.domain,
            domainSource,
            setUrls,
          )}`;
        }}
        rel='noreferrer noopener'
        alt={''}
      />
    </>
  );
};
