import React, { useState } from 'react';
import axios, { AxiosError, HttpStatusCode } from 'axios';
import { observer } from 'mobx-react';
import { match } from 'ts-pattern';
import { VisibilityOff } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { resetAllowedUserStorage } from '#/entities/user/lib/reset-user-storage';
import { environments } from '#/environment';
import { User } from '#entities/user';
import { UserData } from '#entities/user/model/type';
import loginImg from '#shared/assets/images/loginImage.png';
import logo from '#shared/assets/images/RHOOD_LOGO.svg';
import { FontSize, FontWeight, LocalStorageName, PagePath, Size } from '#shared/enums';
import { catchEnterKeyDown } from '#shared/lib/catch-enter-key-down';
import { ButtonContainer, LoginButton, LoginContainer } from '#shared/styles/styles';
import { LinkToExternalSite } from '#shared/ui-kit/link-to-external-site/link-to-external-site';

const LoginPage = observer(() => {
  const [statusError, setStatusError] = useState<HttpStatusCode | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = async () => {
    try {
      const res = await axios.post<User & UserData>(`${environments.REACT_APP_PROXY}/api/users/login/`, {
        login: login,
        password: password,
      });
      const user = res.data;

      resetAllowedUserStorage();
      localStorage.setItem(
        LocalStorageName.USER,
        JSON.stringify({
          id: user.id,
          token: user.token,
        }),
      );
      window.location.href = PagePath.FLATS;
    } catch (error) {
      const err = error as AxiosError;

      const status: number | null = err.response ? err.response.status : null;

      if (!status) {
        return;
      }

      setStatusError(status);
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;

    setPassword(newPassword);
  };

  return (
    <Stack>
      <Box className='split left'>
        <Box className='centered'>
          <img alt={''} src={logo} />
          <LoginContainer>
            <FormControl error={Boolean(statusError)} sx={{ width: '100%' }}>
              <TextField
                onKeyDown={(event) => catchEnterKeyDown(event, handleLogin)}
                autoComplete={'off'}
                error={Boolean(statusError)}
                placeholder={'Логин'}
                InputProps={{
                  sx: { borderRadius: '8px', height: '56px' },
                }}
                size={Size.SMALL}
                id='login'
                variant='outlined'
                onChange={(event) => setLogin(event.target.value)}
              />
            </FormControl>
            <FormControl error={Boolean(statusError)} sx={{ width: '330px' }}>
              <TextField
                onKeyDown={(event) => catchEnterKeyDown(event, handleLogin)}
                error={Boolean(statusError)}
                placeholder={'Пароль'}
                type={showPassword ? 'password' : 'text'}
                InputProps={{
                  sx: { borderRadius: '8px', height: '56px' },
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge='end'
                        aria-label='toggle password visibility'
                        style={{ visibility: password ? 'visible' : 'hidden' }}
                      >
                        {showPassword ? <VisibilityIcon /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size={Size.SMALL}
                id='password'
                variant='outlined'
                value={password}
                onChange={handlePasswordChange}
              />
              {statusError && (
                <FormHelperText
                  sx={{
                    fontSize: FontSize.TWELFTH_FONT,
                    fontWeight: FontWeight.REGULAR,
                    fontFeatureSettings: `clig' off, 'liga' off'`,
                  }}
                >
                  {match(statusError)
                    .with(HttpStatusCode.Unauthorized, () => 'Неправильно введен логин или пароль.')
                    .with(
                      HttpStatusCode.Forbidden,
                      () =>
                        'Для разблокировки аккаунта через корпоративный чат-бот напишите одну из команд:“Блок”, “Пароль” или “Разблок”.',
                    )
                    .with(HttpStatusCode.NotFound, () => (
                      <>
                        Ваш город не добавлен в базу парсера. Пожалуйста, обратитесь в чат{' '}
                        {
                          <LinkToExternalSite
                            to={`${environments.REACT_APP_TELEGRAM_URL}/${environments.REACT_APP_TECHNICAL_SUPPORT}`}
                            text={'технической поддержки'}
                            underline={false}
                          />
                        }
                        .
                      </>
                    ))
                    .with(HttpStatusCode.FailedDependency, () => 'Не удалось авторизоваться, попробуйте еще раз.')
                    .with(
                      HttpStatusCode.ExpectationFailed,
                      () => 'Технические неполадки, попробуйте авторизоваться позже.',
                    )
                    .otherwise(() => `Произошла ошибка при входе(${statusError} статус ошибки). Попробуйте еще раз.`)}
                </FormHelperText>
              )}
            </FormControl>
          </LoginContainer>
          <ButtonContainer>
            <LoginButton id={'auth_button'} variant='contained' color='primary' size={Size.LARGE} onClick={handleLogin}>
              Войти
            </LoginButton>
          </ButtonContainer>
        </Box>
      </Box>
      <Box className='split right'>
        <img className='adaptive-image' src={loginImg} alt='Avatar man' />
      </Box>
    </Stack>
  );
});

export default LoginPage;
