import { makeAutoObservable } from 'mobx';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { onboardingControllerGetAllOnboardingSteps } from '#shared/api/generated-api/api';

export class OnboardingController {
  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  static getAllOnboardingSteps = async () => {
    return (await onboardingControllerGetAllOnboardingSteps()) as unknown as IOnboardingStep[];
  };
}
