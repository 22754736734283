import axios, { HttpStatusCode } from 'axios';
import { DateTime } from 'luxon';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { RealEstateFilterType } from '#/entities/real-estate/common/type';
import { environments } from '#/environment';
import { FlatFilterType } from '#entities/real-estate/flat';
import { HouseFilterType } from '#entities/real-estate/house';
import { LandFilterType } from '#entities/real-estate/land';
import { IUserOnboardingProgressSteps } from '#entities/user/model/tour/common/interface/user-onboarding.interface';
import {
  apiUserControllerCreateUserOnboardingProgress,
  apiUserControllerGetUserOnboardingProgress,
} from '#shared/api/generated-api/api';
import { OnboardingStepWithCompletionResponse } from '#shared/api/generated-api/api.schemas';
import { InitialState, PagePath, RealEstateTypeUpperCase, TimeConvert } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { filterFieldFromObject } from '#shared/lib/remove-empty-field-from-object';
import { spyObjectByFieldValue } from '#shared/lib/spy-object-by-field-value';
import { SubscriptionPlan } from '#shared/types/subscription-plan';
import { resetAllowedUserStorage } from '../lib/reset-user-storage';
import { RealEstateFilters, User, UserStat, UserStatDto } from './type';

export class UserStore {
  @observable statAction: UserStat = {} as UserStat;
  @observable loadingStat: boolean = true;
  @observable loadingUserOnboardingProgress: boolean = true;
  @observable subscriptionPlans: SubscriptionPlan[] = [];
  @observable userRealEstateFilters: RealEstateFilters<RealEstateFilterType>[] = [];
  @observable userAllFilters: RealEstateFilters<RealEstateFilterType>[] = [];
  @observable userOnboardingProgressSteps: IUserOnboardingProgressSteps[] = [];
  @observable
  activeUserFiltersCount: number = InitialState.ZERO;

  @observable
  isUserFilterLoading: boolean = true;

  @observable isFilterRecentlyAdded: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  logout() {
    axios
      .post(`${environments.REACT_APP_PROXY}/api/users/logout`)
      .then(() => {
        window.location.href = PagePath.LOGIN;
        resetAllowedUserStorage();
      })
      .catch((error) => {
        handleAxiosError(error);
      });
  }

  @action
  setLoadingStat(loadingStatus: boolean) {
    this.loadingStat = loadingStatus;
  }

  @action
  async fetchStat(userId: string) {
    this.setLoadingStat(true);

    try {
      const userStat: UserStatDto = await axios
        .get(`${environments.REACT_APP_PROXY}/api/users/${userId}/statistics`)
        .then((response) => {
          return response.data;
        });

      runInAction(() => {
        this.statAction = userStat;
      });
    } catch (error) {
      handleAxiosError(error);
    }

    this.setLoadingStat(false);
  }

  @action
  findUserStat() {
    return this.statAction;
  }

  async saveFilter({
    filter,
    name,
    realEstateType,
  }: {
    filter: RealEstateFilterType;
    name: string;
    realEstateType: RealEstateTypeUpperCase;
  }): Promise<HttpStatusCode> {
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: {
          ...filter,
          name,
          realEstateType,
        },
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
      exceptionTransformField: ['address', 'name'],
    });

    return await axios
      .post(`${environments.REACT_APP_PROXY}/api/user-filters`, {
        ...filteredObjectFilter,
        isNotificationEnabled: false,
      })
      .then(async (res) => {
        this.isFilterRecentlyAdded = true;
        await this.fetchRealEstateFilterData(realEstateType);

        return res.status;
      })
      .catch((err) => err.response.status);
  }

  @action
  async updateFilter({
    filter,
    name,
    realEstateType,
    isNotificationEnabled,
  }: {
    filter: RealEstateFilters<RealEstateFilterType>;
    name: string;
    realEstateType: RealEstateTypeUpperCase;
    isNotificationEnabled: boolean;
  }): Promise<HttpStatusCode> {
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: {
          ...filter.params,
          name,
          realEstateType,
        },
        isFilterEmptyString: false,
        isEmptyArray: false,
        isFilterNull: false,
      }),
      exceptionTransformField: ['address', 'name'],
    });

    return await axios
      .put(`${environments.REACT_APP_PROXY}/api/user-filters/${filter.id}`, {
        ...filteredObjectFilter,
        isNotificationEnabled: isNotificationEnabled,
      })
      .then((res) => {
        runInAction(() => {
          const updatedFilter = this.userRealEstateFilters.find((updatedFilter) => updatedFilter.id === filter.id);

          if (isNotificationEnabled && filter.name === name) {
            this.activeUserFiltersCount++;

            return;
          }

          if (updatedFilter?.isNotificationEnabled && filter.name === name) {
            this.activeUserFiltersCount--;
          }
        });

        return res.status;
      })
      .catch((err) => err.response.status);
  }

  isSubscriptionPlanExpiring(user: User) {
    const calculateSubscriptionPlanDaysLeft = (expirationDate: string | null) => {
      if (!expirationDate) {
        return null;
      }

      const daysLeft = DateTime.fromISO(expirationDate).diff(DateTime.now(), ['days']).toObject().days;

      if (!daysLeft) {
        return null;
      }

      return Math.ceil(daysLeft);
    };
    const fiveDays = 5;
    const oneDay = 1;
    const subscriptionPlanDaysLeft = calculateSubscriptionPlanDaysLeft(user.subscriptionPlanExpiresAt);
    const isSubscriptionPlanExpiring = Boolean(subscriptionPlanDaysLeft && subscriptionPlanDaysLeft <= fiveDays);
    const isLastDay = Boolean(subscriptionPlanDaysLeft && subscriptionPlanDaysLeft <= oneDay);

    return {
      isSubscriptionPlanExpiring,
      isLastDay,
      subscriptionPlanDaysLeft,
    };
  }

  @action
  async fetchSubscriptionPlan() {
    try {
      const subscriptionPlans = await axios.get<SubscriptionPlan[]>(
        `${environments.REACT_APP_PROXY}/api/subscription-plan`,
      );

      runInAction(() => {
        const yearRate = subscriptionPlans.data.find(
          (rate) => !rate.isDefault && rate.durationMonths === TimeConvert.MONTHS_IN_ONE_YEAR,
        );

        if (yearRate && yearRate.priceWithoutDiscount) {
          const oneHundredPercent = 100;
          const discountForYearRate =
            oneHundredPercent - Math.ceil((yearRate.price * oneHundredPercent) / yearRate.priceWithoutDiscount);
          const generateDiscountString = (percentage: number) => `Выгода ${percentage}%`;

          yearRate.features.unshift({ description: generateDiscountString(discountForYearRate) });
        }

        this.subscriptionPlans = subscriptionPlans.data;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action findSubscriptionPlanById(id: string) {
    return this.subscriptionPlans.find((plan) => plan.id === id);
  }

  @action
  deleteFilter = async (filterId: string) => {
    try {
      await axios.delete(`${environments.REACT_APP_PROXY}/api/user-filters/${filterId}`);

      runInAction(() => {
        const deletedFilter = this.userRealEstateFilters.find((filter) => filter.id === filterId);

        if (deletedFilter && deletedFilter.isNotificationEnabled) {
          this.activeUserFiltersCount--;
        }

        this.userRealEstateFilters = this.userRealEstateFilters.filter((filter) => filter.id !== filterId);
      });
    } catch (error) {
      handleAxiosError(error);
    }
  };

  @action
  fetchRealEstateFilterData = async (realEstateType: RealEstateTypeUpperCase) => {
    try {
      this.isUserFilterLoading = true;

      const response = await axios.get(
        `${environments.REACT_APP_PROXY}/api/user-filters?realEstateType=${realEstateType}`,
      );

      runInAction(() => {
        this.userRealEstateFilters = response.data;
        this.isUserFilterLoading = false;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  };

  @action
  fetchActiveFiltersCount = async () => {
    try {
      const response = await axios.get<{ count: number }>(
        `${environments.REACT_APP_PROXY}/api/user-filters/active-count`,
      );

      runInAction(() => {
        this.activeUserFiltersCount = response.data.count;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  };

  @computed
  get isExistNotificationEnabledInUserFilter() {
    return Boolean(this.userRealEstateFilters.filter((filter) => filter.isNotificationEnabled).length);
  }

  @computed
  get isExistUserFilter() {
    return Boolean(this.userRealEstateFilters.length);
  }

  @action
  fetchAllUserFilters = async () => {
    try {
      const responseFlat = await axios.get(
        `${environments.REACT_APP_PROXY}/api/user-filters?realEstateType=${RealEstateTypeUpperCase.FLAT}`,
      );
      const responseHouse = await axios.get(
        `${environments.REACT_APP_PROXY}/api/user-filters?realEstateType=${RealEstateTypeUpperCase.HOUSE}`,
      );
      const responseLand = await axios.get(
        `${environments.REACT_APP_PROXY}/api/user-filters?realEstateType=${RealEstateTypeUpperCase.LAND}`,
      );

      runInAction(() => {
        this.userAllFilters = [
          ...responseFlat.data.filter((filter: RealEstateFilters<FlatFilterType>) => filter.isNotificationEnabled),
          ...responseHouse.data.filter((filter: RealEstateFilters<HouseFilterType>) => filter.isNotificationEnabled),
          ...responseLand.data.filter((filter: RealEstateFilters<LandFilterType>) => filter.isNotificationEnabled),
        ];
      });
    } catch (error) {
      handleAxiosError(error);
    }
  };

  @action
  createOnboardingProgress = async (userId: string, onboardingTourId: string) => {
    const stepData = await apiUserControllerCreateUserOnboardingProgress(userId, {
      onboardingId: onboardingTourId,
    });

    runInAction(() => {
      this.userOnboardingProgressSteps = [
        ...this.userOnboardingProgressSteps,
        {
          id: stepData.onboardingId,
          completedAt: stepData.completedAt,
        },
      ];
    });
  };

  @action
  fetchOnboardingProgress = async (userId: string) => {
    this.loadingUserOnboardingProgress = true;

    const data = await apiUserControllerGetUserOnboardingProgress(userId);
    const userOnboardingSteps = data.map((step: OnboardingStepWithCompletionResponse) => {
      return {
        id: step.id,
        completedAt: step.completedAt,
      } as IUserOnboardingProgressSteps;
    });

    runInAction(() => {
      this.userOnboardingProgressSteps = userOnboardingSteps;
      this.loadingUserOnboardingProgress = false;
    });
  };

  @action
  checkLocalHasCompleteOnboarding = (onboardingId: string) => {
    return Boolean(this.userOnboardingProgressSteps.find((tour) => tour.id === onboardingId));
  };
}
