export enum TimeConvert {
  MILLISECONDS_IN_MILLISECONDS = 1,
  SECONDS_IN_MINUTE = 60,
  MINUTES_IN_HOUR = 60,
  MILLISECONDS_IN_SECOND = 1000,
  MILLISECONDS_IN_MINUTE = 60000,
  MILLISECONDS_IN_HOUR = 3600000,
  MILLISECONDS_IN_THREE_HOUR = 10800000,
  HOURS_IN_DAY = 24,
  MONTHS_IN_ONE_YEAR = 12,
  MONTHS_IN_ONE_MONTH = 1,
}
