import axios from 'axios';
import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { match } from 'ts-pattern';
import { environments } from '#/environment';
import { DomainSource } from '#entities/domain/model/type';
import { FilterParamsTask, Task } from '#entities/task/model/types';
import { Domain, RealEstateTypeUpperCase } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { AutoCompleteOptionDto } from '#shared/types/auto-complete-option';
import { MetroOptionDto } from '../ui/city-metro-station-multi-autocomplete';
import { MicrodistrictOptionDto } from '../ui/microdistrict-multi-autocomplete';
import { City, cityGroupsProps, MetroStationCity, MicrodistrictCity } from './types';

export class CityStore {
  city: City[] = [];
  metroStations: MetroStationCity[] = [];
  microdistricts: MicrodistrictCity[] = [];
  tasks: Task[] = [];
  loadingCity = true;
  loadingTask = true;
  loadingMetroStations = true;
  loadingMicrodistricts = true;
  loadingAvailableTask = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  async fetchData() {
    try {
      const dataCities: City[] = await axios.get(`${environments.REACT_APP_PROXY}/api/cities`).then((res) => res.data);
      const sortedCities = dataCities.sort((city, prevCity) => city.name.localeCompare(prevCity.name));

      runInAction(() => {
        this.city = sortedCities;
        this.loadingCity = false;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchAvailableTasks(id: string) {
    try {
      this.loadingAvailableTask = true;

      const dataCities = await axios
        .get(`${environments.REACT_APP_PROXY}/api/cities/${id}/available-tasks`)
        .then((res) => res.data);

      this.loadingAvailableTask = false;

      return dataCities;
    } catch (error) {
      this.loadingAvailableTask = false;
      handleAxiosError(error);
    }
  }

  @action
  async fetchMetroStationByCityId(cityId: string) {
    try {
      const metroStationsDataByCity = await axios
        .get(`${environments.REACT_APP_PROXY}/api/cities/${cityId}/metro-stations`)
        .then((res) => res.data);

      runInAction(() => {
        this.metroStations = metroStationsDataByCity;
        this.loadingMetroStations = false;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchMicrodistrictByCityId(cityId: string) {
    try {
      const microdistrictsDataByCity = await axios
        .get(`${environments.REACT_APP_PROXY}/api/cities/${cityId}/microdistricts`)
        .then((res) => res.data);

      runInAction(() => {
        this.microdistricts = microdistrictsDataByCity;
        this.loadingMicrodistricts = false;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  setMetroStations = (metroStations: MetroStationCity[]) => {
    this.metroStations = metroStations;
  };

  @action
  updateTask = (newTask: Task) => {
    this.tasks = this.tasks.map((task) => (task.id === newTask.id ? newTask : task));
  };

  @action
  addTask = (task: Task) => {
    this.tasks.push(task);
  };

  @action
  findCities = () => {
    return this.city;
  };

  @action
  findCityById = (id: string) => {
    return this.city.find((city) => city.id === id);
  };

  @action
  findAutoCompleteOptionCity = (availableCity?: City[]): AutoCompleteOptionDto[] => {
    const city = availableCity ?? this.city;

    return city.map((city: City) => {
      return {
        label: city.name,
        id: city.id,
      };
    });
  };

  @action
  findAutoCompleteOptionMetroCity = (): MetroOptionDto[] => {
    return this.metroStations.map((metroStation: MetroStationCity) => {
      return {
        label: metroStation.name,
        id: metroStation.id,
        metroLine: metroStation.metroLine,
      };
    });
  };

  @action
  findAutoCompleteOptionMicrodistrictCity = (): MicrodistrictOptionDto[] => {
    return this.microdistricts.map((microdistrict) => {
      return {
        label: microdistrict.name,
        id: microdistrict.id,
      };
    });
  };

  @action
  async fetchTasksByCities() {
    this.loadingTask = true;

    try {
      const dataTasks: Task[] = await axios
        .get(`${environments.REACT_APP_PROXY}/api/cities/tasks`)
        .then((res) => res.data);

      runInAction(() => {
        this.tasks = dataTasks;
        this.loadingTask = false;
      });
    } catch (error) {
      handleAxiosError(error);
    }

    this.loadingTask = false;
  }

  @action findCitiesByIds(ids: string[]) {
    return this.city.filter((city) => ids.includes(city.id));
  }

  @computed
  findTasksByCities() {
    return this.tasks;
  }

  @computed
  findMicrodistrictsByCities() {
    return this.microdistricts;
  }

  @computed
  findTasksByCity(city: string, isActive = false) {
    return match(isActive)
      .when(
        (isActive) => isActive,
        () => this.tasks.filter((task) => task.cityId === city && task.isActive),
      )
      .otherwise(() => this.tasks.filter((task) => task.cityId === city));
  }

  @action
  filterTaskByScript(tasks: Task[], script: Domain) {
    return this.tasks.filter((task) => task.script === script);
  }

  @action
  findTaskByParams(tasks: Task[], options: FilterParamsTask) {
    const { script, params } = options;

    return tasks.find((task) => {
      if (script && script !== task.script) {
        return false;
      }

      if (params) {
        for (const key in params) {
          const keyParams = key as keyof Task['params'];
          const paramByKey = params[keyParams as keyof typeof params];

          if (
            paramByKey &&
            task.params[keyParams as keyof typeof task.params] !== params[keyParams as keyof typeof params]
          ) {
            return false;
          }
        }
      }

      return true;
    });
  }

  @computed
  findConnectedDomainsByParams({
    city,
    domains,
    realEstateType,
  }: {
    city: string[];
    domains: DomainSource[];
    realEstateType: RealEstateTypeUpperCase;
  }) {
    return domains.filter((domain: DomainSource) =>
      this.tasks.some(
        (task: Task) =>
          task.script === domain.value &&
          task.params.realEstateType === realEstateType &&
          city.includes(task.cityId) &&
          task.isActive,
      ),
    );
  }

  @computed
  filterAutoCompleteOptionByCity(citiesOptions: AutoCompleteOptionDto[], selectedCityValue: string[]) {
    return citiesOptions.filter((city: AutoCompleteOptionDto) => selectedCityValue.includes(city.id));
  }

  @computed
  findAutoCompleteOptionByCity(citiesOptions: AutoCompleteOptionDto[], selectedCityValue: string) {
    return citiesOptions.find((city: AutoCompleteOptionDto) => city.id === selectedCityValue);
  }

  @computed
  filterAutoCompleteOptionByMetroLine(metroOptions: MetroOptionDto[], selectedMetroValue: string[]) {
    return metroOptions.filter((metro: MetroOptionDto) => selectedMetroValue.includes(metro.id));
  }

  @computed
  filterAutoCompleteOptionByMicrodistrict(
    microdistrtictOptions: MicrodistrictOptionDto[],
    selectedDistrtictValue: string[],
  ) {
    return microdistrtictOptions.filter((microdistrict) => selectedDistrtictValue.includes(microdistrict.id));
  }

  @action
  filterCitiesByCityName(cityName: string) {
    if (!cityName) {
      return this.city;
    }

    return this.city.filter((city) => city.name.toLowerCase().startsWith(cityName.toLowerCase()));
  }

  @action
  generateGroupedCitiesByFirstLetter(cities: City[]) {
    const cityGrouping = cities.reduce((cityGrouping: { [firstLetter: string]: cityGroupsProps }, city: City) => {
      const firstLetter = city.name[0];

      if (!cityGrouping[firstLetter]) {
        cityGrouping[firstLetter] = { firstLetter, cities: [city] };

        return cityGrouping;
      }

      cityGrouping[firstLetter].cities.push(city);

      return cityGrouping;
    }, {});

    return Object.values(cityGrouping);
  }
}
