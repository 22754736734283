/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * OpenAPI spec version: 1.0.0
 */
import { customInstance } from '../instance/custom-instance';
import type {
  AdminCityResponse,
  ApiFlatControllerFindManyCommentsByFlatIdParams,
  ApiFlatControllerGetDailyStatByRoomCountAndAuthorParams,
  ApiFlatControllerGetStatByAuthorAndDomainParams,
  ApiFlatControllerGetStatByPublishingTimeParams,
  ApiFlatControllerGetStatByRoomCountAndDealTypeDailyParams,
  ApiFlatControllerGetStatByRoomCountAndDealTypeParams,
  ApiHouseControllerFindManyCommentsByFlatIdParams,
  ApiHouseControllerGetDailyStatByAuthorParams,
  ApiHouseControllerGetDailyStatByRoomCountAndDealTypeParams,
  ApiHouseControllerGetStatByAuthorAndDomainParams,
  ApiHouseControllerGetStatByPublishingTimeParams,
  ApiHouseControllerGetStatByRoomCountAndDealTypeParams,
  ApiLandControllerFindManyCommentsByFlatIdParams,
  ApiLandControllerGetDailyStatByAuthorParams,
  ApiLandControllerGetDailyStatByRoomCountAndDealTypeParams,
  ApiLandControllerGetStatByAuthorAndDomainParams,
  ApiLandControllerGetStatByPublishingTimeParams,
  ApiLandControllerGetStatByRoomCountAndDealTypeParams,
  ApiUserControllerGetAllCrmFlatsByUserIdParams,
  ApiUserControllerGetAllCrmHousesByUserIdParams,
  ApiUserControllerGetAllCrmLandsByUserIdParams,
  ApiUserControllerGetAllFavouriteFlatsParams,
  ApiUserControllerGetAllFavouriteHousesParams,
  ApiUserControllerGetAllFavouriteLandsParams,
  ApiUserControllerGetFlatCallsByUserIdParams,
  ApiUserControllerGetHouseCallsByUserIdParams,
  ApiUserControllerGetLandCallsByUserIdParams,
  ApiUserControllerGetPaymentsByUserIdParams,
  ApiUserFilterControllerGetUserFiltersParams,
  ApiWorkAddressControllerCountManagerUsersByWorkAddressParams,
  ApiWorkAddressControllerGetManagerUsersByWorkAddressParams,
  AuthCodeDto,
  AvailableTaskParamsResponse,
  BaseUserFilterResponse,
  BaseUserPaymentResponse,
  BaseUserResponse,
  Buffer,
  CityControllerGetTasksByCitiesParams,
  CreateTaskDto,
  CreateUserOnboardingProgressDto,
  CrmFlatResponseWithRelatedFields,
  CrmHouseResponseWithRelatedFields,
  CrmLandResponseWithRelatedFields,
  CrmStatusResponse,
  DomainResponse,
  FlatCallResponse,
  FlatResponseWithRelatedFields,
  FlatStatByAuthorAndDomainResponse,
  FlatStatByRoomCountAndAuthorResponse,
  FlatStatByRoomCountAndDealTypeResponse,
  GetFlatClustersDto,
  GetFlatsCountDto,
  GetFlatsDto,
  GetHouseClustersDto,
  GetHousesCountDto,
  GetHousesDto,
  GetLandClustersDto,
  GetLandsCountDto,
  GetLandsDto,
  HouseCallResponse,
  HouseResponseWithRelatedFields,
  HouseStatByAuthorAndDomainResponse,
  HouseStatByAuthorResponse,
  HouseStatByDealTypeResponse,
  LandCallResponse,
  LandResponseWithRelatedFields,
  LandStatByAuthorAndDomainResponse,
  LandStatByAuthorResponse,
  LandStatByDealTypeResponse,
  MetroStationWithMetroLineResponse,
  MicrodistrictResponse,
  NearestMetroStationResponse,
  OnboardingStepResponse,
  OnboardingStepWithCompletionResponse,
  PaymentResponseWithSubscriptionPlan,
  PhoneNumberResponse,
  RealEstateCluster,
  RealEstateCommentResponseWithUser,
  RealEstateCountResponse,
  RealEstateStatByPublishingTime,
  StatusEntity,
  SubscriptionPlanIdDto,
  SubscriptionPlanPaymentResponse,
  SubscriptionPlanWithFeaturesResponse,
  SuggestAddressDto,
  TaskIdResponse,
  TaskResponse,
  TelegramIdResponse,
  UpdateTaskDto,
  UserAuthDto,
  UserAuthResponse,
  UserCountResponse,
  UserFilterDto,
  UserFilterUpdateDto,
  UserIdDto,
  UserOnboardingProgressCreateResponse,
  UserStatisticsResponse,
  UserWithStatisticResponse,
} from './api.schemas';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Пользователь с ролью администратора дополнительно получает информацию по
 * количеству тасок и информацию о наличии прокси
 * @summary Получить список городов
 */
export const cityControllerGetUserCities = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AdminCityResponse[]>({ url: `/api/cities`, method: 'GET' }, options);
};

/**
 * @summary Получить список станций метро по городу
 */
export const cityControllerGetMetroStationsByCity = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MetroStationWithMetroLineResponse[]>(
    { url: `/api/cities/${id}/metro-stations`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить список созданных тасок по списку городов
 */
export const cityControllerGetTasksByCities = (
  params?: CityControllerGetTasksByCitiesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskResponse[]>({ url: `/api/cities/tasks`, method: 'GET', params }, options);
};

/**
 * @summary Получить список доступных к созданию тасок по городу
 */
export const cityControllerGetAvailableTasksByCity = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<AvailableTaskParamsResponse[]>(
    { url: `/api/cities/${id}/available-tasks`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить список микрорайонов по городу
 */
export const cityControllerGetMicrodistrictsByCity = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<MicrodistrictResponse[]>({ url: `/api/cities/${id}/microdistricts`, method: 'GET' }, options);
};

/**
 * @summary Получить список доменов
 */
export const domainControllerGetAdminDomains = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<DomainResponse[]>({ url: `/api/domains`, method: 'GET' }, options);
};

/**
 * @summary Получить список доменов
 */
export const crmStatusControllerGetAdminDomains = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<CrmStatusResponse[]>({ url: `/api/crm-statuses`, method: 'GET' }, options);
};

/**
 * @summary Поиск квартир по фильтру
 */
export const apiFlatControllerFindManyFlatsByFilter = (
  getFlatsDto: GetFlatsDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatResponseWithRelatedFields[]>(
    {
      url: `/api/flats`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getFlatsDto,
    },
    options,
  );
};

/**
 * @summary Получить все комментарии по квартире
 */
export const apiFlatControllerFindManyCommentsByFlatId = (
  id: string,
  params: ApiFlatControllerFindManyCommentsByFlatIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCommentResponseWithUser[]>(
    { url: `/api/flats/${id}/comments`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик квартир по фильтру
 */
export const apiFlatControllerCountByFilter = (
  getFlatsCountDto: GetFlatsCountDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    {
      url: `/api/flats/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getFlatsCountDto,
    },
    options,
  );
};

/**
 * @summary Кластеры квартир для карты
 */
export const apiFlatControllerGetClustersByFilter = (
  getFlatClustersDto: GetFlatClustersDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCluster[]>(
    {
      url: `/api/flats/clusters`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getFlatClustersDto,
    },
    options,
  );
};

/**
 * @summary Статистика - среднее количество объявлений от частных лиц за месяц
 */
export const apiFlatControllerGetStatByPublishingTime = (
  params: ApiFlatControllerGetStatByPublishingTimeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateStatByPublishingTime[]>(
    { url: `/api/flats/statByPublishingTime`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по агрегаторам
 */
export const apiFlatControllerGetStatByAuthorAndDomain = (
  params: ApiFlatControllerGetStatByAuthorAndDomainParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatStatByAuthorAndDomainResponse[]>(
    { url: `/api/flats/statByAuthorAndDomain`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по количеству комнат и типу сделки
 */
export const apiFlatControllerGetStatByRoomCountAndDealType = (
  params: ApiFlatControllerGetStatByRoomCountAndDealTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatStatByRoomCountAndDealTypeResponse[]>(
    { url: `/api/flats/statByRoomCountAndDealType`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Объявления за текущий день - Статистика по количеству комнат и
 *   автору за сегодня по городу
 */
export const apiFlatControllerGetDailyStatByRoomCountAndAuthor = (
  params: ApiFlatControllerGetDailyStatByRoomCountAndAuthorParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatStatByRoomCountAndAuthorResponse[]>(
    { url: `/api/flats/statByRoomCountAndAuthor`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Объявления за текущий день - Статистика по количеству комнат и типу
 *   сделки за день по городу
 */
export const apiFlatControllerGetStatByRoomCountAndDealTypeDaily = (
  params: ApiFlatControllerGetStatByRoomCountAndDealTypeDailyParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatStatByRoomCountAndDealTypeResponse[]>(
    {
      url: `/api/flats/statByRoomCountAndDealTypeDaily`,
      method: 'GET',
      params,
    },
    options,
  );
};

/**
 * @summary Добавить квартиру в избранное
 */
export const apiFlatControllerAddToFavourites = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/flats/${id}/favourites`, method: 'POST' }, options);
};

/**
 * @summary Убрать квартиру из избранного
 */
export const apiFlatControllerRemoveFromFavourites = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/flats/${id}/favourites`, method: 'DELETE' }, options);
};

/**
 * @summary Получить номер телефона с агрегатора
 */
export const apiFlatControllerGetPhone = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<PhoneNumberResponse>({ url: `/api/flats/${id}/phone`, method: 'GET' }, options);
};

/**
 * @summary Отметить дубль квартиры как неверно сопоставленный
 */
export const apiFlatControllerRemoveDuplicate = (
  id: string,
  crmFlatId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/flats/${id}/duplicates/${crmFlatId}`, method: 'DELETE' }, options);
};

/**
 * @summary Получить  информацию об объекте по id
 */
export const apiFlatControllerFindById = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<FlatResponseWithRelatedFields>({ url: `/api/flats/${id}`, method: 'GET' }, options);
};

/**
 * @summary Поиск домов по фильтру
 */
export const apiHouseControllerFindManyHousesByFilter = (
  getHousesDto: GetHousesDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseResponseWithRelatedFields[]>(
    {
      url: `/api/houses`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getHousesDto,
    },
    options,
  );
};

/**
 * @summary Получить все комментарии по дому
 */
export const apiHouseControllerFindManyCommentsByFlatId = (
  id: string,
  params: ApiHouseControllerFindManyCommentsByFlatIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCommentResponseWithUser[]>(
    { url: `/api/houses/${id}/comments`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик домов по фильтру
 */
export const apiHouseControllerCountByFilter = (
  getHousesCountDto: GetHousesCountDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    {
      url: `/api/houses/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getHousesCountDto,
    },
    options,
  );
};

/**
 * @summary Кластеры домов для карты
 */
export const apiHouseControllerGetClustersByFilter = (
  getHouseClustersDto: GetHouseClustersDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCluster[]>(
    {
      url: `/api/houses/clusters`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getHouseClustersDto,
    },
    options,
  );
};

/**
 * @summary Статистика - среднее количество объявлений от частных лиц за месяц
 */
export const apiHouseControllerGetStatByPublishingTime = (
  params: ApiHouseControllerGetStatByPublishingTimeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateStatByPublishingTime[]>(
    { url: `/api/houses/statByPublishingTime`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по агрегаторам
 */
export const apiHouseControllerGetStatByAuthorAndDomain = (
  params: ApiHouseControllerGetStatByAuthorAndDomainParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseStatByAuthorAndDomainResponse[]>(
    { url: `/api/houses/statByAuthorAndDomain`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по типу сделки
 */
export const apiHouseControllerGetStatByRoomCountAndDealType = (
  params: ApiHouseControllerGetStatByRoomCountAndDealTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseStatByDealTypeResponse[]>(
    { url: `/api/houses/statByDealType`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Объявления за текущий день - Статистика по автору за сегодня по
 *   городу
 */
export const apiHouseControllerGetDailyStatByAuthor = (
  params: ApiHouseControllerGetDailyStatByAuthorParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseStatByAuthorResponse[]>(
    { url: `/api/houses/statByAuthor`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Объявления за текущий день - Статистика по типу сделки за сегодня
 *   по городу
 */
export const apiHouseControllerGetDailyStatByRoomCountAndDealType = (
  params: ApiHouseControllerGetDailyStatByRoomCountAndDealTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseStatByDealTypeResponse[]>(
    { url: `/api/houses/statByDealTypeDaily`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Добавить дом в избранное
 */
export const apiHouseControllerAddToFavourites = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/houses/${id}/favourites`, method: 'POST' }, options);
};

/**
 * @summary Убрать дом из избранного
 */
export const apiHouseControllerRemoveFromFavourites = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/houses/${id}/favourites`, method: 'DELETE' }, options);
};

/**
 * @summary Получить номер телефона с агрегатора
 */
export const apiHouseControllerGetPhone = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<PhoneNumberResponse>({ url: `/api/houses/${id}/phone`, method: 'GET' }, options);
};

/**
 * @summary Отметить дубль дома как неверно сопоставленный
 */
export const apiHouseControllerRemoveDuplicate = (
  id: string,
  crmHouseId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/houses/${id}/duplicates/${crmHouseId}`, method: 'DELETE' }, options);
};

/**
 * @summary Получить  информацию об объекте по id
 */
export const apiHouseControllerFindById = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<HouseResponseWithRelatedFields>({ url: `/api/houses/${id}`, method: 'GET' }, options);
};

/**
 * @summary Поиск земельных участков по фильтру
 */
export const apiLandControllerFindManyLandsByFilter = (
  getLandsDto: GetLandsDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandResponseWithRelatedFields[]>(
    {
      url: `/api/lands`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getLandsDto,
    },
    options,
  );
};

/**
 * @summary Получить все комментарии по земельному участку
 */
export const apiLandControllerFindManyCommentsByFlatId = (
  id: string,
  params: ApiLandControllerFindManyCommentsByFlatIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCommentResponseWithUser[]>(
    { url: `/api/lands/${id}/comments`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик земельных участков по фильтру
 */
export const apiLandControllerCountByFilter = (
  getLandsCountDto: GetLandsCountDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    {
      url: `/api/lands/count`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getLandsCountDto,
    },
    options,
  );
};

/**
 * @summary Кластеры земельных участков для карты
 */
export const apiLandControllerGetClustersByFilter = (
  getLandClustersDto: GetLandClustersDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCluster[]>(
    {
      url: `/api/lands/clusters`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: getLandClustersDto,
    },
    options,
  );
};

/**
 * @summary Статистика - среднее количество объявлений от частных лиц за месяц
 */
export const apiLandControllerGetStatByPublishingTime = (
  params: ApiLandControllerGetStatByPublishingTimeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateStatByPublishingTime[]>(
    { url: `/api/lands/statByPublishingTime`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по агрегаторам
 */
export const apiLandControllerGetStatByAuthorAndDomain = (
  params: ApiLandControllerGetStatByAuthorAndDomainParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandStatByAuthorAndDomainResponse[]>(
    { url: `/api/lands/statByAuthorAndDomain`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Статистика по типу сделки
 */
export const apiLandControllerGetStatByRoomCountAndDealType = (
  params: ApiLandControllerGetStatByRoomCountAndDealTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandStatByDealTypeResponse[]>(
    { url: `/api/lands/statByDealType`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Объявления за текущий день - Статистика по автору за сегодня по
 *   городу
 */
export const apiLandControllerGetDailyStatByAuthor = (
  params: ApiLandControllerGetDailyStatByAuthorParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandStatByAuthorResponse[]>({ url: `/api/lands/statByAuthor`, method: 'GET', params }, options);
};

/**
 * @summary Объявления за текущий день - Статистика по типу сделки за сегодня
 *   по городу
 */
export const apiLandControllerGetDailyStatByRoomCountAndDealType = (
  params: ApiLandControllerGetDailyStatByRoomCountAndDealTypeParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandStatByDealTypeResponse[]>(
    { url: `/api/lands/statByDealTypeDaily`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Добавить земельный участок в избранное
 */
export const apiLandControllerAddToFavourites = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/lands/${id}/favourites`, method: 'POST' }, options);
};

/**
 * @summary Убрать земельный участок из избранного
 */
export const apiLandControllerRemoveFromFavourites = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/lands/${id}/favourites`, method: 'DELETE' }, options);
};

/**
 * @summary Получить номер телефона с агрегатора
 */
export const apiLandControllerGetPhone = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<PhoneNumberResponse>({ url: `/api/lands/${id}/phone`, method: 'GET' }, options);
};

/**
 * @summary Отметить дубль земельного участка как неверно сопоставленный
 */
export const apiLandControllerRemoveDuplicate = (
  id: string,
  crmLandId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/lands/${id}/duplicates/${crmLandId}`, method: 'DELETE' }, options);
};

/**
 * @summary Получить  информацию об объекте по id
 */
export const apiLandControllerFindById = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<LandResponseWithRelatedFields>({ url: `/api/lands/${id}`, method: 'GET' }, options);
};

/**
 * @summary Получить список статусов
 */
export const statusControllerFindAll = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<StatusEntity[]>({ url: `/api/statuses`, method: 'GET' }, options);
};

/**
 * @summary Создать задачу на парсинг и актуализацию
 */
export const taskControllerCreate = (
  createTaskDto: CreateTaskDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TaskIdResponse>(
    {
      url: `/api/tasks`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createTaskDto,
    },
    options,
  );
};

/**
 * @summary Включить или выключить задачу на парсинг и актуализацию
 */
export const taskControllerUpdate = (
  id: string,
  updateTaskDto: UpdateTaskDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/tasks/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateTaskDto,
    },
    options,
  );
};

/**
 * @summary Авторизация
 */
export const apiUserControllerLogin = (userAuthDto: UserAuthDto, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserAuthResponse>(
    {
      url: `/api/users/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userAuthDto,
    },
    options,
  );
};

/**
 * @summary Логаут
 */
export const apiUserControllerLogout = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/users/logout`, method: 'POST' }, options);
};

/**
 * @summary Получить квартиры, сохраненные из парсера, для личного кабинета
 */
export const apiUserControllerGetAllCrmFlatsByUserId = (
  id: string,
  params: ApiUserControllerGetAllCrmFlatsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CrmFlatResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/crm-flats`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик квартир, сохраненных из парсера, в личном кабинете
 */
export const apiUserControllerGetCountCrmFlatsByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/crm-flats/count`, method: 'GET' }, options);
};

/**
 * @summary Получить дома, сохраненные из парсера, для личного кабинета
 */
export const apiUserControllerGetAllCrmHousesByUserId = (
  id: string,
  params: ApiUserControllerGetAllCrmHousesByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CrmHouseResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/crm-houses`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик домов, сохраненных из парсера, в личном кабинете
 */
export const apiUserControllerGetCountCrmHousesByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/crm-houses/count`, method: 'GET' }, options);
};

/**
 * @summary Получить земельные участки, сохраненные из парсера, для личного
 *   кабинета
 */
export const apiUserControllerGetAllCrmLandsByUserId = (
  id: string,
  params: ApiUserControllerGetAllCrmLandsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<CrmLandResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/crm-lands`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик земельных участков, сохраненных из парсера, в личном
 *   кабинете
 */
export const apiUserControllerGetCountCrmLandsByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/crm-lands/count`, method: 'GET' }, options);
};

/**
 * @summary Заблокировать пользователя
 */
export const apiUserControllerBlockUser = (userIdDto: UserIdDto, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>(
    {
      url: `/api/users/block`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userIdDto,
    },
    options,
  );
};

/**
 * @summary Разблокировать пользователя
 */
export const apiUserControllerUnblockUser = (
  userIdDto: UserIdDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/users/unblock`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userIdDto,
    },
    options,
  );
};

/**
 * @summary Подключить мессенджер Telegram по коду авторизации
 */
export const apiUserControllerGetMessengerKey = (
  id: string,
  authCodeDto: AuthCodeDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<TelegramIdResponse>(
    {
      url: `/api/users/${id}/messenger-code`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authCodeDto,
    },
    options,
  );
};

/**
 * @summary Отвязать мессенджер Telegram
 */
export const apiUserControllerDeactivateMessenger = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/users/${id}/messenger-code`, method: 'DELETE' }, options);
};

/**
 * @summary Получить квартиры из избранного
 */
export const apiUserControllerGetAllFavouriteFlats = (
  id: string,
  params: ApiUserControllerGetAllFavouriteFlatsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/favourite-flats`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик квартир в избранном
 */
export const apiUserControllerGetAllFavouriteFlatsCount = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/favourite-flats/count`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить дома из избранного
 */
export const apiUserControllerGetAllFavouriteHouses = (
  id: string,
  params: ApiUserControllerGetAllFavouriteHousesParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/favourite-houses`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик домов в избранном
 */
export const apiUserControllerGetAllFavouriteHousesCount = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/favourite-houses/count`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить земельные участки из избранного
 */
export const apiUserControllerGetAllFavouriteLands = (
  id: string,
  params: ApiUserControllerGetAllFavouriteLandsParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandResponseWithRelatedFields[]>(
    { url: `/api/users/${id}/favourite-lands`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик земельных участков в избранном
 */
export const apiUserControllerGetAllFavouriteLandsCount = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/favourite-lands/count`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить ссылку на оплату по идентификатору тарифного плана
 */
export const apiUserControllerConnectSubscriptionPlan = (
  id: string,
  subscriptionPlanIdDto: SubscriptionPlanIdDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<SubscriptionPlanPaymentResponse>(
    {
      url: `/api/users/${id}/subscription-plan`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscriptionPlanIdDto,
    },
    options,
  );
};

/**
 * @summary Получить статистическую информацию о пользователе
 */
export const apiUserControllerGetStatisticsById = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserStatisticsResponse>({ url: `/api/users/${id}/statistics`, method: 'GET' }, options);
};

/**
 * @summary Получить все платежи пользователя
 */
export const apiUserControllerGetPaymentsByUserId = (
  id: string,
  params: ApiUserControllerGetPaymentsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PaymentResponseWithSubscriptionPlan[]>(
    { url: `/api/users/${id}/payments`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Счетчик платежей пользователя
 */
export const apiUserControllerGetPaymentsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/payments/count`, method: 'GET' }, options);
};

/**
 * @summary Получить звонки по квартирам
 */
export const apiUserControllerGetFlatCallsByUserId = (
  id: string,
  params: ApiUserControllerGetFlatCallsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<FlatCallResponse[]>({ url: `/api/users/${id}/flat-calls`, method: 'GET', params }, options);
};

/**
 * @summary Счетчик звонков по квартирам
 */
export const apiUserControllerGetFlatCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/flat-calls/count`, method: 'GET' }, options);
};

/**
 * @summary Счетчик распознанных звонков по квартирам
 */
export const apiUserControllerGetRecognizedFlatCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/flat-calls/count/recognized`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить звонки по домам
 */
export const apiUserControllerGetHouseCallsByUserId = (
  id: string,
  params: ApiUserControllerGetHouseCallsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<HouseCallResponse[]>({ url: `/api/users/${id}/house-calls`, method: 'GET', params }, options);
};

/**
 * @summary Счетчик звонков по домам
 */
export const apiUserControllerGetHouseCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/house-calls/count`, method: 'GET' }, options);
};

/**
 * @summary Счетчик распознанных звонков по домам
 */
export const apiUserControllerGetRecognizedHouseCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/house-calls/count/recognized`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить звонки по земельным участкам
 */
export const apiUserControllerGetLandCallsByUserId = (
  id: string,
  params: ApiUserControllerGetLandCallsByUserIdParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<LandCallResponse[]>({ url: `/api/users/${id}/land-calls`, method: 'GET', params }, options);
};

/**
 * @summary Счетчик звонков по земельным участкам
 */
export const apiUserControllerGetLandCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/users/${id}/land-calls/count`, method: 'GET' }, options);
};

/**
 * @summary Счетчик распознанных звонков по земельным участкам
 */
export const apiUserControllerGetRecognizedLandCallsCountByUserId = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RealEstateCountResponse>(
    { url: `/api/users/${id}/land-calls/count/recognized`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить информацию о пользователе
 */
export const apiUserControllerGetUserData = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<UserAuthResponse>({ url: `/api/users/${id}`, method: 'GET' }, options);
};

/**
 * @summary Распознать звонок по квартире по запросу
 */
export const apiUserControllerRecognizeFlatCall = (
  id: string,
  callId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/users/${id}/flat-calls/${callId}/recognize`, method: 'POST' }, options);
};

/**
 * @summary Распознать звонок по дому по запросу
 */
export const apiUserControllerRecognizeHouseCall = (
  id: string,
  callId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/users/${id}/house-calls/${callId}/recognize`, method: 'POST' }, options);
};

/**
 * @summary Распознать звонок по загородному участку по запросу
 */
export const apiUserControllerRecognizeLandCall = (
  id: string,
  callId: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/users/${id}/land-calls/${callId}/recognize`, method: 'POST' }, options);
};

export const apiUserControllerGetUserOnboardingProgress = (
  id: string,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<OnboardingStepWithCompletionResponse[]>(
    { url: `/api/users/${id}/onboarding/progress`, method: 'GET' },
    options,
  );
};

export const apiUserControllerCreateUserOnboardingProgress = (
  id: string,
  createUserOnboardingProgressDto: CreateUserOnboardingProgressDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserOnboardingProgressCreateResponse>(
    {
      url: `/api/users/${id}/onboarding/progress`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUserOnboardingProgressDto,
    },
    options,
  );
};

/**
 * @summary Получить список фильтров пользователя
 */
export const apiUserFilterControllerGetUserFilters = (
  params: ApiUserFilterControllerGetUserFiltersParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<BaseUserFilterResponse[]>({ url: `/api/user-filters`, method: 'GET', params }, options);
};

/**
 * @summary Сохранить фильтр пользователя
 */
export const apiUserFilterControllerCreate = (
  userFilterDto: UserFilterDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/user-filters`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userFilterDto,
    },
    options,
  );
};

/**
 * @summary Счетчик фильтров пользователя c подпиской
 */
export const apiUserFilterControllerCountUserFilters = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<RealEstateCountResponse>({ url: `/api/user-filters/active-count`, method: 'GET' }, options);
};

/**
 * @summary Удалить сохраненный фильтр пользователя
 */
export const apiUserFilterControllerDelete = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<void>({ url: `/api/user-filters/${id}`, method: 'DELETE' }, options);
};

/**
 * @summary Обновить сохраненный фильтр пользователя
 */
export const apiUserFilterControllerUpdate = (
  id: string,
  userFilterUpdateDto: UserFilterUpdateDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/user-filters/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: userFilterUpdateDto,
    },
    options,
  );
};

/**
 * @summary Получить всех менеджеров по work-address
 */
export const apiWorkAddressControllerGetManagersByWorkAddress = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<BaseUserResponse[]>({ url: `/api/work-address/${id}/managers`, method: 'GET' }, options);
};

/**
 * @summary Получить пользователей из work-address со статистикой
 */
export const apiWorkAddressControllerGetManagerUsersByWorkAddress = (
  id: number,
  params?: ApiWorkAddressControllerGetManagerUsersByWorkAddressParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserWithStatisticResponse[]>(
    { url: `/api/work-address/${id}/users`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Количество пользователей из work-address
 */
export const apiWorkAddressControllerCountManagerUsersByWorkAddress = (
  id: number,
  params?: ApiWorkAddressControllerCountManagerUsersByWorkAddressParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UserCountResponse>(
    { url: `/api/work-address/${id}/users/count`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Актуализировать пользователей в work-address
 */
export const apiWorkAddressControllerActualizeUsers = (
  id: number,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>({ url: `/api/work-address/${id}`, method: 'PUT' }, options);
};

/**
 * @summary Получить 3 ближайшие станции метро к переданной
 */
export const metroStationControllerGetNearby = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<NearestMetroStationResponse[]>(
    { url: `/api/metro-stations/${id}/nearest`, method: 'GET' },
    options,
  );
};

/**
 * @summary Получить список всех тарифных планов
 */
export const subscriptionPlanControllerGetAll = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<SubscriptionPlanWithFeaturesResponse[]>(
    { url: `/api/subscription-plan`, method: 'GET' },
    options,
  );
};

/**
 * @summary Эндпоинт для коллбэков банка об успешных платежах
 */
export const userPaymentControllerUpdatePaymentStatus = (
  buffer: Buffer,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/user-payment/callback`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: buffer,
    },
    options,
  );
};

/**
 * @summary Получить информацию о платеже пользователя по id
 */
export const userPaymentControllerGetById = (id: string, options?: SecondParameter<typeof customInstance>) => {
  return customInstance<BaseUserPaymentResponse>({ url: `/api/user-payment/${id}`, method: 'GET' }, options);
};

export const locationSuggestControllerSuggestLocation = (
  suggestAddressDto: SuggestAddressDto,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    {
      url: `/api/location-suggest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: suggestAddressDto,
    },
    options,
  );
};

export const onboardingControllerGetAllOnboardingSteps = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<OnboardingStepResponse[]>({ url: `/api/onboarding`, method: 'GET' }, options);
};

export type CityControllerGetUserCitiesResult = NonNullable<Awaited<ReturnType<typeof cityControllerGetUserCities>>>;
export type CityControllerGetMetroStationsByCityResult = NonNullable<
  Awaited<ReturnType<typeof cityControllerGetMetroStationsByCity>>
>;
export type CityControllerGetTasksByCitiesResult = NonNullable<
  Awaited<ReturnType<typeof cityControllerGetTasksByCities>>
>;
export type CityControllerGetAvailableTasksByCityResult = NonNullable<
  Awaited<ReturnType<typeof cityControllerGetAvailableTasksByCity>>
>;
export type CityControllerGetMicrodistrictsByCityResult = NonNullable<
  Awaited<ReturnType<typeof cityControllerGetMicrodistrictsByCity>>
>;
export type DomainControllerGetAdminDomainsResult = NonNullable<
  Awaited<ReturnType<typeof domainControllerGetAdminDomains>>
>;
export type CrmStatusControllerGetAdminDomainsResult = NonNullable<
  Awaited<ReturnType<typeof crmStatusControllerGetAdminDomains>>
>;
export type ApiFlatControllerFindManyFlatsByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerFindManyFlatsByFilter>>
>;
export type ApiFlatControllerFindManyCommentsByFlatIdResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerFindManyCommentsByFlatId>>
>;
export type ApiFlatControllerCountByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerCountByFilter>>
>;
export type ApiFlatControllerGetClustersByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetClustersByFilter>>
>;
export type ApiFlatControllerGetStatByPublishingTimeResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetStatByPublishingTime>>
>;
export type ApiFlatControllerGetStatByAuthorAndDomainResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetStatByAuthorAndDomain>>
>;
export type ApiFlatControllerGetStatByRoomCountAndDealTypeResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetStatByRoomCountAndDealType>>
>;
export type ApiFlatControllerGetDailyStatByRoomCountAndAuthorResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetDailyStatByRoomCountAndAuthor>>
>;
export type ApiFlatControllerGetStatByRoomCountAndDealTypeDailyResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerGetStatByRoomCountAndDealTypeDaily>>
>;
export type ApiFlatControllerAddToFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerAddToFavourites>>
>;
export type ApiFlatControllerRemoveFromFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerRemoveFromFavourites>>
>;
export type ApiFlatControllerGetPhoneResult = NonNullable<Awaited<ReturnType<typeof apiFlatControllerGetPhone>>>;
export type ApiFlatControllerRemoveDuplicateResult = NonNullable<
  Awaited<ReturnType<typeof apiFlatControllerRemoveDuplicate>>
>;
export type ApiFlatControllerFindByIdResult = NonNullable<Awaited<ReturnType<typeof apiFlatControllerFindById>>>;
export type ApiHouseControllerFindManyHousesByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerFindManyHousesByFilter>>
>;
export type ApiHouseControllerFindManyCommentsByFlatIdResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerFindManyCommentsByFlatId>>
>;
export type ApiHouseControllerCountByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerCountByFilter>>
>;
export type ApiHouseControllerGetClustersByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetClustersByFilter>>
>;
export type ApiHouseControllerGetStatByPublishingTimeResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetStatByPublishingTime>>
>;
export type ApiHouseControllerGetStatByAuthorAndDomainResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetStatByAuthorAndDomain>>
>;
export type ApiHouseControllerGetStatByRoomCountAndDealTypeResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetStatByRoomCountAndDealType>>
>;
export type ApiHouseControllerGetDailyStatByAuthorResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetDailyStatByAuthor>>
>;
export type ApiHouseControllerGetDailyStatByRoomCountAndDealTypeResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerGetDailyStatByRoomCountAndDealType>>
>;
export type ApiHouseControllerAddToFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerAddToFavourites>>
>;
export type ApiHouseControllerRemoveFromFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerRemoveFromFavourites>>
>;
export type ApiHouseControllerGetPhoneResult = NonNullable<Awaited<ReturnType<typeof apiHouseControllerGetPhone>>>;
export type ApiHouseControllerRemoveDuplicateResult = NonNullable<
  Awaited<ReturnType<typeof apiHouseControllerRemoveDuplicate>>
>;
export type ApiHouseControllerFindByIdResult = NonNullable<Awaited<ReturnType<typeof apiHouseControllerFindById>>>;
export type ApiLandControllerFindManyLandsByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerFindManyLandsByFilter>>
>;
export type ApiLandControllerFindManyCommentsByFlatIdResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerFindManyCommentsByFlatId>>
>;
export type ApiLandControllerCountByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerCountByFilter>>
>;
export type ApiLandControllerGetClustersByFilterResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetClustersByFilter>>
>;
export type ApiLandControllerGetStatByPublishingTimeResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetStatByPublishingTime>>
>;
export type ApiLandControllerGetStatByAuthorAndDomainResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetStatByAuthorAndDomain>>
>;
export type ApiLandControllerGetStatByRoomCountAndDealTypeResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetStatByRoomCountAndDealType>>
>;
export type ApiLandControllerGetDailyStatByAuthorResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetDailyStatByAuthor>>
>;
export type ApiLandControllerGetDailyStatByRoomCountAndDealTypeResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerGetDailyStatByRoomCountAndDealType>>
>;
export type ApiLandControllerAddToFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerAddToFavourites>>
>;
export type ApiLandControllerRemoveFromFavouritesResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerRemoveFromFavourites>>
>;
export type ApiLandControllerGetPhoneResult = NonNullable<Awaited<ReturnType<typeof apiLandControllerGetPhone>>>;
export type ApiLandControllerRemoveDuplicateResult = NonNullable<
  Awaited<ReturnType<typeof apiLandControllerRemoveDuplicate>>
>;
export type ApiLandControllerFindByIdResult = NonNullable<Awaited<ReturnType<typeof apiLandControllerFindById>>>;
export type StatusControllerFindAllResult = NonNullable<Awaited<ReturnType<typeof statusControllerFindAll>>>;
export type TaskControllerCreateResult = NonNullable<Awaited<ReturnType<typeof taskControllerCreate>>>;
export type TaskControllerUpdateResult = NonNullable<Awaited<ReturnType<typeof taskControllerUpdate>>>;
export type ApiUserControllerLoginResult = NonNullable<Awaited<ReturnType<typeof apiUserControllerLogin>>>;
export type ApiUserControllerLogoutResult = NonNullable<Awaited<ReturnType<typeof apiUserControllerLogout>>>;
export type ApiUserControllerGetAllCrmFlatsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllCrmFlatsByUserId>>
>;
export type ApiUserControllerGetCountCrmFlatsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetCountCrmFlatsByUserId>>
>;
export type ApiUserControllerGetAllCrmHousesByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllCrmHousesByUserId>>
>;
export type ApiUserControllerGetCountCrmHousesByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetCountCrmHousesByUserId>>
>;
export type ApiUserControllerGetAllCrmLandsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllCrmLandsByUserId>>
>;
export type ApiUserControllerGetCountCrmLandsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetCountCrmLandsByUserId>>
>;
export type ApiUserControllerBlockUserResult = NonNullable<Awaited<ReturnType<typeof apiUserControllerBlockUser>>>;
export type ApiUserControllerUnblockUserResult = NonNullable<Awaited<ReturnType<typeof apiUserControllerUnblockUser>>>;
export type ApiUserControllerGetMessengerKeyResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetMessengerKey>>
>;
export type ApiUserControllerDeactivateMessengerResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerDeactivateMessenger>>
>;
export type ApiUserControllerGetAllFavouriteFlatsResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteFlats>>
>;
export type ApiUserControllerGetAllFavouriteFlatsCountResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteFlatsCount>>
>;
export type ApiUserControllerGetAllFavouriteHousesResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteHouses>>
>;
export type ApiUserControllerGetAllFavouriteHousesCountResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteHousesCount>>
>;
export type ApiUserControllerGetAllFavouriteLandsResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteLands>>
>;
export type ApiUserControllerGetAllFavouriteLandsCountResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetAllFavouriteLandsCount>>
>;
export type ApiUserControllerConnectSubscriptionPlanResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerConnectSubscriptionPlan>>
>;
export type ApiUserControllerGetStatisticsByIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetStatisticsById>>
>;
export type ApiUserControllerGetPaymentsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetPaymentsByUserId>>
>;
export type ApiUserControllerGetPaymentsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetPaymentsCountByUserId>>
>;
export type ApiUserControllerGetFlatCallsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetFlatCallsByUserId>>
>;
export type ApiUserControllerGetFlatCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetFlatCallsCountByUserId>>
>;
export type ApiUserControllerGetRecognizedFlatCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetRecognizedFlatCallsCountByUserId>>
>;
export type ApiUserControllerGetHouseCallsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetHouseCallsByUserId>>
>;
export type ApiUserControllerGetHouseCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetHouseCallsCountByUserId>>
>;
export type ApiUserControllerGetRecognizedHouseCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetRecognizedHouseCallsCountByUserId>>
>;
export type ApiUserControllerGetLandCallsByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetLandCallsByUserId>>
>;
export type ApiUserControllerGetLandCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetLandCallsCountByUserId>>
>;
export type ApiUserControllerGetRecognizedLandCallsCountByUserIdResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetRecognizedLandCallsCountByUserId>>
>;
export type ApiUserControllerGetUserDataResult = NonNullable<Awaited<ReturnType<typeof apiUserControllerGetUserData>>>;
export type ApiUserControllerRecognizeFlatCallResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerRecognizeFlatCall>>
>;
export type ApiUserControllerRecognizeHouseCallResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerRecognizeHouseCall>>
>;
export type ApiUserControllerRecognizeLandCallResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerRecognizeLandCall>>
>;
export type ApiUserControllerGetUserOnboardingProgressResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerGetUserOnboardingProgress>>
>;
export type ApiUserControllerCreateUserOnboardingProgressResult = NonNullable<
  Awaited<ReturnType<typeof apiUserControllerCreateUserOnboardingProgress>>
>;
export type ApiUserFilterControllerGetUserFiltersResult = NonNullable<
  Awaited<ReturnType<typeof apiUserFilterControllerGetUserFilters>>
>;
export type ApiUserFilterControllerCreateResult = NonNullable<
  Awaited<ReturnType<typeof apiUserFilterControllerCreate>>
>;
export type ApiUserFilterControllerCountUserFiltersResult = NonNullable<
  Awaited<ReturnType<typeof apiUserFilterControllerCountUserFilters>>
>;
export type ApiUserFilterControllerDeleteResult = NonNullable<
  Awaited<ReturnType<typeof apiUserFilterControllerDelete>>
>;
export type ApiUserFilterControllerUpdateResult = NonNullable<
  Awaited<ReturnType<typeof apiUserFilterControllerUpdate>>
>;
export type ApiWorkAddressControllerGetManagersByWorkAddressResult = NonNullable<
  Awaited<ReturnType<typeof apiWorkAddressControllerGetManagersByWorkAddress>>
>;
export type ApiWorkAddressControllerGetManagerUsersByWorkAddressResult = NonNullable<
  Awaited<ReturnType<typeof apiWorkAddressControllerGetManagerUsersByWorkAddress>>
>;
export type ApiWorkAddressControllerCountManagerUsersByWorkAddressResult = NonNullable<
  Awaited<ReturnType<typeof apiWorkAddressControllerCountManagerUsersByWorkAddress>>
>;
export type ApiWorkAddressControllerActualizeUsersResult = NonNullable<
  Awaited<ReturnType<typeof apiWorkAddressControllerActualizeUsers>>
>;
export type MetroStationControllerGetNearbyResult = NonNullable<
  Awaited<ReturnType<typeof metroStationControllerGetNearby>>
>;
export type SubscriptionPlanControllerGetAllResult = NonNullable<
  Awaited<ReturnType<typeof subscriptionPlanControllerGetAll>>
>;
export type UserPaymentControllerUpdatePaymentStatusResult = NonNullable<
  Awaited<ReturnType<typeof userPaymentControllerUpdatePaymentStatus>>
>;
export type UserPaymentControllerGetByIdResult = NonNullable<Awaited<ReturnType<typeof userPaymentControllerGetById>>>;
export type LocationSuggestControllerSuggestLocationResult = NonNullable<
  Awaited<ReturnType<typeof locationSuggestControllerSuggestLocation>>
>;
export type OnboardingControllerGetAllOnboardingStepsResult = NonNullable<
  Awaited<ReturnType<typeof onboardingControllerGetAllOnboardingSteps>>
>;
