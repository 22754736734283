import axios from 'axios';
import { action, makeAutoObservable } from 'mobx';
import { match } from 'ts-pattern';
import { environments } from '#/environment';
import { CityStore } from '#entities/city/model/city.store';
import { CatalogStore } from '#entities/domain/catalog.store';
import { DealType, RealEstateTypeUpperCase, ReductionDealType, ReductionRealEstateTypeUpperCase } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { CreateTaskDto, Task } from './types';

export class TaskStore {
  constructor(
    public readonly catalogStore: CatalogStore,
    public readonly cityStore: CityStore,
  ) {
    makeAutoObservable(this);
  }

  @action
  async createTask(params: CreateTaskDto) {
    return await axios.post(`${environments.REACT_APP_PROXY}/api/tasks`, params);
  }

  @action
  generateTaskNameByParams(params: Omit<CreateTaskDto, 'name'>) {
    try {
      const domainName = this.catalogStore.findDomainByValue(params.script)?.name;
      const cityName = this.cityStore.findCityById(params.cityId)?.name;
      const reductionRealEstateTypeName = match(params.params.realEstateType)
        .with(RealEstateTypeUpperCase.HOUSE, () => `(${ReductionRealEstateTypeUpperCase.HOUSE})`)
        .with(RealEstateTypeUpperCase.LAND, () => `(${ReductionRealEstateTypeUpperCase.LAND})`)
        .otherwise(() => '');
      const reductionDealTypeName = match(params.params.dealType)
        .with(DealType.RENT_SHORT, () => ReductionDealType.RENT_SHORT)
        .with(DealType.RENT_LONG, () => ReductionDealType.RENT_LONG)
        .otherwise(() => '');

      if (!domainName || !cityName) {
        return null;
      }

      return [domainName, cityName, reductionRealEstateTypeName, reductionDealTypeName]
        .filter((name) => Boolean(name))
        .join(' ');
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async updateTask(taskId: string, updatedTask: Task) {
    const { isActive, interval, ...otherParams } = updatedTask;
    const paramsForUpdate = { isActive, interval };

    return await axios.put(`${environments.REACT_APP_PROXY}/api/tasks/${taskId}`, paramsForUpdate).then(() => {
      this.cityStore.updateTask(updatedTask);
    });
  }
}
