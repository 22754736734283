import { ItemCount } from '#shared/enums';
import { isObjects } from '#shared/guards/is-objects.guard';

export const filterArrayByField = <T,>(array: T[], fieldKey: keyof T, fieldValue?: string | number) => {
  return array.filter((realEstateStat: T) => realEstateStat[fieldKey] === fieldValue);
};

const uniqueObjectArray = <T extends object>(arr: T[]) => {
  const seen = new Set<string>();

  return arr.filter((object) => {
    const json = JSON.stringify(object);

    if (!seen.has(json)) {
      seen.add(json);

      return object;
    }
  });
};

export const uniqueArray = <T,>(array: T[]): T[] => {
  const objectStorage: { [key: string]: T } = {};

  if (isObjects(array)) {
    // Принимает в себя только объекты (Массивы не учитываются в данном случае
    // как объекты)

    return uniqueObjectArray(array) as T[];
  }

  array.forEach((value) => {
    objectStorage[((value as string) + '::' + typeof value) as string] = value;
  });

  return Object.values(objectStorage);
};

type KeysWithNumberValue<T> = {
  [K in keyof T]: T[K] extends number | null | undefined ? K : never;
}[keyof T];

export const reduceAccumulatorByField = <T,>(arrayData: T[], fieldKey: KeysWithNumberValue<T>): number => {
  return arrayData.reduce((accumulator, objectData): number => {
    const val = objectData[fieldKey];

    if (typeof val === 'number') {
      return accumulator + val;
    }

    return accumulator;
  }, ItemCount.ZERO);
};
