import L from 'leaflet';
import { LatLntPoint } from '#shared/types/geo/point';

export type MarkerProps = {
  popupElement: {
    contentHtml: string;
  };
  markerIcon: L.DivIcon;
  latLngPoint: LatLntPoint;
  eventHandlers?: {
    onClick?: (marker: L.Marker) => void;
    onMouseOver?: (marker: L.Marker) => void;
    onMouseOut?: (marker: L.Marker) => void;
  };
};

export const RenderMarker = ({ popupElement, markerIcon, latLngPoint, eventHandlers }: MarkerProps) => {
  const popup = L.popup({
    closeButton: false,
    offset: [0, -15],
  })
    .setContent(
      `<div class="mapRealEstatePopup">
        ${popupElement.contentHtml}
  </div>`,
    )
    .setLatLng(latLngPoint);

  const marker = L.marker(latLngPoint, {
    autoPanOnFocus: false,
    riseOffset: 3,
    riseOnHover: true,
    zIndexOffset: 255,
    icon: markerIcon,
  })
    .bindPopup(popup, {
      autoPan: false,
    })
    .clearAllEventListeners();

  marker.on('mouseout', () => {
    marker.closePopup();

    eventHandlers?.onMouseOut ? eventHandlers.onMouseOut(marker) : null;
  });

  marker.on('mouseover', () => {
    eventHandlers?.onMouseOver ? eventHandlers.onMouseOver(marker) : null;
  });

  marker.on('click', (event) => {
    L.DomEvent.stopPropagation(event);
    event.originalEvent.stopPropagation();
    event.originalEvent.preventDefault();
    eventHandlers?.onClick ? eventHandlers.onClick(marker) : null;
  });

  return marker;
};

export default RenderMarker;
