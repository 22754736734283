import { FC, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '#entities/user/model/user-context';
import { PagePath, ParserRole } from '#shared/enums';

type ProtectedRouteByRoleProps = {
  allowedRoles: ParserRole[];
  children: ReactNode;
};

const ProtectedRouteByRole: FC<ProtectedRouteByRoleProps> = ({ allowedRoles, children }) => {
  const navigate = useNavigate();
  const userRole = useUser().role.name;
  const hasAllowedRole = allowedRoles.includes(userRole);

  const checkRoles = () => {
    if (!allowedRoles.includes(userRole)) {
      navigate(PagePath.FLATS);
    }
  };

  useEffect(() => {
    checkRoles();
  }, []);

  return <>{hasAllowedRole ? children : null}</>;
};

export default ProtectedRouteByRole;
