import { Props } from 'react-floater';
import { theme } from '#app/providers/theme';

export const defaultFloaterProps: Partial<Props> = {
  styles: {
    arrow: {
      length: 8, // Высота 8px
      spread: 16, // Длина 16px
    },
  },
};

export const defaultStylesJoyride = {
  options: {
    zIndex: 2000,
  },
  overlay: {
    maxHeight: 'none',
  },
};

export const defaultStylesByStep = {
  spotlight: {
    borderRadius: theme.custom.base.module.first_five,
  },
  disableAnimation: true,
};
