import React from 'react';

type RhoodIconProps = {
  opacity: string;
  color?: string;
  fill?: string;
  width?: string;
  height?: string;
};

const RhoodIcon: React.FC<RhoodIconProps> = ({ opacity, color, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width ?? '24px'}
      height={height ?? '24px'}
      viewBox='0 0 24 24'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.01317 19.5H2V4.5H8.02633C8.57927 4.5 9.11121 4.57322 9.62216 4.71967C10.1331 4.85914 10.609 5.06137 11.05 5.32636C11.4979 5.58438 11.9039 5.89819 12.2679 6.26778C12.6388 6.6304 12.9538 7.03487 13.2128 7.48117C13.4787 7.92748 13.6817 8.40516 13.8217 8.91423C13.9687 9.42329 14.0422 9.95328 14.0422 10.5042C14.0422 11.0202 13.9757 11.5223 13.8427 12.0105C13.7167 12.4986 13.5312 12.9623 13.2862 13.4017C13.0483 13.841 12.7543 14.2455 12.4043 14.6151C12.0544 14.9847 11.6624 15.3054 11.2285 15.5774L12.8978 19.5H9.69565L8.38329 16.477L5.01317 16.4979V19.5ZM5.01317 7.50209V13.5063H8.02633C8.43929 13.5063 8.82774 13.4296 9.1917 13.2762C9.55566 13.1158 9.87413 12.8996 10.1471 12.6276C10.4201 12.3556 10.6335 12.0384 10.7875 11.6757C10.9485 11.3061 11.029 10.9156 11.029 10.5042C11.029 10.0927 10.9485 9.70572 10.7875 9.3431C10.6335 8.9735 10.4201 8.65272 10.1471 8.38075C9.87413 8.10879 9.55566 7.8961 9.1917 7.74268C8.82774 7.58229 8.43929 7.50209 8.02633 7.50209H5.01317Z'
        fill={color ?? '#131D35'}
        fillOpacity={opacity}
      />
      <path
        d='M18.6704 6.40972C18.1999 5.70656 17.2463 5.51655 16.5406 5.98533C15.8348 6.4541 15.6441 7.40415 16.1146 8.10731L18.6183 11.8491L16.1146 15.5908C15.6441 16.294 15.8348 17.244 16.5406 17.7128C17.2463 18.1816 18.1999 17.9916 18.6704 17.2884L21.7421 12.6979C22.086 12.1839 22.086 11.5143 21.7421 11.0003L18.6704 6.40972Z'
        fill={color ?? '#131D35'}
        fillOpacity={opacity}
      />
    </svg>
  );
};

export default RhoodIcon;
