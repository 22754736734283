import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { MapOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { FontSize, FontWeight, PagePath, Size, TabLink } from '#shared/enums';
import { getPath } from '#shared/lib/get-path';
import { MapTableButton } from '#shared/styles/styles';

type LinkToMapRealEstateButtonProps = {
  selectedTab?: TabLink;
  to?: string;
};

const LinkToMapRealEstateButton: FC<LinkToMapRealEstateButtonProps> = ({ selectedTab, to }) => {
  const theme = useTheme();

  const getLink = (selectedTab: TabLink | undefined) => {
    if (selectedTab !== undefined) {
      return getPath(selectedTab as TabLink) + PagePath.MAP;
    }

    return to ?? '';
  };

  return (
    <Link to={getLink(selectedTab)}>
      <MapTableButton
        sx={{
          whiteSpace: 'nowrap',
          color: theme.palette.text.primary,
          fontSize: FontSize.FOURTEENTH_FONT,
          fontWeight: FontWeight.MEDIUM,
        }}
        variant='outlined'
        color='inherit'
        size={Size.MEDIUM}
        startIcon={<MapOutlined sx={{ color: theme.palette.text.primary }} />}
      >
        Показать на карте
      </MapTableButton>
    </Link>
  );
};

export default observer(LinkToMapRealEstateButton);
