import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import qs from 'qs';
import { match } from 'ts-pattern';
import { environments } from '#/environment';
import { socket } from '#/socket';
import { Comment } from '#entities/comment';
import { CatalogStore } from '#entities/domain/catalog.store';
import { User } from '#entities/user';
import { RealEstateCountDto } from '#shared/dto/real-estate-count-dto';
import { InitialState, PageLimit, RealEstateTypeUpperCase, TypeOrderBy, WsEvent } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { modifyPostBodyForRequest } from '#shared/lib/modify-post-body-for-request';
import { filterFieldFromObject } from '#shared/lib/remove-empty-field-from-object';
import { spyObjectByFieldValue } from '#shared/lib/spy-object-by-field-value';
import { FavouriteStore } from '#shared/lib/store';
import { AxiosErrorData } from '#shared/types/axios-response-error-data';
import { HouseFilters } from '../enum/house-filters.enum';
import { House } from '../types/type';
import { HouseFilterType } from './use-house-filters';

export class FavouriteHouseStore implements FavouriteStore<House> {
  @observable realEstate: House[] = [];
  @observable comments: Comment[] = [];
  @observable count: number = 0;
  @observable page: number = InitialState.FIRST;
  @observable rowsPerPage: PageLimit = PageLimit.FIFTY;
  @observable currentCommentPage: number = InitialState.FIRST;
  @observable isErrorData = true;
  @observable isConnectedDomains: boolean = false;
  @observable orderBy: TypeOrderBy = TypeOrderBy.PUBLISHED_AT_DESC;

  loading = true;
  loadingPhone = false;
  loadingCount = true;
  isFavoriteLoadingMap = new Map<string, boolean>();

  constructor(private readonly catalogStore: CatalogStore) {
    makeAutoObservable(this);
  }

  @action
  setOrderBy(orderBy: TypeOrderBy) {
    this.orderBy = orderBy;
  }

  @action
  resetStore() {
    this.realEstate = [];
    this.comments = [];
    this.count = 0;
    this.page = InitialState.FIRST;
    this.loading = true;
    this.loadingPhone = false;
    this.loading = true;
    this.currentCommentPage = InitialState.FIRST;
    this.isErrorData = true;
    this.orderBy = TypeOrderBy.PUBLISHED_AT_DESC;
  }

  @action
  setLoadingRealEstate(loadingState: boolean) {
    this.loading = loadingState;
  }

  @action
  setLoadingCount(loadingState: boolean) {
    this.loadingCount = loadingState;
  }

  @action
  setPage(page: number) {
    this.page = page;
  }

  @action
  setRowsPerPage(rowsPerPage: PageLimit) {
    this.rowsPerPage = rowsPerPage;
  }

  @action
  removeById(realEstateId: string) {
    this.realEstate = this.realEstate.filter((house) => house.id !== realEstateId);
  }

  @action
  findById(realEstateId: string) {
    return this.realEstate.find((house) => house.id === realEstateId);
  }

  async fetchByIdFromApi(realEstateId: string) {
    try {
      const res = await axios.get<House>(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}`);

      runInAction(() => {
        this.realEstate = [res.data];
      });
    } catch {
      return undefined;
    }
  }

  @action
  clearComments() {
    this.comments = [];
  }

  @action
  async fetchCount(objectFilter: HouseFilterType) {
    this.setLoadingCount(true);

    const modifiedFilter = modifyPostBodyForRequest(objectFilter, this.catalogStore.findStatuses().length);
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: modifiedFilter,
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
      exceptionTransformField: [HouseFilters.ADDRESS],
    });

    const response = await axios
      .post(`${environments.REACT_APP_PROXY}/api/houses/count`, {
        ...filteredObjectFilter,
      })
      .then((response: AxiosResponse<RealEstateCountDto>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<RealEstateCountDto>) => {
        runInAction(() => {
          this.count = response.data.count;
          this.setLoadingCount(false);
        });
      })
      .with({ status: HttpStatusCode.BadRequest }, () => {
        this.count = 0;
        this.setLoadingCount(false);
      })
      .otherwise(() => {
        this.setLoadingCount(false);
      });
  }

  @action
  async fetchComments(realEstateId: string, page: number, commentCount: number) {
    try {
      const pageCount: number = Math.ceil(commentCount / PageLimit.FIFTEEN);

      if (pageCount <= InitialState.FIRST) {
        this.clearComments();
      }

      const hasNextPage: boolean = page <= pageCount;

      if (!hasNextPage) {
        return;
      }

      const comments: Comment[] = await axios
        .get(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/comments?page=${page}`)
        .then((response) => {
          return response.data.reverse();
        });

      runInAction(() => {
        this.comments = [...comments, ...this.comments];
        this.currentCommentPage++;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async addToFavourites(realEstateId: string) {
    try {
      this.isFavoriteLoadingMap.set(realEstateId, true);

      await axios.post(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/favourites`).then(() => {
        const house = this.findById(realEstateId);

        if (!house) {
          return;
        }

        runInAction(() => {
          house.isFavourite = true;
          this.isFavoriteLoadingMap.set(realEstateId, false);
        });
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchFavourite(user: User) {
    this.loading = true;
    this.isErrorData = true;

    try {
      const datahouses: House[] = await axios
        .get(`${environments.REACT_APP_PROXY}/api/users/${user.id}/favourite-houses`, {
          params: {
            page: this.page,
            limit: this.rowsPerPage,
            orderBy: this.orderBy,
          },
          paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((res) => {
          return res.data.map((house: Record<string, any>) => {
            return {
              ...house,
              isFavourite: Boolean(house.usersFavourites.length),
            };
          });
        });

      runInAction(() => {
        this.realEstate = datahouses;
        this.loading = false;
        this.isErrorData = false;
      });
    } catch (error) {
      this.isErrorData = true;
      handleAxiosError(error);
    }

    this.isErrorData = false;
    this.loading = false;
  }

  @action
  async fetchFavouriteCount(userId: string) {
    try {
      axios.get(`${environments.REACT_APP_PROXY}/api/users/${userId}/favourite-houses/count`).then((response) => {
        runInAction(() => {
          this.count = response.data.count;
        });
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async changeFavourite(realEstateId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    if (house.isFavourite) {
      await this.removeFromFavourites(realEstateId);
    } else {
      await this.addToFavourites(realEstateId);
    }
  }

  @action
  changeStatus(realEstateId: string, statusId: string | null, userId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    if (statusId) {
      house.userStatus = {
        userId: userId,
        statusId: statusId,
      };

      return;
    }

    house.userStatus = null;
  }

  @action
  reserveForCall(params: { realEstateId: string; userId: string; avatarUrl: string; fullName: string }) {
    const house = this.findById(params.realEstateId);

    if (!house) {
      return;
    }

    house.callingUser = {
      id: params.userId,
      fullName: params.fullName,
      avatarUrl: params.avatarUrl,
    };
  }

  @action
  cancelReservationForCall(realEstateId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house.callingUser = null;
  }

  @action
  handleDisconnect(userId: string) {
    this.realEstate.forEach((house) => {
      if (house.callingUser?.id === userId) {
        house.callingUser = null;
      }
    });
  }

  @action
  addComment(comment: Comment) {
    this.comments.push(comment);
  }

  @action
  findPhoneById(realEstateId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    socket.emit(WsEvent.GET_PHONE, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
    this.loadingPhone = true;
  }

  @action
  setNewPhone(realEstateId: string, phone: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house.phone = phone;
    this.loadingPhone = false;
  }

  @action
  incrementCommentCount(realEstateId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house._count.comments++;
  }

  @action
  changeStatusEmit(realEstateId: string, userStatusId: string | null) {
    socket.emit(WsEvent.CHANGE_STATUS, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      statusId: userStatusId,
    });
  }

  @action
  callEndedEmit(realEstateId: string) {
    socket.emit(WsEvent.CALL_ENDED, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
  }

  @action
  makeCallEmit(realEstateId: string, phoneNumber?: string) {
    socket.emit(WsEvent.MAKE_CALL, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      phoneNumber: phoneNumber,
    });
  }

  @action
  addDuplicateEmit(realEstateId: string, crmId: string) {
    socket.emit(WsEvent.ADD_DUPLICATE, {
      realEstateId: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      crmRealEstateId: Number(crmId),
    });
  }

  @action
  saveRealEstateEmit(realEstateId: string) {
    socket.emit(WsEvent.SAVE_REAL_ESTATE, { id: realEstateId, realEstateType: RealEstateTypeUpperCase.HOUSE });
  }

  @action
  addCommentEmit(realEstateId: string, comment: string) {
    socket.emit(WsEvent.ADD_COMMENT, {
      id: realEstateId,
      text: comment,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
  }

  @action
  async removeFromFavourites(realEstateId: string) {
    try {
      this.isFavoriteLoadingMap.set(realEstateId, true);

      await axios.delete(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/favourites`).then(() => {
        const house = this.findById(realEstateId);

        if (!house) {
          return;
        }

        runInAction(() => {
          house.isFavourite = false;
        });
      });

      this.isFavoriteLoadingMap.set(realEstateId, false);
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
