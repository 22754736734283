import React from 'react';
import { Box } from '@mui/material';
import { Spinner } from '#shared/ui-kit/spinner/spinner';

const CircularProgress = () => {
  return (
    <Box height={'80px'}>
      <Spinner sx={{ color: 'blue' }} size={'24px'} />
    </Box>
  );
};

export default CircularProgress;
