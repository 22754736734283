export enum HouseFilters {
  CITY = 'city',
  DEAL_TYPE = 'dealType',
  PUBLISHED_AT_FROM = 'publishedAtFrom',
  PUBLISHED_AT_TO = 'publishedAtTo',
  AUTHOR = 'author',
  ADDRESS = 'address',
  PRICE_FROM = 'priceFrom',
  PRICE_TO = 'priceTo',
  ORDER_BY = 'orderBy',
  DOMAIN = 'domain',
  CAN_SEE_PART_MATCHES = 'canSeePartMatches',
  LAND_AREA_FROM = 'landAreaFrom',
  LAND_AREA_TO = 'landAreaTo',
  AREA_FROM = 'areaFrom',
  AREA_TO = 'areaTo',
  METRO_STATION = 'metroStationId',
  MICRODISTRICT = 'microdistrictId',
  STATUS = 'statusId',
}
