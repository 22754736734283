import { Step } from 'react-joyride';
import { makeAutoObservable } from 'mobx';
import { CallTour } from '#entities/user/model/tour/call-tour/call-tour';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { LkTour } from '#entities/user/model/tour/lk-tour/lk-tour';
import { SaveFilterTour } from '#entities/user/model/tour/save-filter-tour/save-filter-tour';
import { SaveObjectTour } from '#entities/user/model/tour/save-object-tour/save-object-tour';
import { SubscriptionOnSearchTour } from '#entities/user/model/tour/subscription-on-search/subscription-on-search-tour';
import { WorkObjectTour } from '#entities/user/model/tour/work-object-tour/work-object-tour';
import { OnboardingController } from '#shared/api/controllers/on-boarding.controller';
import { OnboardingStepResponse } from '#shared/api/generated-api/api.schemas';
import { LocalStorageName } from '#shared/enums';
import { LocalStorageService } from '#shared/service/local-storage.service';

interface IUserInstructionStorage {
  isVisible: boolean;
}

export interface AdvancedStep extends Step {
  showNavigationButtons?: boolean;
  showPrevArrow?: boolean;
  showNextArrow?: boolean;
  customArrow?: {
    styles?: React.CSSProperties;
  };
}

export interface State extends Omit<OnboardingStepResponse, 'content'> {
  run: boolean;
  stepIndex: number;
  steps: AdvancedStep[];
}

export class UserInstructionStore {
  data: IOnboardingStep[] = [];
  saveFilterTour: SaveFilterTour = {} as SaveFilterTour;
  subscriptionOnSearchTour: SubscriptionOnSearchTour = {} as SubscriptionOnSearchTour;
  workObjectTour: WorkObjectTour = {} as WorkObjectTour;
  callTour: CallTour = {} as CallTour;
  saveObjectTour = {} as SaveObjectTour;
  lkTour: LkTour = {} as LkTour;
  isVisible: boolean = false;

  constructor() {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  initialize = async () => {
    this.data = await OnboardingController.getAllOnboardingSteps();

    const saveFilterData = this.data.find((tour) => tour.orderNumber === 1);
    const subscriptionOnSearchData = this.data.find((tour) => tour.orderNumber === 2);
    const workObjectData = this.data.find((tour) => tour.orderNumber === 3);
    const callData = this.data.find((tour) => tour.orderNumber === 4);
    const saveObjectData = this.data.find((tour) => tour.orderNumber === 5);
    const lkData = this.data.find((tour) => tour.orderNumber === 6);

    const isExistAllStepsData =
      saveFilterData && saveObjectData && lkData && callData && workObjectData && subscriptionOnSearchData;

    if (isExistAllStepsData) {
      this.saveFilterTour = new SaveFilterTour(saveFilterData);
      this.subscriptionOnSearchTour = new SubscriptionOnSearchTour(subscriptionOnSearchData);
      this.workObjectTour = new WorkObjectTour(workObjectData);
      this.callTour = new CallTour(callData);
      this.saveObjectTour = new SaveObjectTour(saveObjectData);
      this.lkTour = new LkTour(lkData);

      const storageData = LocalStorageService.getItemStorage<IUserInstructionStorage>(
        LocalStorageName.USER_INSTRUCTION,
      );

      if (storageData) {
        this.setVisible(storageData.isVisible);
      } else {
        this.setVisible(true);
      }

      return;
    }

    throw new Error('Ошибка при получении данных для инструкции');
  };

  handleOpenInstruction = () => {
    LocalStorageService.setItemStorage<IUserInstructionStorage>(LocalStorageName.USER_INSTRUCTION, {
      isVisible: true,
    });
    this.setVisible(true);
  };

  handleCloseInstruction = () => {
    LocalStorageService.setItemStorage<IUserInstructionStorage>(LocalStorageName.USER_INSTRUCTION, {
      isVisible: false,
    });
    this.setVisible(false);
  };

  setVisible = (isVisible: UserInstructionStore['isVisible']) => {
    this.isVisible = isVisible;
  };
}
