import { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { DateTime } from 'luxon';
import { action, makeAutoObservable } from 'mobx';
import { match } from 'ts-pattern';
import { ItemCount, StatusName } from '#/shared/enums';
import { CatalogStore } from '#entities/domain/catalog.store';
import { Flat, FlatStore } from '#entities/real-estate/flat';
import { User } from '#entities/user';
import { defaultStylesByStep } from '#entities/user/model/tour/common/const/styles';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { ITourWithFakeFlat } from '#entities/user/model/tour/common/interface/tour-with-fake-flat.interface';
import { userStore } from '#entities/user/model/user-store.context';
import { generateFlatFakeData } from '../common/builder/flat-response-builder';
import { State } from '../user-Instruction-store';

interface ISaveObjectState extends State {
  openedMakeCallDrawerId: string | null;
}

export class SaveObjectTour implements ITourWithFakeFlat {
  data: ISaveObjectState;
  flatStore: FlatStore = {} as FlatStore;
  catalogStore: CatalogStore = {} as CatalogStore;
  fakeFlat: Flat | null = null;
  constructor(onboardingStep: IOnboardingStep) {
    const { content, ...otherFields } = onboardingStep;

    this.data = {
      ...otherFields,
      run: false,
      stepIndex: 0,
      openedMakeCallDrawerId: null,
      steps: [
        {
          content: content.step1,
          placement: 'right-end',
          disableBeacon: true,
          spotlightPadding: 0,
          offset: -4,
          target: '.realEstate:first-child>.detail-button-cell',
          showNavigationButtons: false,
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -30,
            },
          },
        },
        {
          content: content.step2,
          placement: 'bottom',
          spotlightPadding: 4,
          offset: 0,
          target: '#save-in-crm-button',
          showNavigationButtons: true,
          spotlightClicks: false,
          floaterProps: {
            autoOpen: true,
          },
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: -125,
            },
          },
        },
        {
          content: content.step3,
          placement: 'bottom',
          spotlightPadding: 4,
          offset: 0,
          target: '#duplicate-button',
          showNavigationButtons: false,
          spotlightClicks: false,
          styles: {
            ...defaultStylesByStep,
            overlay: {
              pointerEvents: 'auto',
            },
            tooltip: {
              left: -40,
            },
          },
        },
      ],
    };
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setData = (data: SaveObjectTour['data']) => {
    this.data = data;
  };

  goNextStep = () => {
    this.setStepIndex(this.data.stepIndex + ItemCount.ONE);
  };

  goPrevStep = () => {
    this.setStepIndex(this.data.stepIndex - ItemCount.ONE);
  };

  setRun = (run: boolean) => {
    this.data.run = run;
  };

  setStepIndex = (index: number) => {
    this.data.stepIndex = index;
  };

  handleCallback = (data: CallBackProps, user: User) => {
    const { action, index, status, type } = data;
    const countStepWithMinusOne = this.data.steps.length - ItemCount.ONE;

    if (index === countStepWithMinusOne && type === EVENTS.TOOLTIP) {
      // Завершение тура
      if (!userStore.checkLocalHasCompleteOnboarding(this.data.id)) {
        userStore.createOnboardingProgress(user.id, this.data.id);
      }

      return;
    }

    if (action === ACTIONS.CLOSE) {
      this.handleClose();
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const futureIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      match({ data, futureIndex })
        .with({ futureIndex: 0 }, (data) => {
          // Логика прописана в компонентах detailButtonCell и
          // real-estate-body-real-estate
          this.setOpenedMakeCallDrawerId(null);
          this.setStepIndex(futureIndex);

          return;
        })
        .otherwise(() => {
          this.setStepIndex(futureIndex);
        });
    }
  };

  handleOpen = (user?: User) => {
    if (user) {
      this.setFakeFlat(this.makeMutationFlat(generateFlatFakeData({ userId: user.id }), user.id));
    }

    if (this.fakeFlat) {
      this.addFakeFlatToList(this.fakeFlat);
    }

    this.setRun(true);
    this.setStepIndex(0);
  };

  @action
  handleClose = () => {
    this.setRun(false);
    this.setOpenedMakeCallDrawerId(null);
    this.setStepIndex(0);
    this.removeFakeFlatToList();
    this.setFakeFlat(null);
  };

  getData = () => {
    return this.data;
  };

  setOpenedMakeCallDrawerId = (openedMakeCallDrawerId: string | null) => {
    this.data.openedMakeCallDrawerId = openedMakeCallDrawerId;
  };

  setFlatStore = (flatStore: FlatStore) => {
    this.flatStore = flatStore;
  };

  setCatalogStore = (catalogStore: CatalogStore) => {
    this.catalogStore = catalogStore;
  };

  makeMutationFlat = (fakeFlat: Flat | null, userId: string) => {
    if (fakeFlat) {
      const notAnswerStatus = this.catalogStore.findStatusByName(StatusName.NO_RESPONSE);

      if (notAnswerStatus && notAnswerStatus.id) {
        fakeFlat.userStatus = {
          userId,
          statusId: notAnswerStatus.id,
        };
      }

      return fakeFlat;
    }

    return null;
  };

  setFakeFlat = (flat: Flat | null) => {
    this.fakeFlat = flat;
  };

  addFakeFlatToList = (flat: Flat) => {
    this.flatStore.realEstate.unshift(flat);
  };

  removeFakeFlatToList = () => {
    const fakeFlat = this.fakeFlat;

    if (fakeFlat) {
      const index = this.flatStore.realEstate.findIndex((flat) => flat.id === fakeFlat.id);

      if (index !== undefined) {
        this.flatStore.realEstate.splice(index, 1);
      }
    }
  };
}
