export enum SnackbarMessage {
  MY_SAVE_CRM = 'Объект сохранен в',
  OTHER_SAVE_CRM = 'Другой риелтор сохранил этот объект',
  MY_DUPLICATE = 'Объект отмечен как дубль и удален из списка',
  OTHER_DUPLICATE = 'Этот объект отметили как дубль',
  COPY_CODE = 'Код скопирован в буфер обмена',
  IS_EXIST = 'Такой фильтр уже существует',
  NOT_CORRECT_FILTER = 'Некорректно заполнен фильтр',
  NEW_TELEGRAM_OBJECT = 'Чтобы подписаться на большее число фильтров подключите RHood Plus',
  RECOGNITION_TEXT = 'Чтобы снять ограничение подключите подписку RHood Plus',
  USER_FILTER_LIMIT_REACHED = 'Можно сохранить только 10 поисков',
}
