import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { match, P } from 'ts-pattern';
import { environments } from '#/environment';
import { modifyPostBodyMapParams } from '#/shared/lib/modify-post-body-map-params';
import { socket } from '#/socket';
import { Comment } from '#entities/comment';
import { CatalogStore } from '#entities/domain/catalog.store';
import { getFilterWithoutMapParams } from '#entities/real-estate';
import { ClusterByRealEstateDto } from '#shared/dto/cluster-by-real-estate-dto';
import { RealEstateCountDto } from '#shared/dto/real-estate-count-dto';
import {
  InitialState,
  PageLimit,
  RealEstateErrorDataMessage,
  RealEstateTypeUpperCase,
  TimeConvert,
  WsEvent,
} from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { modifyPostBodyForRequest } from '#shared/lib/modify-post-body-for-request';
import { filterFieldFromObject } from '#shared/lib/remove-empty-field-from-object';
import { spyObjectByFieldValue } from '#shared/lib/spy-object-by-field-value';
import { Store } from '#shared/lib/store';
import { AxiosErrorData } from '#shared/types/axios-response-error-data';
import { GeometryWithoutGeometryCollection } from '#shared/types/geo/geo-json-geometry-type';
import { HouseFilters } from '../enum/house-filters.enum';
import { House } from '../types/type';
import { HouseFilterType } from './use-house-filters';

export class HouseStore implements Store<House, HouseFilterType> {
  @observable realEstate: House[] = [];
  @observable comments: Comment[] = [];
  @observable count: number = 0;
  currentFilter: null | HouseFilterType = null;
  @observable page: InitialState = InitialState.FIRST;
  @observable rowsPerPage: PageLimit = PageLimit.FIFTY;
  @observable clusters: ClusterByRealEstateDto[] = [];
  loading = true;
  loadingPhone = false;
  loadingCount = true;
  isFavoriteLoadingMap = new Map<string, boolean>();
  @observable currentCommentPage: number = InitialState.FIRST;
  @observable isErrorData = true;
  @observable isChangingFilter: boolean = true;
  @observable isConnectedDomains: boolean = false;
  cancelTokenSource = axios.CancelToken.source();

  constructor(private readonly catalogStore: CatalogStore) {
    makeAutoObservable(this);
  }

  @action
  resetStore() {
    this.realEstate = [];
    this.comments = [];
    this.count = 0;
    this.currentFilter = null;
    this.page = InitialState.FIRST;
    this.loading = true;
    this.loadingPhone = false;
    this.loading = true;
    this.loadingCount = true;
    this.currentCommentPage = InitialState.FIRST;
    this.isErrorData = true;
    this.isChangingFilter = true;
  }

  @action
  setChangingFilter(isChangingFilter: boolean) {
    this.isChangingFilter = isChangingFilter;
  }

  @action
  async fetchDataClusters() {
    if (!this.currentFilter) {
      return;
    }

    try {
      const modifiedFilter = modifyPostBodyForRequest(this.currentFilter, this.catalogStore.findStatuses().length);

      const filteredObjectClusterFilter = spyObjectByFieldValue({
        object: filterFieldFromObject({
          object: getFilterWithoutMapParams(modifiedFilter),
          isFilterEmptyString: true,
          isEmptyArray: true,
          isFilterNull: true,
        }),
        exceptionTransformField: [HouseFilters.ADDRESS],
      });

      const dataHouses: ClusterByRealEstateDto[] = await axios
        .post(`${environments.REACT_APP_PROXY}/api/houses/clusters`, filteredObjectClusterFilter)
        .then((response) => response.data);

      runInAction(() => {
        this.clusters = dataHouses;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  setLoadingRealEstate(loadingState: boolean) {
    this.loading = loadingState;
  }

  @action
  clearComments() {
    this.comments = [];
  }

  @action
  setLoadingCount(loadingState: boolean) {
    this.loadingCount = loadingState;
  }

  @action
  setPage(page: InitialState) {
    this.page = page;
  }

  @action
  setRowsPerPage(rowsPerPage: PageLimit) {
    this.rowsPerPage = rowsPerPage;
  }

  @action
  setCurrentFilter(objectFilter: HouseFilterType) {
    runInAction(() => {
      this.currentFilter = objectFilter;
    });
  }

  @action
  changeValueByCurrentFilter<T extends keyof HouseFilterType>(fieldName: T, value: HouseFilterType[T]) {
    if (!this.currentFilter) {
      return;
    }

    this.currentFilter[fieldName] = value;
  }

  @action
  removeFieldByFilter<T extends keyof HouseFilterType>(fieldName: T) {
    if (!this.currentFilter) {
      return;
    }

    delete this.currentFilter[fieldName];
  }

  @action
  removeById(realEstateId: string) {
    this.realEstate = this.realEstate.filter((house) => house.id !== realEstateId);
  }

  @action
  findById(realEstateId: string) {
    return this.realEstate.find((house) => house.id === realEstateId);
  }

  @action
  async fetchData(startTime?: DateTime, geometry?: GeometryWithoutGeometryCollection, zoom?: number) {
    if (!this.currentFilter) {
      return;
    }

    this.setLoadingRealEstate(true);

    const modifyFilterByMapParams = modifyPostBodyMapParams(this.currentFilter, { geometry, zoom });
    const modifiedFilter = modifyPostBodyForRequest(modifyFilterByMapParams, this.catalogStore.findStatuses().length);
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: modifiedFilter,
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
      exceptionTransformField: [HouseFilters.ADDRESS],
    });

    this.cancelTokenSource.cancel();
    this.cancelTokenSource = axios.CancelToken.source();

    const response = await axios
      .post(
        `${environments.REACT_APP_PROXY}/api/houses`,
        {
          page: this.page,
          limit: this.rowsPerPage,
          ...filteredObjectFilter,
        },
        {
          cancelToken: this.cancelTokenSource.token,
        },
      )
      .then((response: AxiosResponse<House[]>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<House[]>) => {
        runInAction(() => {
          this.realEstate = response.data.map((res) => {
            res.isFavourite = Boolean(res.usersFavourites.length);

            return res;
          });
          this.isConnectedDomains = true;

          if (startTime) {
            const debounceChangeLoading = debounce(() => {
              this.setLoadingRealEstate(false);
            }, TimeConvert.MILLISECONDS_IN_SECOND);

            debounceChangeLoading();

            return;
          }

          this.setLoadingRealEstate(false);
        });
      })
      .with({ status: HttpStatusCode.BadRequest }, (response: AxiosResponse<AxiosErrorData>) => {
        match(response.data.message)
          .with(
            P.when((message) => message.includes(RealEstateErrorDataMessage.DOMAIN_MUST_BE_ARRAY)),
            () => {
              this.isConnectedDomains = false;
              this.setLoadingRealEstate(false);
            },
          )
          .otherwise(() => {
            this.isConnectedDomains = true;
            this.setLoadingRealEstate(false);
          });
      })
      .otherwise(() => {
        this.isConnectedDomains = true;
        this.setLoadingRealEstate(false);
      });
  }

  @action
  async summaryFetchData(page: number, zoom: number, geometry?: GeometryWithoutGeometryCollection) {
    if (!this.currentFilter) {
      return false;
    }

    try {
      const modifyFilterByMapParams = modifyPostBodyMapParams(this.currentFilter, { geometry, zoom });
      const modifiedFilter = modifyPostBodyForRequest(modifyFilterByMapParams, this.catalogStore.findStatuses().length);
      const filteredObjectFilter = spyObjectByFieldValue({
        object: filterFieldFromObject({
          object: modifiedFilter,
          isFilterEmptyString: true,
          isEmptyArray: true,
          isFilterNull: true,
        }),
        exceptionTransformField: [HouseFilters.ADDRESS],
      });
      const dataHouses: House[] = await axios
        .post(`${environments.REACT_APP_PROXY}/api/houses`, {
          page: page,
          limit: this.rowsPerPage,
          ...filteredObjectFilter,
        })
        .then((response) => response.data);

      runInAction(() => {
        this.realEstate = [...this.realEstate, ...dataHouses];
      });

      return Boolean(dataHouses.length);
    } catch (error) {
      handleAxiosError(error);
    }

    return false;
  }

  @action
  async fetchCount(objectFilter: HouseFilterType) {
    this.setLoadingCount(true);

    const modifyFilterByMapParams = modifyPostBodyMapParams(objectFilter);
    const modifiedFilter = modifyPostBodyForRequest(modifyFilterByMapParams, this.catalogStore.findStatuses().length);
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: modifiedFilter,
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
      exceptionTransformField: [HouseFilters.ADDRESS],
    });

    const response = await axios
      .post(`${environments.REACT_APP_PROXY}/api/houses/count`, {
        ...filteredObjectFilter,
      })
      .then((response: AxiosResponse<RealEstateCountDto>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<RealEstateCountDto>) => {
        runInAction(() => {
          this.count = response.data.count;
          this.setLoadingCount(false);
        });
      })
      .with({ status: HttpStatusCode.BadRequest }, () => {
        this.count = 0;
        this.setLoadingCount(false);
      })
      .otherwise(() => {
        this.setLoadingCount(false);
      });
  }

  async fetchByIdFromApi(realEstateId: string) {
    try {
      const res = await axios.get<House>(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}`);

      runInAction(() => {
        this.realEstate = [res.data];
      });
    } catch {
      return undefined;
    }
  }

  @action
  async fetchComments(realEstateId: string, page: number, commentCount: number) {
    try {
      const pageCount: number = Math.ceil(commentCount / PageLimit.FIFTEEN);

      if (pageCount <= InitialState.FIRST) {
        this.clearComments();
      }

      const hasNextPage: boolean = page <= pageCount;

      if (!hasNextPage) {
        return;
      }

      const comments: Comment[] = await axios
        .get(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/comments?page=${page}`)
        .then((response) => {
          return response.data.reverse();
        });

      runInAction(() => {
        this.comments = [...comments, ...this.comments];
        this.currentCommentPage++;
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async addToFavourites(realEstateId: string) {
    try {
      this.isFavoriteLoadingMap.set(realEstateId, true);

      await axios.post(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/favourites`).then(() => {
        const house = this.findById(realEstateId);

        if (!house) {
          return;
        }

        runInAction(() => {
          house.isFavourite = true;
          this.isFavoriteLoadingMap.set(realEstateId, false);
        });
      });
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async changeFavourite(realEstateId: string) {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    if (house.isFavourite) {
      await this.removeFromFavourites(realEstateId);
    } else {
      await this.addToFavourites(realEstateId);
    }
  }

  addComment(comment: Comment): void {
    this.comments.push(comment);
  }

  cancelReservationForCall(realEstateId: string): void {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house.callingUser = null;
  }

  changeStatus(realEstateId: string, statusId: string | null, userId: string): void {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    if (statusId) {
      house.userStatus = {
        userId: userId,
        statusId: statusId,
      };

      return;
    }

    house.userStatus = null;
  }

  findPhoneById(realEstateId: string): void {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    socket.emit(WsEvent.GET_PHONE, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
    this.loadingPhone = true;
  }

  handleDisconnect(userId: string): void {
    this.realEstate.forEach((house) => {
      if (house.callingUser?.id === userId) {
        house.callingUser = null;
      }
    });
  }

  incrementCommentCount(realEstateId: string): void {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house._count.comments++;
  }

  reserveForCall(params: { realEstateId: string; userId: string; avatarUrl: string; fullName: string }): void {
    const house = this.findById(params.realEstateId);

    if (!house) {
      return;
    }

    house.callingUser = {
      id: params.userId,
      fullName: params.fullName,
      avatarUrl: params.avatarUrl,
    };
  }

  setNewPhone(realEstateId: string, phone: string): void {
    const house = this.findById(realEstateId);

    if (!house) {
      return;
    }

    house.phone = phone;
    this.loadingPhone = false;
  }

  @action
  changeStatusEmit(realEstateId: string, userStatusId: string | null) {
    socket.emit(WsEvent.CHANGE_STATUS, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      statusId: userStatusId,
    });
  }

  @action
  callEndedEmit(realEstateId: string) {
    socket.emit(WsEvent.CALL_ENDED, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
  }

  @action
  makeCallEmit(realEstateId: string, phoneNumber?: string) {
    socket.emit(WsEvent.MAKE_CALL, {
      id: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      phoneNumber: phoneNumber,
    });
  }

  @action
  addDuplicateEmit(realEstateId: string, crmId: string) {
    socket.emit(WsEvent.ADD_DUPLICATE, {
      realEstateId: realEstateId,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
      crmRealEstateId: Number(crmId),
    });
  }

  @action
  saveRealEstateEmit(realEstateId: string) {
    socket.emit(WsEvent.SAVE_REAL_ESTATE, { id: realEstateId, realEstateType: RealEstateTypeUpperCase.HOUSE });
  }

  @action
  addCommentEmit(realEstateId: string, comment: string) {
    socket.emit(WsEvent.ADD_COMMENT, {
      id: realEstateId,
      text: comment,
      realEstateType: RealEstateTypeUpperCase.HOUSE,
    });
  }

  @action
  async removeFromFavourites(realEstateId: string) {
    try {
      this.isFavoriteLoadingMap.set(realEstateId, true);

      await axios.delete(`${environments.REACT_APP_PROXY}/api/houses/${realEstateId}/favourites`).then(() => {
        const house = this.findById(realEstateId);

        if (!house) {
          return;
        }

        runInAction(() => {
          house.isFavourite = false;
        });
      });

      this.isFavoriteLoadingMap.set(realEstateId, false);
    } catch (error) {
      handleAxiosError(error);
    }
  }

  @action
  async fetchNewData() {
    if (!this.currentFilter || !this.currentFilter.domain.length) {
      return;
    }

    const modifiedFilter = modifyPostBodyForRequest(this.currentFilter, this.catalogStore.findStatuses().length);
    const filteredObjectFilter = spyObjectByFieldValue({
      object: filterFieldFromObject({
        object: modifiedFilter,
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
      exceptionTransformField: [HouseFilters.ADDRESS],
    });

    const response = await axios
      .post(`${environments.REACT_APP_PROXY}/api/houses`, {
        page: this.page,
        limit: this.rowsPerPage,

        ...filteredObjectFilter,
      })
      .then((response: AxiosResponse<House[]>) => response);

    const oldIds = new Set(this.realEstate.map((house) => house.id));
    const newHouses = response.data.filter((newHouse) => !oldIds.has(newHouse.id));
    const newHousesWithNewProp = newHouses.map((house) => {
      return { ...house, isNew: true };
    });

    runInAction(() => {
      this.realEstate.forEach((house) => {
        house.isNew = false;
      });
    });

    if (newHouses.length) {
      this.realEstate = this.realEstate.filter((house) => !house.isNew);
      this.realEstate.unshift(...newHousesWithNewProp);
    }
  }
}
