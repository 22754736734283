import React, { createContext, ReactElement } from 'react';
import { MapStore } from '#features/real-estate-map/map-store';
import { CityStore } from '#entities/city/model/city.store';
import { ConnectCityStore } from '#entities/city/model/connect-city.store';
import { CatalogStore } from '#entities/domain/catalog.store';
import { PaymentStore } from '#entities/payment/model/payment.store';
import { CrmFlatStore } from '#entities/real-estate/crm-flat/model/crm-flat.store';
import { CrmHouseStore } from '#entities/real-estate/crm-house/model/crm-house.store';
import { CrmLandStore } from '#entities/real-estate/crm-land/model/crm-land.store';
import { FlatFilterType } from '#entities/real-estate/flat';
import { FavouriteFlatStore } from '#entities/real-estate/flat/model/favourite-flat.store';
import { FlatStore } from '#entities/real-estate/flat/model/flat.store';
import { Flat } from '#entities/real-estate/flat/types/type';
import { FlatCallStore } from '#entities/real-estate/flat-call/model/flat-call.store';
import { CallDataFlat, CallDataHouse, CallDataLand } from '#entities/real-estate/flat-call/types/type';
import { HouseFilterType } from '#entities/real-estate/house';
import { FavouriteHouseStore } from '#entities/real-estate/house/model/favourite-house-store';
import { HouseStore } from '#entities/real-estate/house/model/house.store';
import { House } from '#entities/real-estate/house/types/type';
import { HouseCallStore } from '#entities/real-estate/house-call/model/house-call.store';
import { LandFilterType } from '#entities/real-estate/land';
import { FavouriteLandStore } from '#entities/real-estate/land/model/favourite-land-store';
import { LandStore } from '#entities/real-estate/land/model/land.store';
import { Land } from '#entities/real-estate/land/types/type';
import { LandCallStore } from '#entities/real-estate/land-call/model/land-call.store';
import { CrmFlat, CrmHouse, CrmLand } from '#entities/real-estate/object/types/crm-real-estate';
import { TaskStore } from '#entities/task/model/task.store';
import { StatTeamStore } from '#entities/user/model/stat-team.store';
import { UserInstructionStore } from '#entities/user/model/tour/user-Instruction-store';
import { CallStore } from '#shared/lib/call-store';
import { CrmStore } from '#shared/lib/crm-store';
import { FavouriteStore, Store } from '#shared/lib/store';

class RootStore {
  constructor(
    public readonly flatStore: Store<Flat, FlatFilterType>,
    public readonly crmFlatStore: CrmStore<CrmFlat>,
    public readonly houseStore: Store<House, HouseFilterType>,
    public readonly crmHouseStore: CrmStore<CrmHouse>,
    public readonly landStore: Store<Land, LandFilterType>,
    public readonly crmLandStore: CrmStore<CrmLand>,
    public readonly catalogStore: CatalogStore,
    public readonly cityStore: CityStore,
    public readonly flatCallStore: CallStore<CallDataFlat>,
    public readonly houseCallStore: CallStore<CallDataHouse>,
    public readonly landCallStore: CallStore<CallDataLand>,
    public readonly favouriteFlatStore: FavouriteStore<Flat>,
    public readonly favouriteHouseStore: FavouriteStore<House>,
    public readonly favouriteLandStore: FavouriteStore<Land>,
    public readonly flatMapStore: MapStore,
    public readonly houseMapStore: MapStore,
    public readonly landMapStore: MapStore,
    public readonly paymentStore: PaymentStore,
    public readonly statTeamStore: StatTeamStore,
    public readonly connectCityStore: ConnectCityStore,
    public readonly taskStore: TaskStore,
    public readonly userInstructionStore: UserInstructionStore,
  ) {}
}

export const RootStoreContext = createContext<RootStore>({} as RootStore);

const catalogStore = new CatalogStore();
const flatStore = new FlatStore(catalogStore);
const crmFlatStore = new CrmFlatStore();
const houseStore = new HouseStore(catalogStore);
const crmHouseStore = new CrmHouseStore();
const landStore = new LandStore(catalogStore);
const crmLandStore = new CrmLandStore();
const cityStore = new CityStore();
const flatCallStore = new FlatCallStore();
const houseCallStore = new HouseCallStore();
const landCallStore = new LandCallStore();
const favouriteFlatStore = new FavouriteFlatStore(catalogStore);
const favouriteHouseStore = new FavouriteHouseStore(catalogStore);
const favouriteLandStore = new FavouriteLandStore(catalogStore);
const flatMapStore = new MapStore(cityStore, flatStore);
const houseMapStore = new MapStore(cityStore, houseStore);
const landMapStore = new MapStore(cityStore, landStore);
const paymentStore = new PaymentStore();
const statTeamStore = new StatTeamStore();
const connectCityStore = new ConnectCityStore(cityStore);
const taskStore = new TaskStore(catalogStore, cityStore);
const userInstructionStore = new UserInstructionStore();

const rootStore = new RootStore(
  flatStore,
  crmFlatStore,
  houseStore,
  crmHouseStore,
  landStore,
  crmLandStore,
  catalogStore,
  cityStore,
  flatCallStore,
  houseCallStore,
  landCallStore,
  favouriteFlatStore,
  favouriteHouseStore,
  favouriteLandStore,
  flatMapStore,
  houseMapStore,
  landMapStore,
  paymentStore,
  statTeamStore,
  connectCityStore,
  taskStore,
  userInstructionStore,
);

export const RootStoreProvider: React.FC<React.PropsWithChildren<Record<string, ReactElement>>> = ({ children }) => {
  return <RootStoreContext.Provider value={rootStore}>{children}</RootStoreContext.Provider>;
};
