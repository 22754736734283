export enum Domain {
  YANDEX = 'YANDEX',
  CIAN = 'CIAN',
  AVITO = 'AVITO',
  YOULA = 'YOULA',
  DOMCLICK = 'DOMCLICK',
  METRIKA = 'METRIKA',
  SOVA = 'SOVA',
  REALT = 'REALT',
  KRISHA = 'KRISHA',
  SAMOLET_PLUS = 'SAMOLET_PLUS',
  DOSKA = 'DOSKA',
  MIRKVARTIR = 'MIRKVARTIR',
  FARPOST = 'FARPOST',
  OLAN = 'OLAN',
  MOVE = 'MOVE',
  REALTY_1777 = 'REALTY_1777',
  M2 = 'M2',
  GIPERNN = 'GIPERNN',
}

export enum DomainWithTaskCookie {
  YANDEX = 'YANDEX',
  FARPOST = 'FARPOST',
}
