import { Feature, Position } from 'geojson';
import { match } from 'ts-pattern';
import * as turf from '@turf/turf';
import { MinCountGeometry } from '#shared/enums/geo/min-count-geometry.enum';
import { flipPointCoordinates } from '#shared/lib/geo/flip-coordinates';

export const createFeatureByCoordinates = <T>(
  coordinatesPoints: Position[],
  isFlipCoordinates: boolean = false,
): Feature | null => {
  const coordinatesArray = isFlipCoordinates
    ? coordinatesPoints.map((coordinates) => flipPointCoordinates(coordinates))
    : coordinatesPoints;

  const feature = match(coordinatesPoints.length)
    .with(MinCountGeometry.POINT, () => turf.point(coordinatesArray[0]))
    .with(MinCountGeometry.LINE_STRING, () => turf.lineString(coordinatesPoints))
    .otherwise(() => {
      return turf.lineToPolygon(turf.lineString(coordinatesArray));
    });

  return feature;
};
