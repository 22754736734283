export const getInitials = (value: string) => {
  if (!value) {
    return '';
  }

  return value
    .split(' ')
    .filter((string) => Boolean(string))
    .map((name) => name[0].toUpperCase())
    .join('');
};
