import * as GeoJSON from 'geojson';
import { Feature, Position } from 'geojson';
import L from 'leaflet';
import Supercluster from 'supercluster';
import RenderMarker, { MarkerProps } from '#features/real-estate-map/marker';
import { getMarkerIcon } from '#features/real-estate-map/ui/marker';
import { SuperClusterSettingsEnum } from '#shared/enums/geo/super-cluster-settings.enum';
import { uniqueArray } from '#shared/lib/array/array';
import { createFeatureByCoordinates } from '#shared/lib/geo/create-feature-by-coordinates';
import { transformPointFeatureToGeometryCoordinates } from '#shared/lib/geo/transform/transform-point-feature-to-geometry-coordinates';

type ClusterMarkerProps<T> = Omit<MarkerProps, 'renderMarker' | 'markerIcon' | 'markerId' | 'eventHandlers'> & {
  selectedMarkerId: string | number | undefined;
  getZoom: () => number;
  indexSuperCluster: Supercluster;
  feature: Supercluster.ClusterFeature<T>;
  eventHandlers?: {
    onClick?: (marker: L.Marker, featurePolygon: Feature | null, childrenUniqueCoordinatePoints: Position[]) => void;
    onMouseOver?: (
      marker: L.Marker,
      featurePolygon: Feature | null,
      childrenUniqueCoordinatePoints: Position[],
    ) => void;
    onMouseOut?: (marker: L.Marker) => void;
  };
};

function RenderClusterMarker<T extends GeoJSON.GeoJsonProperties>({
  selectedMarkerId,
  getZoom,
  indexSuperCluster,
  popupElement,
  latLngPoint,
  feature,
  eventHandlers,
}: ClusterMarkerProps<T>) {
  const notUniqueClusterPoints = indexSuperCluster.getLeaves(
    feature.properties.cluster_id,
    SuperClusterSettingsEnum.LIMIT_LEAVES_POINTS,
  );
  const uniqueCoordinatePointClusters = uniqueArray(transformPointFeatureToGeometryCoordinates(notUniqueClusterPoints));

  const featurePolygon = createFeatureByCoordinates(uniqueCoordinatePointClusters, true);

  return RenderMarker({
    popupElement: {
      contentHtml: popupElement.contentHtml,
    },
    markerIcon: getMarkerIcon({
      count: feature.properties.point_count,
      mapZoom: getZoom(),
      isSelectedCluster: selectedMarkerId === feature.properties.cluster_id,
    }),
    latLngPoint: latLngPoint,
    eventHandlers: {
      onMouseOut: (marker) => {
        eventHandlers?.onMouseOut ? eventHandlers.onMouseOut(marker) : null;
      },
      onMouseOver: (marker) => {
        eventHandlers?.onMouseOver
          ? eventHandlers.onMouseOver(marker, featurePolygon, uniqueCoordinatePointClusters)
          : null;
      },
      onClick: (marker) => {
        eventHandlers?.onClick ? eventHandlers.onClick(marker, featurePolygon, uniqueCoordinatePointClusters) : null;
      },
    },
  });
}

export default RenderClusterMarker;
