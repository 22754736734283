import { cloneDeep } from 'lodash';
import { RealEstateFilterType } from '#/entities/real-estate/common/type';
import { limitGeoParamZoomApi } from '#shared/lib/geo/limit-geo-search-params';
import { GeometryWithoutGeometryCollection } from '#shared/types/geo/geo-json-geometry-type';

type FilterMapParams = {
  zoom?: number;
  geometry?: GeometryWithoutGeometryCollection;
};

export const modifyPostBodyMapParams = <T extends RealEstateFilterType>(filter: T, mapParams?: FilterMapParams) => {
  let filterCopy = cloneDeep(filter);

  filterCopy = {
    ...filterCopy,
    geometry: mapParams?.geometry ?? filterCopy.geometry,
    zoom: limitGeoParamZoomApi(mapParams?.zoom ?? filterCopy.zoom),
  };

  return filterCopy;
};
