import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { action, makeAutoObservable, runInAction } from 'mobx';
import { match } from 'ts-pattern';
import { User, UserStore } from '#entities/user';
import { defaultStylesByStep } from '#entities/user/model/tour/common/const/styles';
import { IOnboardingStep } from '#entities/user/model/tour/common/interface/on-boarding-step-response.interface';
import { ITour } from '#entities/user/model/tour/common/interface/tour.interface';
import { ItemCount, ModalTab, PagePath, TimeConvert } from '#shared/enums';
import { userStore } from '../../user-store.context';
import { State } from '../user-Instruction-store';
import { SubscriptionSearchTourStepNameIndex } from './subscription-search-tour-step-name-index';

interface ISubscriptionOnSearchState extends State {
  isOpenModalFilter: boolean;
  currentModalTab: ModalTab;
}

export class SubscriptionOnSearchTour implements ITour {
  data: ISubscriptionOnSearchState;
  userStore: UserStore = userStore;
  isOpenInfoModal: boolean = false;
  preliminaryTransition: boolean = false;
  navigatePathFlag: PagePath = PagePath.EMPTY_PATH;

  constructor(onboardingStep: IOnboardingStep) {
    const { content, ...otherFields } = onboardingStep;

    this.data = {
      ...otherFields,
      run: false,
      stepIndex: 0,
      isOpenModalFilter: false,
      currentModalTab: ModalTab.FILTERS,
      steps: [
        {
          content: content.step1,
          disableBeacon: true,
          spotlightPadding: 4,
          offset: 16,
          placement: 'top-start',
          target: '.connect-bot-content',
          showNavigationButtons: false,
          spotlightClicks: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              bottom: '-8px',
              left: '16px',
              rotate: '180deg',
            },
          },
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -8,
            },
          },
        },
        {
          content: content.step2,
          spotlightPadding: 4,
          offset: 4,
          placement: 'bottom-end',
          target: '.connect-bot-success-container',
          showNavigationButtons: true,
          showPrevArrow: false,
          showNextArrow: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              right: '16px',
            },
          },
          styles: {
            ...defaultStylesByStep,
          },
        },
        {
          content: content.step3,
          spotlightPadding: 4,
          offset: 8,
          placement: 'bottom-end',
          target: '.savedFilter_0',
          showNavigationButtons: true,
          spotlightClicks: true,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              right: '46px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -170,
            },
          },
        },
        {
          content: content.step4,
          spotlightPadding: 4,
          offset: 8,
          placement: 'bottom-end',
          target: '.savedFilter_0',
          showNavigationButtons: false,
          floaterProps: {
            hideArrow: true,
          },
          customArrow: {
            styles: {
              top: '-8px',
              right: '46px',
            },
          },
          styles: {
            ...defaultStylesByStep,
            tooltip: {
              left: -170,
            },
          },
        },
      ],
    };
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  setData = (data: SubscriptionOnSearchTour['data']) => {
    this.data = data;
  };

  goNextStep = () => {
    this.setStepIndex(this.data.stepIndex + ItemCount.ONE);
  };

  goPrevStep = () => {
    this.setStepIndex(this.data.stepIndex - ItemCount.ONE);
  };

  setRun = (run: boolean) => {
    this.data.run = run;
  };

  setStepIndex = (index: number) => {
    this.data.stepIndex = index;
  };

  handleCallback = (data: CallBackProps, user: User) => {
    const { action, index, type, status } = data;
    const countStepWithMinusOne = this.data.steps.length - ItemCount.ONE;

    if (status == STATUS.PAUSED) {
      return;
    }

    if (index === countStepWithMinusOne && type === EVENTS.TOOLTIP) {
      // Завершение тура
      if (!userStore.checkLocalHasCompleteOnboarding(this.data.id)) {
        userStore.createOnboardingProgress(user.id, this.data.id);
      }

      return;
    }

    if (action === ACTIONS.CLOSE) {
      this.handleClose();
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const futureIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      match({ data, futureIndex })
        .with({ futureIndex: SubscriptionSearchTourStepNameIndex.SUCCESS_CONNECT_BOT }, () => {
          this.setNavigatePathFlag(PagePath.ACCOUNT_BOT);
          this.handleOpen();
          this.setStepIndex(SubscriptionSearchTourStepNameIndex.SUCCESS_CONNECT_BOT);
        })
        .with({ futureIndex: SubscriptionSearchTourStepNameIndex.MODAL_FILTER_SUBSCRIBE }, () => {
          this.setNavigatePathFlag(PagePath.FLATS);
          this.handleOpenModalSavedFilter();
          setTimeout(() => {
            this.setStepIndex(SubscriptionSearchTourStepNameIndex.MODAL_FILTER_SUBSCRIBE);
          }, TimeConvert.MILLISECONDS_IN_SECOND * 1);
        })
        .with(
          {
            futureIndex: SubscriptionSearchTourStepNameIndex.SUCCESS_MODAL_FILTER_SUBSCRIBE,
          },
          () => {
            const firstFilter = userStore.userRealEstateFilters[0];

            if (!firstFilter.isNotificationEnabled) {
              userStore
                .updateFilter({
                  filter: firstFilter,
                  name: firstFilter.name,
                  realEstateType: firstFilter.realEstateType,
                  isNotificationEnabled: true,
                })
                .then(() => {
                  runInAction(() => {
                    firstFilter.isNotificationEnabled = true;
                  });
                });
            }

            this.setStepIndex(SubscriptionSearchTourStepNameIndex.SUCCESS_MODAL_FILTER_SUBSCRIBE);
          },
        )
        .otherwise(() => {
          this.setStepIndex(futureIndex);
        });
    }
  };

  handleOpen = () => {
    this.setRun(true);
    this.setStepIndex(0);
  };

  @action
  handleClose = () => {
    this.data.isOpenModalFilter = false;
    this.setRun(false);
    this.setPreliminaryTransition(false);
    this.setIsOpenInfoModal(false);
    this.setStepIndex(0);
    this.setNavigatePathFlag(PagePath.EMPTY_PATH);
  };

  getData = () => {
    return this.data;
  };

  setIsOpenInfoModal = (isOpenInfoModal: SubscriptionOnSearchTour['isOpenInfoModal']) => {
    this.isOpenInfoModal = isOpenInfoModal;
  };

  setPreliminaryTransition = (preliminaryTransition: SubscriptionOnSearchTour['preliminaryTransition']) => {
    this.preliminaryTransition = preliminaryTransition;
  };

  handleOpenModalSavedFilter = () => {
    this.data.isOpenModalFilter = true;
    this.data.currentModalTab = ModalTab.SAVED_FILTERS;
  };

  handleCloseModalSavedFilter = () => {
    this.data.isOpenModalFilter = false;
    this.data.currentModalTab = ModalTab.SAVED_FILTERS;
  };

  handleCloseInfoModal = () => {
    this.setPreliminaryTransition(false);
    this.setIsOpenInfoModal(false);
  };

  setNavigatePathFlag = (navigatePathFlag: SubscriptionOnSearchTour['navigatePathFlag']) => {
    this.navigatePathFlag = navigatePathFlag;
  };

  goToLastStep = () => {
    const lastIndex = this.data.steps.length - 1;

    this.setRun(false);
    this.setStepIndex(lastIndex);

    setTimeout(() => {
      this.setRun(true);
    }, TimeConvert.MILLISECONDS_IN_MILLISECONDS * 50);
  };
}
