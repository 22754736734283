import { GeoJsonObject, Geometry } from 'geojson';
import { isPolygonGeometry } from '#shared/lib/geo/guard/geo-guard';

type PositionWithTwoSpace = [number, number];

export interface ViewPortPolygon extends GeoJsonObject {
  type: 'Polygon';
  coordinates: [
    [PositionWithTwoSpace, PositionWithTwoSpace, PositionWithTwoSpace, PositionWithTwoSpace, PositionWithTwoSpace],
  ];
}

export const isGeometryViewport = (geometry: Geometry): geometry is ViewPortPolygon => {
  const isPolygon = isPolygonGeometry(geometry);

  if (!isPolygon) {
    return false;
  }

  const innerRing = geometry?.coordinates[0];

  return innerRing.length === 5 && JSON.stringify(innerRing[0]) === JSON.stringify(innerRing[4]);
};
