import React from 'react';
import { Avatar, SxProps } from '@mui/material';
import { environments } from '#/environment';
import { useUser } from '#entities/user/model/user-context';
import { PhotoSize } from '#shared/enums';
import { getInitials } from '#shared/lib/get-initials';

type UserAvatarProps = {
  id?: string;
  avatarUrl: string;
  name?: string;
  sx?: SxProps;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

const hashCode = (str: string) => {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
};

export const UserAvatar: React.FC<UserAvatarProps> = ({ id, avatarUrl, name, sx, onClick }) => {
  const initials = name ? getInitials(name) : '';
  const fullPathUrl = `${environments.REACT_APP_CDN}/${PhotoSize.EXTRA_SMALL}${avatarUrl}`;
  const color = intToRGB(hashCode(name ?? ''));
  const user = useUser();

  return (
    <Avatar
      id={id}
      sx={{
        backgroundColor: `#${color}`,
        border: `${!user.subscriptionPlan.isDefault ? '4px solid #828DF8' : 'none'}`,
        '.MuiAvatar-img': {
          cursor: sx ? 'default' : 'pointer',
        },
      }}
      src={fullPathUrl}
      alt='user avatar'
      children={initials}
      onClick={onClick}
    />
  );
};
