import React, { FC } from 'react';
import { Size } from '#shared/enums';
import { ClearObjectsButton } from '#shared/styles/styles';

type ResetFilterButtonProps = {
  reset: () => void;
};

export const ResetFilterButton: FC<ResetFilterButtonProps> = ({ reset }) => {
  return (
    <ClearObjectsButton id={'resetFilterButton'} size={Size.LARGE} onClick={() => reset()}>
      Очистить
    </ClearObjectsButton>
  );
};
