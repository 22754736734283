import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Search } from '@mui/icons-material';
import { Button, SxProps, useTheme } from '@mui/material';
import { FontFamily } from '#shared/enums';

type SaveFilterButtonProps = {
  saveFilter: () => void;
  isNotDefaultFilter: boolean;
  sx?: SxProps;
  hasSearchIcon?: boolean;
};

export const SaveFilterButton: FC<SaveFilterButtonProps> = observer(
  ({ saveFilter, isNotDefaultFilter, hasSearchIcon = false }) => {
    const theme = useTheme();

    return (
      <>
        <Button
          disableRipple
          onClick={saveFilter}
          sx={{
            textTransform: 'none',
            whiteSpace: 'nowrap',
            padding: '8px 11px',
            fontSize: '14px',
            borderRadius: '8px',
            fontFamily: FontFamily.ROBOTO,
            color: theme.palette.primary.main,
            visibility: isNotDefaultFilter ? 'visible' : 'hidden',
          }}
          id={'saveFilterButton'}
          size={'medium'}
          startIcon={hasSearchIcon ? <Search /> : null}
        >
          Сохранить поиск
        </Button>
      </>
    );
  },
);
