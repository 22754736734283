import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { uid } from 'uid';
import { Flat } from '#/entities/real-estate/flat';
import { Author, Currency, DealType, Domain } from '#shared/enums';

const dateTimeYesterday = DateTime.now().minus({ day: 1 }).toISO();

export class FlatResponseWithRelatedFields {
  flat: Flat = {} as Flat;

  constructor(flat: Flat) {
    this.flat = flat;
    makeAutoObservable(this);
  }
}

export enum FlatFakeInfo {
  phone = '79123456789',
}

export const exampleFlatFakeData = {
  coincidence: '',
  id: '',
  isFavourite: false,
  taskId: '',
  cityId: '',
  advertId: '4221689992',
  isActive: true,
  address: 'Ставропольская улица, 9',
  price: '5300000',
  dealType: DealType.SALE,
  author: Author.OWNER,
  clientName: 'Частное лицо',
  description:
    'Уютная светлая тёплая квартира. С соседями проблем не было. В шаговой доступности магазины, аптеки, лет сад, школа.',
  domain: Domain.AVITO,
  latitude: 57.12173620899353,
  longitude: 65.52887287602329,
  phone: '79123456789',
  url: '/tyumen/kvartiry/2-k._kvartira_517_m_49_et._4221689992',
  area: 51,
  floor: 4,
  floorCount: 9,
  roomCount: 2,
  publishedAt: dateTimeYesterday,
  modifiedAt: dateTimeYesterday,
  actualizedAt: dateTimeYesterday,
  createdAt: dateTimeYesterday,
  updatedAt: dateTimeYesterday,
  duplicatedAt: dateTimeYesterday,
  userStatusId: null,
  callingUserId: null,
  metroStationId: null,
  microdistrictId: '92817867-a853-401d-95bd-7a4804fb5445',
  buildingId: null,
  currency: Currency.RUB,
  images: [
    {
      url: '/image/1/1.s65BYra1H0c3zI1PK0ypqCrAHUf_yd1IZ8sdRQ.DT-J1Cz9br15JEFmlLS4pvYQqU94-AeYPLcsqCS7pj0',
    },
  ],
  priceHistory: [
    {
      price: '5300000',
      currency: Currency.RUB,
      createdAt: '2024-08-08T07:34:44.609Z',
    },
  ],
  duplicates: [],
  userStatus: null,
  _count: {
    comments: 7,
  },
  metroStation: null,
  callingUser: null,
  calls: [
    {
      createdAt: DateTime.now().minus({ hour: 1, minutes: 50 }).toISO(),
    },
  ],
  usersFavourites: [],
};

interface generateFlatFakeDataProps {
  flatData?: Partial<Flat>;
  userId: string;
}
export const generateFlatFakeData = ({ flatData, userId }: generateFlatFakeDataProps) => {
  const flatId = `${uid(32)}_${userId}`;
  const dateTimeYesterday = DateTime.now().minus({ day: 1 }).toISO();
  const updatedFakeData = {
    ...cloneDeep(exampleFlatFakeData),
    publishedAt: dateTimeYesterday,
    modifiedAt: dateTimeYesterday,
    actualizedAt: dateTimeYesterday,
    createdAt: dateTimeYesterday,
    updatedAt: dateTimeYesterday,
    duplicatedAt: dateTimeYesterday,
    id: flatId,
    ...flatData,
  };

  return new FlatResponseWithRelatedFields(updatedFakeData).flat;
};
