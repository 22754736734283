import { cloneDeep } from 'lodash';
import { BaseRealEstateFilterType } from '../type';

export const trimFilters = <T extends BaseRealEstateFilterType>(filter: T): T => {
  const filterCopy = cloneDeep(filter);

  if (filterCopy.address) {
    filterCopy.address.trim();
  }

  return filterCopy;
};

export const getFilterWithoutMapParams = <T extends BaseRealEstateFilterType>(filter: T) => {
  const { geometry, zoom, ...restFilter } = filter;

  return restFilter;
};
