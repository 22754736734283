export enum PagePath {
  EMPTY_PATH = '/',
  LOGIN = '/login',
  FLATS = '/flats',
  HOUSES = '/houses',
  LANDS = '/lands',
  FLATS_MAP = '/flats/map',
  HOUSES_MAP = '/houses/map',
  LANDS_MAP = '/lands/map',
  ADMIN_TASKS = '/tasks',
  MY_OBJECTS = '/my-objects',
  STATS_PARSER = '/stats',
  STATS_TEAM = '/stats/team',
  TASKS = '/tasks',
  MAP = '/map',
  FAVORITES = '/favorites',
  ACCOUNT_SUBSCRIPTIONS = '/account/subscriptions',
  ACCOUNT_TARIFFS = '/account/tariffs',
  ACCOUNT_BOT = '/account/bot',
  ACCOUNT_CALLS = '/account/calls',
  ACCOUNT_PAYMENTS = '/account/payments',
  FORBIDDEN = '/forbidden',
}
