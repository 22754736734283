import { RealEstateFilterType } from '#/entities/real-estate/common/type';

export const modifyPostBodyForRequest = <T extends RealEstateFilterType>(filter: T, statusLength: number) => {
  const filterCopy = JSON.parse(JSON.stringify(filter));

  if (filterCopy.statusId?.length === statusLength) {
    delete filterCopy.statusId;
  }

  return filterCopy;
};
