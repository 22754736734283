import { HttpStatusCode, isAxiosError } from 'axios';
import { LocalStorageName, PagePath } from '#shared/enums';

export const handleAxiosError = (error: unknown): void => {
  if (!error || !isAxiosError(error)) {
    return;
  }

  const status = error.response?.status;

  if (!status) {
    return;
  }

  if (status === HttpStatusCode.Unauthorized) {
    window.location.href = PagePath.LOGIN;
    localStorage.removeItem(LocalStorageName.IS_SUBSCRIPTION_PLAN_ALERT_CLOSED);

    return;
  }

  if (status === HttpStatusCode.Forbidden) {
    window.location.href = PagePath.FORBIDDEN;

    return;
  }
};
