import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { action, computed, makeAutoObservable, observable } from 'mobx';
import qs from 'qs';
import { match } from 'ts-pattern';
import { environments } from '#/environment';
import { WorkAddressManagerDto } from '#shared/dto/work-address-manager-dto';
import { WorkAddressUserCountDto } from '#shared/dto/work-address-user-count-dto';
import { WorkAddressUserDto } from '#shared/dto/work-address-user-dto';
import { PageLimit, PageNumber, UserStatisticOrder } from '#shared/enums';
import { handleAxiosError } from '#shared/lib/handle-axios-error';
import { filterFieldFromObject } from '#shared/lib/remove-empty-field-from-object';
import { AutoCompleteOptionDto } from '#shared/types/auto-complete-option';
import { AxiosErrorData } from '#shared/types/axios-response-error-data';

export class StatTeamStore {
  @observable orderBy: UserStatisticOrder = UserStatisticOrder.COMMISSION_DESC;
  @observable users: WorkAddressUserDto[] = [];
  @observable userPage: number = PageNumber.FIRST;
  @observable loadingUsers: boolean = false;
  @observable rowsPerPage: PageLimit = PageLimit.FIFTEEN;
  @observable managers: WorkAddressManagerDto[] = [];
  @observable loadingManagers: boolean = false;
  @observable managerPage: number = PageNumber.FIRST;
  @observable searchManagerQuery: string = '';
  @observable searchUserQuery: string = '';
  @observable selectedManagerId: string = '';
  @observable userCount: number = 0;
  @observable loadingUserCount: boolean = false;
  @observable hasFirstRenderPage: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  setHasFirstRenderPage = (hasFirstRenderPage: StatTeamStore['hasFirstRenderPage']) => {
    this.hasFirstRenderPage = hasFirstRenderPage;
  };

  @action setLoadingUserCount = (loadingUserCount: StatTeamStore['loadingUserCount']) => {
    this.loadingUserCount = loadingUserCount;
  };

  @action setUserCount = (userCount: StatTeamStore['userCount']) => {
    this.userCount = userCount;
  };

  @action
  fetchUserCountData = async (workAddressId: number) => {
    this.setLoadingUserCount(true);

    const query = qs.stringify(
      filterFieldFromObject({
        object: {
          id: workAddressId,
          fullName: this.searchUserQuery,
          managerId: this.selectedManagerId,
        },
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
    );

    const response = await axios
      .get(`${environments.REACT_APP_PROXY}/api/work-address/${workAddressId}/users/count?${query}`)
      .then((response: AxiosResponse<WorkAddressUserCountDto>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<WorkAddressUserCountDto>) => {
        this.setUserCount(response.data.count);
        this.setLoadingUserCount(false);
      })
      .with({ status: HttpStatusCode.BadRequest }, (response: AxiosResponse<AxiosErrorData>) => {
        handleAxiosError(response.data.error);
        this.setLoadingUserCount(false);
      })
      .otherwise(() => {
        this.setLoadingUserCount(false);
      });
  };

  @action
  fetchUsers = async (workAddressId: number) => {
    this.setLoadingUsers(true);

    const query = qs.stringify(
      filterFieldFromObject({
        object: {
          id: workAddressId,
          fullName: this.searchUserQuery,
          managerId: this.selectedManagerId,
          page: this.userPage,
          orderBy: this.orderBy,
        },
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
    );

    const response = await axios
      .get(`${environments.REACT_APP_PROXY}/api/work-address/${workAddressId}/users?${query}`)
      .then((response: AxiosResponse<WorkAddressUserDto[]>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<WorkAddressUserDto[]>) => {
        this.setUsers(response.data);
        this.setLoadingUsers(false);
      })
      .with({ status: HttpStatusCode.BadRequest }, (response: AxiosResponse<AxiosErrorData>) => {
        handleAxiosError(response.data.error);
        this.setLoadingUsers(false);
      })
      .otherwise(() => {
        this.setLoadingUsers(false);
      });
  };

  @action
  summaryFetchManagers = async (workAddressId: number) => {
    const query = qs.stringify(
      filterFieldFromObject({
        object: {
          page: this.managerPage,
          fullName: this.searchManagerQuery,
        },
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
    );

    const response = await axios
      .get(`${environments.REACT_APP_PROXY}/api/work-address/${workAddressId}/managers?${query}`)
      .then((response: AxiosResponse<WorkAddressManagerDto[]>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<WorkAddressManagerDto[]>) => {
        this.setManagers([...this.managers, ...response.data]);
        this.setLoadingManagers(false);
      })
      .with({ status: HttpStatusCode.BadRequest }, (response: AxiosResponse<AxiosErrorData>) => {
        handleAxiosError(response.data.error);
        this.setLoadingManagers(false);
      })
      .otherwise(() => {
        this.setLoadingManagers(false);
      });
  };

  @action
  setSearchUserQuery(searchUserQuery: StatTeamStore['searchUserQuery']) {
    this.searchUserQuery = searchUserQuery;
  }

  @action
  setSelectedManagerId(selectedManagerId: StatTeamStore['selectedManagerId']) {
    this.selectedManagerId = selectedManagerId;
  }

  @action
  setManagerPage(managerPage: StatTeamStore['managerPage']) {
    this.managerPage = managerPage;
  }

  @action
  setManagers(managers: StatTeamStore['managers']) {
    this.managers = managers;
  }

  @action
  setLoadingManagers(loadingManagers: StatTeamStore['loadingManagers']) {
    this.loadingManagers = loadingManagers;
  }

  @action
  setSearchManagerQuery(searchManagerQuery: StatTeamStore['searchManagerQuery']) {
    this.searchManagerQuery = searchManagerQuery;
  }

  @computed
  findAutoCompleteOptionByManagerId(managerId: string) {
    return this.findAutoCompleteOptionManagers().find((manager: AutoCompleteOptionDto) => manager.id === managerId);
  }

  @action
  findAutoCompleteOptionManagers = (): AutoCompleteOptionDto[] => {
    return this.managers.map((manager: WorkAddressManagerDto) => {
      return {
        label: manager.fullName,
        id: manager.id,
      };
    });
  };

  @action
  setLoadingUsers = (loadingUsers: StatTeamStore['loadingUsers']) => {
    this.loadingUsers = loadingUsers;
  };

  @action
  setUsers = (users: StatTeamStore['users']) => {
    this.users = users;
  };

  @action
  findUsers(): WorkAddressUserDto[] {
    return this.users;
  }

  @action
  setUserPage(page: number) {
    this.userPage = page;
  }

  @action
  setRowsPerPage(rowsPerPage: PageLimit) {
    this.rowsPerPage = rowsPerPage;
  }

  @action
  findUserById(id: string): WorkAddressUserDto | undefined {
    return this.users.find((user) => user.id === id);
  }

  @action
  fetchManagers = async (workAddressId: number) => {
    this.setLoadingManagers(true);

    const query = qs.stringify(
      filterFieldFromObject({
        object: {
          page: this.managerPage,
          fullName: this.searchManagerQuery,
        },
        isFilterEmptyString: true,
        isEmptyArray: true,
        isFilterNull: true,
      }),
    );

    const response = await axios
      .get(`${environments.REACT_APP_PROXY}/api/work-address/${workAddressId}/managers?${query}`)
      .then((response: AxiosResponse<WorkAddressManagerDto[]>) => response)
      .catch((axiosError: AxiosError<AxiosErrorData>) => axiosError.response);

    match(response)
      .with({ status: HttpStatusCode.Ok }, (response: AxiosResponse<WorkAddressManagerDto[]>) => {
        this.setManagers(response.data);
        this.setLoadingManagers(false);
      })
      .with({ status: HttpStatusCode.BadRequest }, (response: AxiosResponse<AxiosErrorData>) => {
        handleAxiosError(response.data.error);
        this.setLoadingManagers(false);
      })
      .otherwise(() => {
        this.setLoadingManagers(false);
      });
  };

  @action
  setOrderBy(orderBy: UserStatisticOrder) {
    this.orderBy = orderBy;
  }
}
