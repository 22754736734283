export enum TaskInterval {
  TEN_SECONDS = 10,
  TWENTY_SECONDS = 20,
  THIRTY_SECONDS = 30,
  FIFTY_SECONDS = 50,
  ONE_MINUTES = 60,
  TWO_MINUTES = 120,
  FIVE_MINUTES = 300,
  TEN_MINUTES = 600,
}
